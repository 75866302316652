import { Box, Container } from "@mui/system";
import "./Tags.css";
import Grid from "@mui/material/Grid";
import catLogo from "../../Components/assets/cat-logo.png";
import dogLogo from "../../Components/assets/dog-logo.png";
import CheckIcon from "@mui/icons-material/Check";
import Radio from "@mui/material/Radio";
import { useEffect, useRef, useState } from "react";
import Avatar from "@mui/material/Avatar";
import {
  Button,
  ButtonGroup,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import ArrowLeftRoundedIcon from "@mui/icons-material/ArrowLeftRounded";
import CssButton from "../../Components/Reusables/CssButton";
import { useNavigate } from "react-router-dom";
import { infoMsg, success } from "../../Components/ToasterFn";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import axios from "axios";
import { getTagLists } from "../../Components/utils/Helper";
import TagsCounterBtn from "./TagsCounterBtn";
import TagsCard from "./TagsCard";

const Tags = ({ count, setCount }) => {
  const navigate = useNavigate();
  const [btn, setBtn] = useState(false);

  const [catTags, setCatTags] = useState([]);
  const [dogTags, setDogTags] = useState([]);
  const [selectedValue, setSelectedValue] = useState("orange");
  const [selectedValue1, setSelectedValue1] = useState("orange");
  const [counterVal, setCounterval] = useState(0);
  const [color, setColor] = useState("");
  const [size, setSize] = useState("");
  const [amount, setAmount] = useState(0);
  const [catTagSize, setCatTagSize] = useState("m");
  const [dogTagSize, setDogTagSize] = useState("m");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleChange1 = (event) => {
    setSelectedValue1(event.target.value);
  };

  const controlProps = (item, para) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  const controlProps1 = (item) => ({
    checked: selectedValue1 === item,
    onChange: handleChange1,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });
  console.log("selected value", selectedValue);
  const myArray = selectedValue.split("-");
  console.log("mnn", myArray[0]);

  const [tags, setTags] = useState("");
  const getPlansInfo = async () => {
    var data = [];
    try {
      const res = await axios.get(getTagLists());
      if (res.status == 200 && res.data) {
        data = res.data;
        console.log("data ", data?.data);
        setTags(data?.data);
      } else {
        console.log(data);

        data = [];
      }
    } catch (e) {
      console.log(e);
      data = [];
    }
  };
  const wasCalled = useRef(false);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if(wasCalled.current) return;
    wasCalled.current = true;
    getPlansInfo();
  }, []);
  console.log("pet size", size);
  if (process.env.REACT_APP_SERVER === "STAGE") {
    console.log("stage");
  } else {
    console.log("live");
  }

  return (
    <Box
      component="form"
      onSubmit={(e) => {
        e.preventDefault();
        // handleCartItems();
      }}
      className="d-flex flex-column tag-price-main"
    >
      {tags &&
        tags.map((val, ind) => {
          return (
            <>
              <TagsCard
                val={val}
                ind={ind}
                btn={btn}
                setBtn={setBtn}
                count={count}
                setCount={setCount}
              />
            </>
          );
        })}

      {btn ? (
        <Box sx={{ textAlign: "center", marginTop: "3vh" }}>
          <CssButton
            callback={() => navigate("/Plans")}
            type="submit"
            width="250px"
            height="100%"
            text="Order Now"
            fontSize="35px"
            color="orange"
          />
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
};

export default Tags;
