import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CssTextField from "../../Components/Reusables/CssTextField";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const ReportMissedPet = ({ openReportPet, setOpenReportPet,sendpetlocation,setSeenLocation }) => {
  const [lastSeen, setLastSeen] = React.useState("");

  const handleClose = () => {
    setOpenReportPet(false);
  };
  console.log(lastSeen);
  const location = useLocation();
  const locData = location.pathname.split("/");
  const path = locData[1];
  console.log("xyz", path);
  return (
    <div>
      <Dialog
        open={openReportPet}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {path == "pets"
            ? "Please identify the location of lost pet ."
            : "Please identify the location it was last seen in"}
        </DialogTitle>
        <DialogContent>
          {}
          <CssTextField
            callback={(e) => setSeenLocation(e)}
            size="small"
            placeholder="Name the Location"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            sx={{
              "&:hover": {
                border: "1px solid #fd9b13",
                bgcolor: "white",
              },
              color: "black",
              bgcolor: "#fff",
              borderRadius: "30px",
              border: "1px solid orange",
            }}
            variant="outlined"
          >
            Discard
          </Button>
          <Button
            onClick={sendpetlocation}
            sx={{
              "&:hover": {
                color: "black",
                bgcolor: "#fd9b13",
              },
              bgcolor: "#fd9b13",
              borderRadius: "30px",
            }}
            variant="contained"
          >
            Report Missing Pet
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ReportMissedPet;
