import { Box } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './Navbar.css'
import tounzLogo from '../assets/tounzLogo.png'
import { useEffect, useState } from 'react';
import { Container } from '@mui/system';

const Navbar = () => {
    const [ind, setInd] = useState(0)
    const navigate = useNavigate()
    const location = useLocation()
    const locData = location.pathname.slice(1)
    const [toggleClassState, setToggleClassState] = useState("")
    const [toggleNavState, setToggleNavState] = useState("")


    const handleClick = () => {
        if (toggleClassState === "mobile-nav") {
            setToggleClassState(""); setToggleNavState("")
        } else {
            setToggleClassState("mobile-nav"); setToggleNavState("is-active")
        }
    }


    return (
        <Box>
            <Box className="page-wrapper">
                <Box className="nav-wrapper">
                    <nav className="navbar" >
                        <Box className='nav-logo'  >
                            <img style={{ cursor: "pointer" }} onClick={() => navigate("/")} src={tounzLogo} />
                        </Box>
                        <Box className={`menu-toggle ${toggleNavState}`} onClick={handleClick} id="mobile-menu">
                            <span className="bar"></span>
                            <span className="bar"></span>
                            <span className="bar"></span>
                        </Box>
                        <ul style={{ float: 'right' }} className={`nav ${toggleClassState} no-search`}>
                            <li className="nav-item" onClick={() => { setToggleClassState(""); setToggleNavState("") }}   ><Link to="/" style={{ fontFamily: "Calli Cat", color: locData === "" ? "orange" : "" }} > Home</Link></li>
                            <li className="nav-item" onClick={() => { setToggleClassState(""); setToggleNavState("") }} ><Link to="/MissingPets" style={{ fontFamily: "Calli Cat", color: locData === "MissingPets" ? "orange" : "" }} >Missing Pets</Link></li>
                            <li className="nav-item" onClick={() => { setToggleClassState(""); setToggleNavState("") }} ><Link to="/Plans" style={{ fontFamily: "Calli Cat", color: locData === "Plans" ? "orange" : "" }} >Plans</Link></li>
                            <li className="nav-item" onClick={() => { setToggleClassState(""); setToggleNavState("") }} ><Link to="/Tags" style={{ fontFamily: "Calli Cat", color: locData === "Tags" ? "orange" : "" }} >Tags</Link></li>
                            <li className="nav-item" onClick={() => { setToggleClassState(""); setToggleNavState("") }}   ><Link to="/pets" style={{ fontFamily: "Calli Cat", color: locData === "pets" ? "orange" : "" }} > Search</Link></li>

                            <li className="nav-item loginBtn " onClick={() => { setToggleClassState(""); setToggleNavState("") }} ><Link style={{ fontFamily: "Calli Cat" }} to="/Login"  >Log In</Link></li>
                        </ul>
                    </nav>
                </Box>
            </Box>
        </Box>
    );
}
export default Navbar;

