import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CssTextField from "../Reusables/CssTextField";
import axios from "axios";
import { reportMissed } from "../utils/Helper";
import { errorMsg, success } from "../ToasterFn";
import { getLocalUserData } from "../utils/Util";

export default function ReportPetDialog({ openReportPet, setOpenReportPet,pid }) {
  const handleClose = () => {
    setOpenReportPet(false);
  };
  const[lastSeen,setLastSeen]=React.useState("")
  console.log("datatopend",pid)
  const uid = getLocalUserData().token;

const reportPet=async()=>{
  const formData = new FormData();
  formData.append("token", uid);
  formData.append("pet_id", pid);
  formData.append("description", lastSeen);


  var data = [];

    try {
      const res = await axios.post(reportMissed(),formData);
      if (res.status == 200 && res.data) {
        data = res.data;
        console.log("userpets ", data);
        if(data?.status){
          success(data?.message)
          window.location.reload()
        }else{
          errorMsg(data?.message)
        }
      } else {
        data = [];
        console.log(res?.response?.data?.message);
        errorMsg(res?.data?.message);
      }
    } catch (e) {
      console.log(e);
      errorMsg(e?.response?.data?.message);

      data = [];
    }
}
  return (
    <div>
      <Dialog
        open={openReportPet}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Please identify the location it was last seen in
        </DialogTitle>
        <DialogContent>
          <CssTextField
            callback={(e) => setLastSeen(e)}
            size="small"
            placeholder="Name the Location"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            sx={{
              "&:hover": {
                border: "1px solid #fd9b13",
                bgcolor: "white",
              },
              color: "black",
              bgcolor: "#fff",
              borderRadius: "30px",
              border: "1px solid orange",
            }}
            variant="outlined"
          >
            Discard
          </Button>
          <Button
            onClick={reportPet}
            sx={{
              "&:hover": {
                color: "black",
                bgcolor: "#fd9b13",
              },
              bgcolor: "#fd9b13",
              borderRadius: "30px",
            }}
            variant="contained"
          >
            Report Missing Pet
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
