import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import { useState } from "react";
import axios from "axios";
import { errorMsg, success } from "../../Components/ToasterFn";
import { editAllUploadedPetFiles } from "../utils/Helper";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 5,
  pb: 3,
  display: "flex",
};

export default function EditUploadsModal({petId}) {
  const [open, setOpen] = React.useState(false);
  const [vaccine1,setVaccine1] = useState(null)
  const [vaccine2,setVaccine2] = useState(null)
  const [vaccine3,setVaccine3] = useState(null)
  const [petHistory,setPetHistory] = useState(null);
  const [petInsure,setPetInsure] = useState(null)
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [files, setFiles] = React.useState([]);
  const [hisFile,setHisFiles] = useState([])
  const[insFile,setInsFile] = useState([])
  const [inputCount, setInputCount] = React.useState(3);
  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles([...files, ...selectedFiles]);
  };
  const vaccineCertificateEdit = (e,idx) => {
    if(idx == 0){
      setVaccine1(e.target.files[0])
    }
    if(idx == 1){
      setVaccine2(e.target.files[0])
    }
    if(idx == 2){
      setVaccine3(e.target.files[0])
    }
  }
  const editPetFiles = async () => {
    const formData = new FormData();
    formData.append("pid", petId);
    if(vaccine1){formData.append("uploaded_file1",vaccine1)}
    if(vaccine2){formData.append("uploaded_file2",vaccine2)}
    if(vaccine3){formData.append("uploaded_file3",vaccine3)}
    if(petInsure){formData.append("insurance_file",petInsure)}
    if(petHistory){formData.append("pet_history",petHistory)}
    var data = [];

    try {
      const res = await axios.post(editAllUploadedPetFiles(), formData);
      if (res.status == 200 && res.data) {
        data = res.data;
        if (data?.status) {
          success("Updated Sucessfully");
          window.location.reload();
          handleClose()
        } else {
          errorMsg(data?.message);
        }
        console.log("userpets ", data);
      } else {
        data = [];
        console.log(res?.response?.data?.message);
        errorMsg(res?.data?.message);
      }
    } catch (e) {
      console.log(e);
      errorMsg(e?.response?.data?.message);
      data = [];
    }
  };
  return (
    <div>
      <Button variant="contained" color="warning" onClick={handleOpen}>
        Edit Uploaded files
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: "auto" }}>
          <div className="container-fluid">
            <div className="row ">
              <div className="col-md-4 d-flex h-auto align-items-center">
                Pet vaccination
              </div>
              <div className="col-md-8 w-auto ">
                {Array.from({ length: inputCount }).map((_, index) => (
                  <div className="xyz my-2 " key={index}>
                    {/* Replace the input tag with a Material-UI file input */}
                    <input
                      type="file"
                      // onChange={(e) => handleFileChange(e)}
                      onChange={(e) => {handleFileChange(e)
                        vaccineCertificateEdit(e,index)}}
                      style={{ display: "none" }}
                      id={`file-input-${index}`}
                    />
                    <label htmlFor={`file-input-${index}`}>
                      <Button
                        component="span"
                        variant="contained"
                        color="warning"
                        //   startIcon={<CloudUploadIcon />}
                      >
                        {files[index] ? "Uploaded" : "Upload File"}
                      </Button>
                    </label>
                    {files[index] && (
                      <button
                        //  onClick={() => handleDeleteClick(index)}
                        className="btn"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="1.2em"
                        >
                          <path d="M4 8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8ZM6 10V20H18V10H6ZM9 12H11V18H9V12ZM13 12H15V18H13V12ZM7 5V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V5H22V7H2V5H7ZM9 4V5H15V4H9Z"></path>
                        </svg>{" "}
                      </button>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-12">
                <div className="row ">
                  <div className="col-md-4 d-flex h-auto align-items-center">
                    Pet Insurance
                  </div>
                  <div className="col-md-8 w-auto">
                    {Array.from({ length: 1 }).map((_, index) => (
                      <div className="xyz my-2" key={index}>
                        {/* Replace the input tag with a Material-UI file input */}
                        <input
                          type="file"
                          onChange={(e) => {setPetInsure(e.target.files[0])
                            setInsFile(e.target.files)}}
                          
                          style={{ display: "none" }}
                          id={`file-input-ins-${index}`}
                        />
                        <label htmlFor={`file-input-ins-${index}`}>
                          <Button
                            component="span"
                            variant="contained"
                            color="warning"
                            //   startIcon={<CloudUploadIcon />}
                          >
                            {insFile[index] ? "Uploaded" : "Upload File"}
                          </Button>
                        </label>
                        {files[index] && (
                          <button
                            className="btn"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="1.2em"
                            >
                              <path d="M4 8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8ZM6 10V20H18V10H6ZM9 12H11V18H9V12ZM13 12H15V18H13V12ZM7 5V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V5H22V7H2V5H7ZM9 4V5H15V4H9Z"></path>
                            </svg>{" "}
                          </button>
                        )}
                      </div>
                    ))}
                  </div>
                </div>{" "}
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-12">
                <div className="row ">
                  <div className="col-md-4 d-flex h-auto align-items-center">
                    Pet History
                  </div>
                  <div className="col-md-8 w-auto">
                    {Array.from({ length: 1 }).map((_, index) => (
                      <div className="xyz my-2" key={index}>
                        {/* Replace the input tag with a Material-UI file input */}
                        <input
                          type="file"
                          onChange={(e) => {
                            setPetHistory(e.target.files[0]);
                            setHisFiles(e.target.files)
                          }}
                          style={{ display: "none" }}
                          id={`file-input-his-${index}`}
                        />
                        <label htmlFor={`file-input-his-${index}`}>
                          <Button
                            component="span"
                            variant="contained"
                            color="warning"
                            //   startIcon={<CloudUploadIcon />}
                          >
                            {hisFile[index] ? "Uploaded" : "Upload File"}
                          </Button>
                        </label>
                        {files[index] && (
                          <button
                            //  onClick={() => handleDeleteClick(index)}
                            className="btn"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="1.2em"
                            >
                              <path d="M4 8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8ZM6 10V20H18V10H6ZM9 12H11V18H9V12ZM13 12H15V18H13V12ZM7 5V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V5H22V7H2V5H7ZM9 4V5H15V4H9Z"></path>
                            </svg>{" "}
                          </button>
                        )}
                      </div>
                    ))}
                  </div>
                </div>{" "}
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-12 d-flex justify-content-center">
                <Button
                  variant="contained"
                  color="warning"
                  onClick={() => editPetFiles()}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
