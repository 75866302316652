import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import UserProvider from "./Components/context/UserProvider";
import AppRoutes from "./Components/AppRoutes";
import axios from "axios";
import { ToastContainer } from "react-toastify";
axios.defaults.withCredentials = true;

function App() {
  return (
    <React.StrictMode>
      <ToastContainer />
      <UserProvider>
        <AppRoutes />
      </UserProvider>
    </React.StrictMode>
  );
}
export default App;
