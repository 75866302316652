import { Button } from "@mui/material";
import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import orderSuccess from "../../src/assests/order-confirmation.webp";

const OrderSuccess = ({orderId}) => {
  const navigate=useNavigate()
  const [searchData, setSearchData] = useSearchParams()
  console.log("srp",searchData)
  const location = useLocation();

  useEffect( ()=>{
    console.log("srp",searchData.get("payment_intent_client_secret"))

  }, [searchData])

  // const searchParams = new URLSearchParams(location.search);

  // // Create an array to hold the parameter names and values
  // const queryParams = [];

  // // Iterate through the search parameters and add them to the array
  // for (let param of searchParams.entries()) {
  //   const [name, value] = param;
  //   queryParams.push({ name, value });
  // }
  // console.log(queryParams)
  return (
    <div className="container">
      <div className="my-5 Payment-order-wrapper text-center">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <h1 className="order-success">Order Successful</h1>
            <div className="Payment-order__media">
              {/* <img src={OrderMedia.src} className='img-fluid' alt='' /> */}
              <img
                src={orderSuccess}
                className="img-fluid img"
                alt=""
                width="250"
                height="50"
              />
            </div>
            <div className="Payment-order__content mt-3">
              <h4>Thank you for your purchase!</h4>
              <p>
                You will receive an order confirmation email with details of
                your order.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center">

      <Button className="me-3" style={{border:"0px solid black",background:"orange",color:"black",padding:"8px"}} onClick={()=>{navigate(`/Orderdetails/${orderId}`)}}>order details</Button>
      <Button className="ms-3" style={{border:"0px solid black",background:"orange",color:"black",padding:"8px"}} onClick={()=>{navigate("/UserPets")}}>Home</Button>
      </div>
    </div>
  );
};

export default OrderSuccess;
