import { Box } from "@mui/material";
import "./Footer.css";
import TounzLogo from "../assets/tounzLogo.png";
import Img16 from "../assets/16.png";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { FooterContents } from "../utils/Helper";
import parse from "html-react-parser";

const Footer = () => {
  const [fData, setFData] = useState([]);

  const FooterData = async () => {
    let data = [];
    try {
      const res = await axios.get(FooterContents());
      if (res.status == 200 && res.data) {
        data = res.data;
        console.log("orders--", data);
        if (data?.status) {
          console.log("res", data?.data);
          setFData(data?.data);
        }
      } else {
        data = [];
      }
    } catch (e) {
      console.log(e);
      data = [];
    }
  };
  const wasCalled = useRef(false);

  useEffect(() => {
    if (wasCalled.current) return;
    wasCalled.current = true;
    FooterData();
  }, []);
  return (
    <Box
      sx={{
        mt: 10,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <center>
        <Box className="hr-box" sx={{ width: "70%", marginTop: "10vh" }}>
          <Box
            sx={{
              display: "flex",
              felxDirection: "column",
              float: "right",
              transform: "translate(-20px, -65px)",
            }}
          >
            <Box>
              <hr />
            </Box>
            <Box>
              <img
                src={Img16}
                style={{ position: "fixed" }}
                width="65px"
                height="65px"
              />
            </Box>
          </Box>
        </Box>

        {fData?.footer_links &&
          fData?.footer_links.length > 0 &&
          fData?.footer_links.map((val) => {
            return (
              <>
                <Box
                  sx={{ width: "100%" }}
                  display="flex"
                  justifyContent="center"
                  textAlign="center"
                >
                  <p className="footer-title">{val?.title}</p>
                </Box>
                <Box sx={{ marginTop: "20px" }}>
                  <div className="footer-content">
                    <p>{val?.text?parse(val?.text):""}</p>
                  </div>
                </Box>
                <Box>
                  <img
                    width="237px"
                    height="212px"
                    src={val?.logo ? val?.logo : ""}
                  />
                </Box>
              </>
            );
          })}
      </center>
    </Box>
  );
};
export default Footer;
