import { Avatar, Button, Grid, Radio } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { errorMsg, infoMsg, success } from "../../Components/ToasterFn";
import TagsCounterBtn from "./TagsCounterBtn";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import CheckIcon from "@mui/icons-material/Check";
import { addTagsToCart } from "../../Components/utils/Helper";
import axios from "axios";
import {
  createGuestCart,
  getCartCount,
  getGuestUserCart,
  getLocalUserData,
} from "../../Components/utils/Util";
import TagColors from "./TagColors";

const TagsCard = ({ val, ind, btn, setBtn, count, setCount }) => {
  const navigate = useNavigate();
  const [catTags, setCatTags] = useState([]);
  const [dogTags, setDogTags] = useState([]);
  const [selectedValue, setSelectedValue] = useState("orange");
  const [selectedValue1, setSelectedValue1] = useState("orange");
  const [counterVal, setCounterval] = useState(0);
  const [color, setColor] = useState("");
  const [size, setSize] = useState("");
  const [amount, setAmount] = useState(0);
  const [catTagSize, setCatTagSize] = useState("m");
  const [dogTagSize, setDogTagSize] = useState("m");
  const [counter, setCounter] = useState(0);
  const [checkVal, setCheckVal] = useState("");
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleChange1 = (event) => {
    setSelectedValue1(event.target.value);
  };

  const controlProps = (item, para) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  const controlProps1 = (item) => ({
    checked: selectedValue1 === item,
    onChange: handleChange1,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });
  console.log("selected value", size);
  const myArray = selectedValue.split("-");
  console.log("mnn", myArray[0]);
  const uid = getLocalUserData().token;

  const tag_id = val?.id;
  const guestCart = getGuestUserCart();
  console.log("gtoken", guestCart);
  const tokenid = getLocalUserData()?.token
  const tokenParam = {
    token: tokenid
  }
  const handleCart = async () => {
    const param = {
      size: size,
      color: myArray[0],
      quantity: counter,
      item_id: tag_id,
      cart_id: guestCart ? guestCart : "",
      token: uid ? uid : "",
    };
    // const formData = new FormData();
    // formData.append("size", size);
    // formData.append("color", myArray[0]);
    // formData.append("quantity", counter);
    // formData.append("item_id", tag_id);
    // formData.append("cart_id", );

    var data = [];

    try {
      const res = await axios.post(addTagsToCart(), param);
      if (res.status == 200 && res.data) {
        data = res.data;
        console.log("add tag ", data);
        // window.location.reload();
        if (data?.status) {
          getCartCount(tokenParam, count, setCount);
          success(data?.message);
          setBtn(true);
          setSelectedValue("orange")
          setSize("")
          setCheckVal("")
          setCounter(0)
          if (guestCart) {
          } else {
            createGuestCart(data?.cart_item_id ? data?.cart_item_id : "");
          }
        } else {
          errorMsg(data?.message);
        }
      } else {
        data = [];
        console.log(res?.response?.data?.message);
        errorMsg(res?.data?.message);
      }
    } catch (e) {
      console.log(e);
      errorMsg(e?.response?.data?.message);

      data = [];
    }
  };

  return (
    <>
      <Box className="dog-tag-price" sx={{ marginTop: "20px" }} key={val?.id}>
        <Grid container justifyContent="flex-end">
          <Grid item xs={12} sm={12} md={5} className="home-tag-img">
            <div className="home-tag-main">
              <img src={val?.pic} alt="" />
            </div>
          </Grid>
          <Grid
            container
            textAlign="left"
            rowSpacing={0}
            item
            md={6}
            className="container-padding"
          >
            <Grid item xs={6} sm={6} md={6}>
              <p className="tag-header">{val?.title}</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <p className="tag-price">{val?.price} AED, Excluding Delivery Charges</p>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <p className="pet-tag-fact">{val?.sub_title} </p>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Box className="tag-facts">
                {val?.key_features?.map((item) => {
                  return (
                    <p className="checked-tag-facts">
                      <CheckIcon sx={{ color: "#FD9B13" }} />
                      {item ? item : ""}
                    </p>
                  );
                })}
              </Box>
            </Grid>
            <Grid container item md={12}>
              <Grid item xs={4} sm={2} md={6}>
                <p className="tag-specs">Color:</p>
              </Grid>
              <Grid textAlign="left" item xs={6} sm={3} md={6}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "left",
                    columnGap: "10px",
                    marginTop: "-10px",
                    marginLeft: "-10px",
                  }}
                >
                  {val?.color?.map((clr, itemid) => {
                    return (
                      <Radio
                        {...controlProps(
                          `${clr?.id}-${clr?.title}-${ind}-${itemid}`
                        )}
                        sx={{
                          color: `${clr?.color_code}`,
                          "&.Mui-checked": {
                            color: `${clr?.color_code}`,
                          },
                        }}
                      />
                    );
                  })}
                </Box>
              </Grid>

              <Grid item xs={3} sm={2} md={6} className="mt-2 mb-2">
                <p className="tag-specs">Size:</p>
              </Grid>

              {val?.size?.map((item) => {
                return (
                  // <TagColors item={item} setSize={setSize} infoMsg={infoMsg} val={val}  />
                  <Grid item xs={4} sm={1.5} md={1} className="mt-2 mb-2">
                    <Avatar
                      onClick={() => {
                        setSize(item?.id);
                        setCheckVal(item?.title)
                        infoMsg(`${item?.title} ${val?.pet_type} Tag Selected`);
                      }}
                      sx={{
                        border: item?.title == checkVal ? '1px solid black' : 'none',
                        cursor: 'pointer',
                        bgcolor: "#FD9B13",
                        width: "22px",
                        height: "21px",
                        fontSize: "15px",
                        marginTop: "-5px",
                      }}
                    >
                      {item?.display_title ? item?.display_title : ""}
                    </Avatar>
                  </Grid>
                );
              })}


              <TagsCounterBtn setCounter={setCounter} counter={counter} />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={1} className="mt-2 mb-2">
            <Box
              className="add-cart-box-btn"
              sx={{
                marginTop: "5px",
                height: "90%",
                width: "60%",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
              }}
            >
              <Box className="add-cart-btn">
                <Button
                  onClick={() => {
                    // setCatTags({
                    //   type: "cat",
                    //   size: catTagSize,
                    //   number: counter,
                    //   total: 50 * counter,
                    //   color: selectedValue ? selectedValue : "orange",
                    // });
                    handleCart();
                    // infoMsg(`${counter} ${val?.pet_type} tag(s) added to cart`);
                  }}
                  sx={{
                    width: "120px",
                    height: "120px",
                    background: "#FD9B13",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    borderRadius: "50%",
                    color: "black",
                    backgroundColor: "orange",
                    fontFamily: "MS Reference Sans Serif",
                    fontWeight: "400",
                    textTransform: "none",
                    fontSize: "15px",
                    fontWeight: "bold",
                    "&:hover": {
                      backgroundColor: "orange",
                      color: "white",
                    },
                    display: "block",
                  }}
                >
                  <ShoppingCartOutlinedIcon
                    className="btn-shopping-cart"
                    sx={{
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      fontSize: "35px",
                    }}
                  />{" "}
                  Add To Cart
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default TagsCard;
