import React, { useState, useEffect } from 'react';
import UserContext from './UserContext';
import axios from 'axios';
import { success, errorMsg } from '../ToasterFn';

export default function UserProvider({ children }) {

    const [userCtx, setUserCtx] = useState({
        // mainUrl: "",
        // // login: login, 
        // role: getCookie("role"),
        // user: typeof window !== undefined ? JSON.parse(localStorage.getItem("userProfile")) : "",
        // config: {
        //     bucketName: "",
        //     region: "",
        //     accessKeyId: "",
        //     secretAccessKey: "",
        // }
    });

    return (
        <UserContext.Provider value={{ userCtx, setUserCtx }}>
            {children}
        </UserContext.Provider>
    )
}