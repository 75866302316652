import { Box, Container, IconButton } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TounZLogo from "../../Components/assets/tounzLogo.png";
import CssTextField from "../../Components/Reusables/CssTextField";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import PetDialog from "../../Components/Dialogs/PetDialog/PetDialog";
import axios from "axios";
import UserContext from "../../Components/context/UserContext";
import { errorMsg } from "../../Components/ToasterFn";
import { getPetById } from "../../Components/utils/Helper";
const Pets = () => {
  const { userCtx } = useContext(UserContext);
  const navigate = useNavigate();
  const [id, setId] = useState("");
  const [openDetails, setOpenDetails] = useState(false);
  const [petData, setPetData] = useState([]);
  const [parentData, setParentData] = useState([]);

  const getPet = async () => {
    var form_data = new FormData();
    form_data.append("qr_code", id);

    var data = [];

    try {
      const res = await axios.post(getPetById(), form_data);
      if (res.status == 200 && res.data) {
        data = res.data;
        console.log("sid ", data);
        if (data?.status) {
          setPetData(data?.pet);
          setParentData(data?.user)
          setOpenDetails(true);
          setId("")
        } else {
          errorMsg(data?.message);
        }
      } else {
        data = [];
        errorMsg("Pet Is Not Found");
      }
    } catch (e) {
      console.log(e);
      data = [];
    }
    // await axios
    //   .post(getPetById(), form_data)
    //   .then((res) => {
    //     // getPetParent(res.data.userId);
    //     console.log("sid", res?.data);
    //     setPetData(res.data);
    //     // if(res?.data)
    //     setOpenDetails(true);
    //   })
    //   .catch((err) => {
    //     errorMsg("Pet Is Not Found");
    //   });
  };
  const getPetParent = async (data) => {
    await axios
      .get(`${userCtx.mainUrl}/auth/userProfile/${data}`)
      .then((res) => {
        setParentData(res.data.user);
        setOpenDetails(true);
      })
      .catch((err) => {
        // err;
      });
  };

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <center>
        <Container>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "300px",
            }}
          >
            <Box>
              <p
                style={{
                  fontFamily: "Calli Cat",
                  fontSize: "44px",
                  marginLeft: "-25px",
                }}
              >
             Search For Pet 
              </p>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                rowGap: "20px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CssTextField
                callback={(dt) => setId(dt)}
                placeholder="Enter Pet Tag Here"
                width="250px"
                defaultV={id}
              />
              <Box>
                <strong>
                  <IconButton
                    sx={{ color: "orange", textAlign: "center" }}
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={getPet}
                  >
                    <ArrowForwardIosIcon />
                  </IconButton>
                </strong>
              </Box>
            </Box>
          </Box>
        </Container>
      </center>
      {openDetails ? (
        <PetDialog
          {...{ openDetails, setOpenDetails }}
          petDetails={petData}
            parentData={parentData}
        />
      ) : null}
    </>
  );
};

export default Pets;
