import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Box, Container, Divider, Grid } from '@mui/material';
import DogImg from "../../assets/dog-img.png"
import CssButton from '../../Reusables/CssButton';
import './PetDialog.css'

export default function PetDialog({ openDetails, setOpenDetails, petDetails, parentData }) {
    const [open, setOpen] = React.useState(false);
    const [petData, setPetData] = React.useState(petDetails)
    const [petParentData, setPetPArentDAta] = React.useState(parentData)
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClose = () => {
        setOpenDetails(false);
    };

    return (
        <Dialog
            fullScreen={fullScreen}
            open={openDetails}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            maxWidth="lg"
        >
            <DialogContent>

                <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={3} >
                        <Box className="photo-box" >
                            <Box className="photo-card" >
                                <img className="pet-img" width="100%" height="100%" src={petData?.pet_pic} />
                            </Box>
                            <Box onClick={handleClose} className="report-btn" sx={{ mt: 2 }} >
                                <CssButton width="100%" height="100%" text="Back" textColor="black" color="#FD9B13" />
                            </Box>
                        </Box>
                    </Grid>
                    
                    <Grid container item xs={12} sm={12} md={9} >
                        <Grid container item xs={12} sm={12} md={12} >
                            <Grid item xs={12} sm={12} md={12}>
                                <Box className="pet-detail-header-box" >
                                    <p className='pet-detail-header' >Pet Info</p>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid columns={24} xs={24} sm={24} md={24} item container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
                            <Grid item xs={24} sm={8} md={4.8} >
                                <Grid item xs={24} sm={24} md={24} > <p className='pet-details-title' >Name of Pet</p>   </Grid>
                                <Grid item xs={24} sm={24} md={24}> <p className='pet-title-content' >{petData?.name} </p> </Grid>
                            </Grid>
                            <Grid item xs={24} sm={8} md={4.8}>
                                <Grid item xs={24} sm={24} md={24}> <p className='pet-details-title'>Pet Gender</p> </Grid>
                                <Grid item xs={24} sm={24} md={24}> <p className='pet-title-content'>{petData?.gender}</p> </Grid>
                            </Grid>
                            <Grid item xs={24} sm={8} md={4.8}>
                                <Grid item xs={24} sm={24} md={24}><p className='pet-details-title'>Breed</p> </Grid>
                                <Grid item xs={24} sm={24} md={24}><p className='pet-title-content'>{petData?.breed}</p> </Grid>
                            </Grid>
                            <Grid item xs={24} sm={8} md={4.8}>
                                <Grid item xs={24} sm={24} md={24}><p className='pet-details-title'>Pet Status</p> </Grid>
                                <Grid item xs={24} sm={24} md={24}> <p className='pet-title-content'>{petData?.is_missing?"Missing":"Safe"}</p> </Grid>
                            </Grid>
                            <Grid item xs={24} sm={8} md={4.8}>
                                <Grid item xs={24} sm={24} md={24}> <p className='pet-details-title'>Comments</p>  </Grid>
                                <Grid item xs={24} sm={24} md={24}><p className='pet-title-content'>{petData?.description}</p> </Grid>
                            </Grid>
                        </Grid>
                        <Divider className='section-divider' sx={{ bgcolor: '#fec06c', height: "2px", textAlign: "center", width: '100%' }} />
                        <Grid columns={24} xs={24} sm={24} md={24} item container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >

                            <Grid item xs={24} sm={8} md={4.8} >
                                <Grid item xs={24} sm={24} md={24} > <p className='pet-details-title' >Parent Name</p>   </Grid>
                                <Grid item xs={24} sm={24} md={24}> <p className='pet-title-content' > {petParentData?.name}</p> </Grid>
                            </Grid>
                            <Grid item xs={24} sm={8} md={4.8}>
                                <Grid item xs={24} sm={24} md={24}> <p className='pet-details-title'>Contact N.</p> </Grid>
                                <Grid item xs={24} sm={24} md={24}> <p className='pet-title-content'>{petParentData?.mobile}</p> </Grid>
                            </Grid>
                            <Grid item xs={24} sm={8} md={4.8}>
                                <Grid item xs={24} sm={24} md={24}><p className='pet-details-title'>Email</p> </Grid>
                                <Grid item xs={24} sm={24} md={24}><p className='pet-title-content'>{petParentData?.email}</p> </Grid>
                            </Grid>
                            <Grid item xs={24} sm={8} md={4.8}>
                                <Grid item xs={24} sm={24} md={24}><p className='pet-details-title'>Address</p> </Grid>
                                <Grid item xs={24} sm={24} md={24}> <p className='pet-title-content'>{petParentData?.address?petParentData?.address:""}</p> </Grid>
                            </Grid>
                            <Grid item xs={24} sm={8} md={4.8}>
                                <Grid item xs={24} sm={24} md={24}> <p className='pet-details-title'>Country</p>  </Grid>
                                <Grid item xs={24} sm={24} md={24}><p className='pet-title-content'>{petParentData?.country}</p> </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
