// import { ChangeEvent, useState } from 'react';

// function FileUploadMultiple() {
//   const [fileList, setFileList] = useState(null);

//   const handleFileChange = (e) => {
//     setFileList(e.target.files);
//   };

//   const handleUploadClick = () => {
//     if (!fileList) {
//       return;
//     }
// console.log("files",files)
//     const data = new FormData();
//     files.forEach((file, i) => {
//       data.append(`file-${i}`, file, file.name);
//     });

//     fetch('https://httpbin.org/post', {
//       method: 'POST',
//       body: data,
//     })
//       .then((res) => res.json())
//       .then((data) => console.log(data))
//       .catch((err) => console.error(err));
//   };

//   const files = fileList ? [...fileList] : [];

// const AppendDiv=()=>{
//     let content=`
//     <input type="file" onChange={(e)=>handleFileChange(e)} multiple  className="form-control"/>

//     <ul>
//       {files.map((file, i) => (
//         <li key={i}>
//           {file.name} - {file.type}
//         </li>
//       ))}
//     </ul>

//     <button onClick={handleUploadClick} className="btn ">Upload</button>
//     <button onClick={()=>{AppendDiv()}}>Add</button>
//   `
//     document.getElementsByClassName("xyz").innerHtml=content
// }

//   return (

//     <div className='xyz'>
//       <input type="file" onChange={(e)=>handleFileChange(e)} multiple  className="form-control"/>

//       <ul>
//         {files.map((file, i) => (
//           <li key={i}>
//             {file.name} - {file.type}
//           </li>
//         ))}
//       </ul>

//       <button onClick={handleUploadClick} className="btn ">Upload</button>
//       <button onClick={()=>{AppendDiv()}}>Add</button>
//     </div>
//   );
// }

// export default FileUploadMultiple;

// import React, { useState } from 'react';

// function FileUploadMultiple() {
// const [files, setFiles] = useState([]);
// const [inputCount, setInputCount] = useState(1); // Initial input count

// const handleFileChange = (e) => {
//     const selectedFiles = Array.from(e.target.files);
//     setFiles([...files, ...selectedFiles]);
// };

// const handleUploadClick = () => {
//     // Implement your upload logic here
//     // const formData = new FormData();

//     // // Append each selected file to the FormData object
//     // files.forEach((file) => {
//     //   formData.append('images', file);
//     // const files = fileList ? [...fileList] : [];
//     // });

//     const data = new FormData();
//         files.forEach((file, i) => {
//         data.append(`file-${i}`, file, file.name);
//         });

//         fetch('https://httpbin.org/post', {
//         method: 'POST',
//         body: data,
//         })
//         .then((res) => res.json())
//         .then((data) => console.log(data))
//         .catch((err) => console.error(err));

//     };

// const AppendDiv = () => {
//     setInputCount(inputCount + 1);
// };

// return (
//     <div>
//     {Array.from({ length: inputCount }).map((_, index) => (
//         <div className='xyz' key={index}>
//         <input
//             type="file"
//             onChange={(e) => handleFileChange(e)}
//             className="form-control "

//         />
//         {/* <ul>
//             {files.map((file, i) => (
//             <li key={i}>
//                 {file.name} - {file.type}
//             </li>
//             ))}
//         </ul> */}
//         </div>
//     ))}
//     {
//         inputCount<5?<button onClick={AppendDiv} className="btn btn-outline-dark" disabled={inputCount>4}>Add</button>:""
//     }

//         <button onClick={handleUploadClick} className="btn btn-outline-dark">
//             Upload
//         </button>
//     </div>
// );
// }

// export default FileUploadMultiple;

import React, { useState } from "react";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

function FileUploadMultiple({ multi }) {
  const [files, setFiles] = useState([]);
  const [inputCount, setInputCount] = useState(1);
  const [allImg, setAllImg] = useState([]);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles([...files, ...selectedFiles]);
  };

  const handleUploadClick = () => {
    const data = new FormData();
    files.forEach((file, i) => {
      allImg.push(file);
      data.append(`file-${i}`, file);
    });

    fetch("https://httpbin.org/post", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((data) => console.log(data))
      .catch((err) => console.error(err));
    console.log("submitted", allImg);
  };

  const handleDeleteClick = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const AppendDiv = () => {
    setInputCount(inputCount + 1);
  };

  return (
    <div>
      {Array.from({ length: inputCount }).map((_, index) => (
        <div className="xyz my-2 d-flex justify-content-center" key={index}>
          {/* Replace the input tag with a Material-UI file input */}
          <input
            type="file"
            onChange={(e) => handleFileChange(e)}
            style={{ display: "none" }}
            id={`file-input-${index}`}
          />
          <label htmlFor={`file-input-${index}`}>
            <Button
              component="span"
              variant="contained"
              color="warning"
              startIcon={<CloudUploadIcon />}
            >
              {files[index] ? "Uploaded" : "Upload File"}
            </Button>
          </label>
          {files[index] && (
            <button onClick={() => handleDeleteClick(index)} className="btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="1.2em"
              >
                <path d="M4 8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8ZM6 10V20H18V10H6ZM9 12H11V18H9V12ZM13 12H15V18H13V12ZM7 5V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V5H22V7H2V5H7ZM9 4V5H15V4H9Z"></path>
              </svg>{" "}
            </button>
          )}
        </div>
      ))}
      {multi ? (
        <div className="d-flex justify-content-center">
          {inputCount < 3 ? (
            <button
              onClick={AppendDiv}
              className="btn"
              disabled={inputCount > 4}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="1.2em"
              >
                <path d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"></path>
              </svg>
            </button>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}

      {/* <button onClick={handleUploadClick} className="btn btn-outline-dark">
        Upload
      </button> */}
    </div>
  );
}

export default FileUploadMultiple;
