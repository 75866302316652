import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { InputAdornment, TextField } from "@mui/material";
const CssTextField = (props) => {
    const { err, defaultV, required, callback, width, height, placeholder, size, endIcon, type, field } = props;
    return (
        <TextField

            required={required ? required : ""}
            onChange={(e) => callback(e.target.value, field)}
            className='duplicate-fields'
            type={type ? type : "text"}

            autoFocus
            InputProps={{
                endAdornment: (
                    endIcon == false ?
                        null
                        : <InputAdornment position="end">
                            <CreateOutlinedIcon sx={{ color: 'grey' }} />
                        </InputAdornment>
                ),
            }}
            sx={{

                '& label.Mui-focused': {
                    color: 'black',
                },
                '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                        borderColor: 'orange',
                    },
                },
                width: width ? width : "100%",
                [`& fieldset`]: {
                    borderRadius: "23px",
                },


            }} size={size ? size : ""}
            placeholder={placeholder}
            value={defaultV}
        />

    );
};
export default CssTextField;