import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { Box } from "@mui/material";
import { display, textAlign } from "@mui/system";

const CssUploadButton = (props) => {
    const { callback, text, width, height, color, radius, fontSize, variant, onClick, icon, textColor, ...other } = props;
    return (
        <Box
            sx={{
                width: width,
                height: height,
                background: "#FD9B13",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: radius ? radius : "50px",
                color: textColor ? textColor : "black",
                backgroundColor: color,
                fontFamily: 'MS Reference Sans Serif',
                fontWeight: "bolder",
                fontSize: fontSize,
                fontStyle: "toLowerCase",
                textTransform: "unset",
                '&:hover': {
                    backgroundColor: color,
                    color: 'white',

                },
                textAlign: 'center',
                paddingTop: '10px',
                cursor: 'pointer'
            }}

            variant="contained"
        >
            <label style={{ cursor: 'pointer' }} for={text + "file_"}>
                <FileUploadIcon /> {text}
            </label>
            <input onChange={(e) => callback(e.target.files[0])} type="file" style={{ display: 'none' }} id={text + "file_"} accept="application/pdf,image/*" />
        </Box>
    );
};
export default CssUploadButton;