import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import CssTextField from "../../Components/Reusables/CssTextField";
import CssAutocomplete from "../../Components/Reusables/CssAuocomplete";
import CssButton from "../../Components/Reusables/CssButton";
import { editParentDetails } from "../../Components/utils/Helper";
import { errorMsg, success } from "../../Components/ToasterFn";
import axios from "axios";
import { getLocalUserData } from "../../Components/utils/Util";
import { MuiTelInput } from "mui-tel-input";
const EditUserInfo = ({ data }) => {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [textValue, setTextValue] = useState("");

  const onTextChange = (e) => setTextValue(e.target.value);
  const handleSubmit = () => console.log(textValue);
  const handleReset = () => setTextValue("");
  const [imageValue, setImageValue] = useState("");

  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [userMobile, setUserMobile] = useState("");
  const [userAdd, setUserAdd] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userCountry, setUserCountry] = useState("");
  const [userCity, setUserCity] = useState("");
  const [userIdNum, setUserIdNum] = useState("");
  const [userEmerName, setUserEmerName] = useState("");
  const [userEmerName2, setUserEmerName2] = useState("");
  const [userEmerRel, setUserEmerRel] = useState("");
  const [userEmerCon, setUserEmerCon] = useState("");

  const [uploadedFile, setUploadedFile] = useState(null);

  useEffect(() => {
    setUserId(data?.id);
    setUserName(data?.full_name);
    setUserMobile(data?.mobile);
    setUserAdd(data?.address);
    setUserEmail(data?.email);
    setUserCountry(data?.country);
    setUserCity(data?.city);
    setUserIdNum(data?.id_number);
    // setUserEmerName(data?.emergency_contact_name)
    setUserEmerRel(data?.emergency_contact_relationship);
    setUserEmerCon(data?.emergency_contact_number);

    setUserEmerName(data?.emergency_contact_f_name);
    setUserEmerName2(data?.emergency_contact_l_name);
  }, [data]);
  const [isImage,setIsImage] = useState(false);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if(file){
      setUploadedFile(file);
      setIsImage(true);
    }
  };

  // console.log("user id", userName)

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const uid = getLocalUserData().token;
  const isValidEmail = (email) => {
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return pattern.test(email);
  };
  const saveddata = async (anchor) => {
    let tosave = "false";
    const formData = new FormData();
    formData.append("token", uid);
    formData.append("full_name", userName);
    formData.append("mobile", userMobile);
    formData.append("address", userAdd);

    if (isValidEmail(userEmail)) {
      formData.append("email", userEmail);
      tosave = true;
    } else {
      tosave = false;
      errorMsg("Please enter a valid email");
    }
    formData.append("country", userCountry);
    if (userCity) {
      formData.append("city", userCity);
    } else {
      // tosave = false;
      // errorMsg("Please Provide City Name")
    }
    formData.append("id_number", userIdNum);
    if (isImage) {
      formData.append("photo_id", uploadedFile);
    }
    formData.append("emergency_contact_f_name", userEmerName);
    formData.append("emergency_contact_number", userEmerCon);
    formData.append("emergency_contact_l_name", userEmerName2);
    formData.append("emergency_contact_relationship", userEmerRel);

    var data = [];
    if (tosave) {
      try {
        const res = await axios.post(editParentDetails(userId), formData);
        if (res.status && res.data) {
          data = res.data;
          console.log("useredit ", data);
          if (data?.status) {
            success(data?.message);
            window.location.reload();
          } else {
            errorMsg(data?.message);
          }
        } else {
          data = [];
          console.log(res?.response?.data?.message);
          errorMsg(res?.data?.message);
        }
      } catch (e) {
        console.log(e);
        errorMsg(e?.response?.data?.message);

        data = [];
      }
    }
  };

  // const handleImageChange = (event) => {
  //   const selectedImage = event.target.files[0];

  //   // Perform operations with the selected image here
  //   // For example, you can read the file value using FileReader API

  //   const reader = new FileReader();
  //   reader.onload = function (e) {
  //     const imageValue = e.target.result;
  //     setImageValue(imageValue);
  //   };

  //   reader.readAsDataURL(selectedImage);
  //   console.log("image-1", imageValue);
  //   console.log("image-2", selectedImage);
  // };
  return (
    <div>
      {["right"].map((anchor) => (
        <>
          <Button
            onClick={toggleDrawer(anchor, true)}
            className="edit-info-btn"
          >
            Edit info
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            className="edit-info-offcanvas"
          >
            <div className="mb-5">
              <h6 className="ms-4 me-4 mt-4">Edit Info</h6>
              <div className="d-flex justify-content-center px-3 mt-3">
                <Grid item xs={12} sm={12} md={6}>
                  <p className="pet-info-title2">Parent Name</p>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <CssTextField
                    required="true"
                    defaultV={userName}
                    callback={(e) => {
                      setUserName(e);
                    }}
                    field="name"
                    width="100%"
                    height="10px"
                    placeholder="Enter Parent's Name"
                    size="small"
                  />
                </Grid>{" "}
              </div>
              <div className="d-flex justify-content-center px-3 mt-3">
                <Grid item xs={12} sm={12} md={6}>
                  <p className="pet-info-title2">Contact No.</p>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <MuiTelInput
                    onlyCountries={["AE", "CA", "GB", "KW"]}
                    size="small"
                    defaultCountry={
                      userCountry === "Canada"
                        ? "CA"
                        : userCountry === "United Kingdom"
                        ? "GB"
                        : userCountry === "Kuwait"
                        ? "KW"
                        : "AE"
                    }
                    sx={{
                      width: "100%",
                      "&.MuiFormControl-root": {
                        "& .MuiInputBase-root": {
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "1px solid #bfb7aa",
                          },
                        },
                      },
                    }}
                    placeholder="Enter Parent's Contact"
                    value={userMobile}
                    onChange={(data) => setUserMobile(data)}
                  />
                </Grid>{" "}
              </div>
              <div className="d-flex justify-content-center px-3 mt-3">
                <Grid item xs={12} sm={12} md={6}>
                  <p className="pet-info-title2">Address</p>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <CssTextField
                    required="true"
                    defaultV={userAdd}
                    callback={(e) => {
                      setUserAdd(e);
                    }}
                    field="name"
                    width="100%"
                    height="10px"
                    placeholder="Enter Parent's Address"
                    size="small"
                  />
                </Grid>{" "}
              </div>
              <div className="d-flex justify-content-center px-3 mt-3">
                <Grid item xs={12} sm={12} md={6}>
                  <p className="pet-info-title2">Email</p>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <CssTextField
                    required="true"
                    defaultV={userEmail}
                    callback={(e) => {
                      setUserEmail(e);
                    }}
                    field="name"
                    width="100%"
                    height="10px"
                    placeholder="Enter Parent's Email"
                    size="small"
                  />
                </Grid>{" "}
              </div>
              <div className="d-flex justify-content-center px-3 mt-3">
                <Grid item xs={12} sm={12} md={6}>
                  {" "}
                  <p className="pet-info-title2">Country</p>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <CssAutocomplete
                    placeholder="Choose Country"
                    value={userCountry}
                    callback={(e) => setUserCountry(e)}
                    options={[
                      "Canada",
                      "United Arab Emirates",
                      "United Kingdom",
                    ]}
                  />
                </Grid>
              </div>
              <div className="d-flex justify-content-center px-3 mt-3">
                <Grid item xs={12} sm={12} md={6}>
                  {" "}
                  <p className="pet-info-title2">City</p>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <CssTextField
                    required="true"
                    callback={(e) => {
                      setUserCity(e);
                    }}
                    defaultV={userCity}
                    field="city"
                    height="10px"
                    placeholder="Enter Parent's City"
                    size="small"
                  />
                </Grid>
              </div>
              <div className="d-flex justify-content-center px-3 mt-3">
                <Grid item xs={12} sm={12} md={6}>
                  {" "}
                  <p className="pet-info-title2">ID Number</p>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <CssTextField
                    callback={(e) => {
                      setUserIdNum(e);
                    }}
                    defaultV={userIdNum}
                    field="city"
                    height="10px"
                    placeholder="Enter Parent's ID"
                    size="small"
                    type="number"
                  />
                </Grid>{" "}
              </div>
              <div className="d-flex justify-content-center px-3 mt-3 imageInput">
                <Grid item xs={12} sm={12} md={6}>
                  {" "}
                  <p className="pet-info-title2">Photo Id</p>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <label htmlFor="upload-photo">
                    <input
                      style={{ display: "none" }}
                      id="upload-photo"
                      name="upload-photo"
                      type="file"
                      // value={uploadedFile}
                      onChange={handleImageChange}
                    />

                    <Button
                      color="warning"
                      variant="contained"
                      component="span"
                    >
                      Upload Photo ID
                    </Button>
                  </label>
                </Grid>
              </div>
              <div className="d-flex justify-content-center px-3 mt-3">
                <Grid item xs={12} sm={12} md={6}>
                  {" "}
                  <p className="pet-info-title2">Emer. Contact First Name</p>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <CssTextField
                    callback={(e) => setUserEmerName(e)}
                    defaultV={userEmerName}
                    field="vetName"
                    width="100%"
                    height="10px"
                    placeholder="Enter Emergency First Name"
                    size="small"
                  />
                </Grid>
              </div>
              <div className="d-flex justify-content-center px-3 mt-3">
                <Grid item xs={12} sm={12} md={6}>
                  {" "}
                  <p className="pet-info-title2">Emer. Contact Last Name</p>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <CssTextField
                    callback={(e) => setUserEmerName2(e)}
                    defaultV={userEmerName2}
                    field="vetName"
                    width="100%"
                    height="10px"
                    placeholder="Enter Emergency Last Name"
                    size="small"
                  />
                </Grid>
              </div>
              <div className="d-flex justify-content-center px-3 mt-3">
                <Grid item xs={12} sm={12} md={6}>
                  {" "}
                  <p className="pet-info-title2">Emer. Contact No.</p>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <MuiTelInput
                    onlyCountries={["AE", "CA", "GB", "KW"]}
                    size="small"
                    defaultCountry={
                      userCountry === "Canada"
                        ? "CA"
                        : userCountry === "United Kingdom"
                        ? "GB"
                        : userCountry === "Kuwait"
                        ? "KW"
                        : "AE"
                    }
                    sx={{
                      width: "100%",
                      "&.MuiFormControl-root": {
                        "& .MuiInputBase-root": {
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "1px solid #bfb7aa",
                          },
                        },
                      },
                    }}
                    placeholder="Enter Emergency Contact"
                    value={userEmerCon}
                    onChange={(data) => setUserEmerCon(data)}
                  />
                </Grid>
              </div>
              <div className="d-flex justify-content-center px-3 mt-3">
                <Grid item xs={12} sm={12} md={6}>
                  {" "}
                  <p className="pet-info-title2">Emer. Contact Relation</p>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <CssTextField
                    required="true"
                    callback={(e) => setUserEmerRel(e)}
                    defaultV={userEmerRel}
                    field="vetName"
                    width="100%"
                    height="10px"
                    placeholder="Enter Emergency Person Relation"
                    size="small"
                  />
                </Grid>
              </div>
              <div className="text-center mt-4 mb-3">
                <Grid
                  item
                  display="flex"
                  justifyContent="flex-end"
                  container
                  xs={8.2}
                  sm={7.5}
                  md={8}
                >
                  <Box className="save-pet-btn">
                    <CssButton
                      callback={(e) => {
                        e.preventDefault();
                        saveddata(anchor);
                      }}
                      btnType="submit"
                      width="160px"
                      height="35px"
                      text="Save"
                      color="orange"
                      fontSize="15px"
                    />
                  </Box>
                </Grid>{" "}
              </div>
            </div>
          </Drawer>
        </>
      ))}
    </div>
  );
};
export default EditUserInfo;
