import { Button } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "../OrderList/OrderList.css";
import { Link } from "react-router-dom";
import { getLocalUserData } from "../../Components/utils/Util";
import axios from "axios";
import { UserOrderDetails } from "../../Components/utils/Helper";

const OrderList = () => {
  const [totalOrder, setTotalOrder] = useState([]);
  const token = getLocalUserData()?.token;
  const param = {
    token: token,
  };
  console.log("sss", token);
  const orderDetails = async () => {
    let data = [];
    try {
      const res = await axios.post(UserOrderDetails(), param);
      if (res.status == 200 && res.data) {
        data = res.data;
        console.log("daa", data);
        if (data?.status) {
          setTotalOrder(data?.orders);
        }
      } else {
        data = [];
      }
    } catch (e) {
      console.log(e);
      data = [];
    }
  };
  const wasCalled = useRef(false);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if(wasCalled.current) return;
    wasCalled.current = true;
    orderDetails();

  }, []);
  return (
    <>
      <div className="container mt-5">
        <div className="d-flex justify-content-center mt-3 mb-3">
          <p class="header-title">Order List</p>
        </div>
        <div className="row">
          <div className="col-12 ">
            <div className="row">
              {/* <div className="mt-4 col-12 mt-3 col-md-3 col-xl-3 col-lg-3">
                <div class="card">
                  <div className="px-4 ">
                    <img
                      src="https://tounz.bangalore2.com/static/media/7.52df342568c87a29775b.png"
                      className="img-fluid"
                    />
                  </div>
                  <div className="mt-3">
                    <h6
                      className="
              text-start ms-3 list-headings "
                    >
                      Tags Purchased:{" "}
                      <span className="list-page-details">6</span>
                    </h6>{" "}
                    <h6
                      className="
              text-start ms-3 list-headings "
                    >
                      Total Price:{" "}
                      <span className="list-page-details">65.00 AED</span>
                    </h6>
                    <h6
                      className="
              text-start ms-3 list-headings "
                    >
                      Order Date:{" "}
                      <span className="list-page-details">02-Aug-2023</span>
                    </h6>
                    <Link to="/Orderdetails/123">
                      <Button className="view-btn mt-3 mb-3 ">View</Button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="mt-4 col-12 col-md-3 col-xl-3 col-lg-3">
                <div class="card">
                  <div className="px-4 ">
                    <img
                      src="https://tounz.bangalore2.com/static/media/7.52df342568c87a29775b.png"
                      className="img-fluid"
                    />
                  </div>
                  <div className="mt-3">
                    <h6
                      className="
              text-start ms-3 list-headings "
                    >
                      Tags Purchased:{" "}
                      <span className="list-page-details">2</span>
                    </h6>{" "}
                    <h6
                      className="
              text-start ms-3 list-headings "
                    >
                      Total Price:{" "}
                      <span className="list-page-details">25.00 AED</span>
                    </h6>
                    <h6
                      className="
              text-start ms-3 list-headings "
                    >
                      Order Date:{" "}
                      <span className="list-page-details">10-Aug-2023</span>
                    </h6>
                    <Button className="view-btn mt-3 mb-3">View</Button>
                  </div>
                </div>
              </div>
              <div className="mt-4 col-12 col-md-3 col-xl-3 col-lg-3">
                <div class="card">
                  <div className="px-4 ">
                    <img
                      src="https://tounz.bangalore2.com/static/media/7.52df342568c87a29775b.png"
                      className="img-fluid"
                    />
                  </div>
                  <div className="mt-3">
                    <h6
                      className="
              text-start ms-3 list-headings "
                    >
                      Tags Purchased:{" "}
                      <span className="list-page-details">4</span>
                    </h6>{" "}
                    <h6
                      className="
              text-start ms-3 list-headings "
                    >
                      Total Price:{" "}
                      <span className="list-page-details">65.00 AED</span>
                    </h6>
                    <h6
                      className="
              text-start ms-3 list-headings "
                    >
                      Order Date:{" "}
                      <span className="list-page-details">19-Jul-2023</span>
                    </h6>
                    <Button className="view-btn mt-3 mb-3">View</Button>
                  </div>
                </div>
              </div> */}
              {totalOrder && totalOrder.length > 0 ? (
                <>
                  {totalOrder.map((item) => {
                    return (
                      <>
                        <div className="mt-4 col-12 col-md-3 col-xl-3 col-lg-3">
                          <div class="card">
                            <div className="px-4 ">
                              <img
                                src="https://tounz.bangalore2.com/static/media/7.52df342568c87a29775b.png"
                                className="img-fluid"
                              />
                            </div>
                            <div className="mt-3">
                              <h6
                                className="
              text-start ms-3 list-headings "
                              >
                                Tags Purchased:{" "}
                                <span className="list-page-details">
                                  {item?.item_count}
                                </span>
                              </h6>{" "}
                              <h6
                                className="
              text-start ms-3 list-headings "
                              >
                                Total Price:{" "}
                                <span className="list-page-details">
                                  {/* 175.00 AED */}
                                  {item?.total} {item?.currency}
                                </span>
                              </h6>
                              <h6
                                className="
              text-start ms-3 list-headings "
                              >
                                Order Date:{" "}
                                <span className="list-page-details">
                                  {/* 24-Jun-2023 */}
                                  {item?.order_at.slice(8, 10)}-
                                  {item?.order_at.slice(5, 7)}-
                                  {item?.order_at.slice(0, 4)}
                                </span>
                              </h6>
                              <h6
                                className="
              text-start ms-3 list-headings "
                              >
                                Payment Status:{" "}
                                <span className="list-page-details">
                                  {/* 175.00 AED */}
                                  {item?.status}
                                </span>
                              </h6>
                              <Link to={`/Orderdetails/${item.order_id}`}>
                                <Button className="view-btn mt-3 mb-3 ">
                                  View
                                </Button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </>
              ) : (
                <h3 className="text-center">No Order Found</h3>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderList;
