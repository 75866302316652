import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";
import { isPaymentCompleted } from "../Components/utils/Helper";
import { getCartCount, getLocalUserData } from "../Components/utils/Util";
import OrderFailure from "./OrderFailure";
import OrderSuccess from "./OrderSuccess";

const OrderStatus = ({ count, setCount }) => {
  const [searchData, setSearchData] = useSearchParams();
  console.log("sssss", searchData);
  const [paymentDone, setPaymentDone] = useState("pending");
  const [orderId, setOrderId] = useState("");
  const uid = getLocalUserData()?.token;

  const paymentStatus = async () => {
    const params = {
      client_id: searchData.get("payment_intent"),
      token: uid,
    };
    const param = {
      client_id: searchData.get("payment_intent"),
      token: uid,
    };
    let data = [];
    try {
      const res = await axios.post(isPaymentCompleted(), params);
      if (res.status == 200 && res.data) {
        data = res.data;
        console.log("dataaa", data);
        if (data?.status == "success") {
          setPaymentDone("success");
          setOrderId(data?.order_id);
          getCartCount(param, count, setCount);
        }
      } else if (data?.status == "error") {
        setPaymentDone("failed");
      } else {
        data = [];
      }
    } catch (e) {
      console.log(e);
      data = [];
    }
  };
  const wasCalled = useRef(false);

  useEffect(() => {
    console.log("srp", searchData.get("payment_intent_client_secret"));
    if(wasCalled.current) return;
    wasCalled.current = true;
    paymentStatus();
  }, []);

  return (
    <div>
      {/* {searchData.get("payment_intent_client_secret")}
      ---{searchData.get("payment_intent")}
      --- */}
      {paymentDone === "success" ? <OrderSuccess orderId={orderId} /> : ""}
      {paymentDone === "failed" ? <OrderFailure /> : ""}
    </div>
  );
};

export default OrderStatus;
