import { Container, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Navbar from "../../Components/Navbar/Navbar";
import UserContext from "../../Components/context/UserContext";
import {
  Form,
  redirect,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { errorMsg, infoMsg, success } from "../../Components/ToasterFn";
import CssButton from "../../Components/Reusables/CssButton";
import CssTextField from "../../Components/Reusables/CssTextField";
import axios from "axios";
import { resetNewPassword } from "../../Components/utils/Helper";

const ResetPassword = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useParams();
  console.log("lctb", location.token);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // const { userCtx, setUserCtx } = useContext(UserContext)

  const handleSave = async (e) => {
    if (password == "") {
      return errorMsg("Password must not be empty");
    } else if (password.length < 6) {
      return errorMsg("Password must be at least 6 characters");
    } else if (confirmPassword !== password) {
      return errorMsg("Passwords didn't match");
    } else {
      const form_data = new FormData();
      form_data.append("password1", password);
      form_data.append("password2", confirmPassword);
      form_data.append("hash", location?.token);

      var data = [];
      try {
        const res = await axios.post(resetNewPassword(), form_data);
        if (res.status && res.data) {
          data = res.data;
          console.log("onchnge--- ", data);
          // setTotalPrice(data?.data);
          if (data?.status) {
            success(data?.message);
            setPassword("");
            setConfirmPassword("");
          } else {
            errorMsg(data?.message);
          }
        } else {
          data = [];
          console.log(res?.response?.data?.message);
          errorMsg(res?.data?.message);
        }
      } catch (e) {
        console.log(e);
        errorMsg(e?.response?.data?.message);
        data = [];
      }
    }
  };

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <Box>
      <Container sx={{ display: "flex", justifyContent: "center" }}>
        <Grid container justifyContent="center" xs={11} sm={8} md={5} mt={10}>
          <Grid item xs={12} sm={12} md={12}>
            <Box sx={{ width: "100%" }}>
              <p style={{ fontSize: "34px" }} className="login-title">
                Enter Your New Password
              </p>
            </Box>
          </Grid>

          <form onSubmit={(e) => e.preventDefault()}>
            <Grid item>
              <Grid item rowSpacing={2} container md={12}>
                <Grid item xs={12} sm={12} md={6}>
                  <Box className="form-titles">
                    <p>Password</p>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <CssTextField
                    required={true}
                    callback={(dt) => setPassword(dt)}
                    width="100%"
                    height="33.09px"
                    size="small"
                    defaultV={password}
                    placeholder="Enter the password "
                    type="password"
                    endIcon={false}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Box className="form-titles">
                    <p>Confirm Password</p>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <CssTextField
                    required={true}
                    defaultV={confirmPassword}
                    callback={(dt) => setConfirmPassword(dt)}
                    width="100%"
                    height="33.09px"
                    size="small"
                    placeholder="Confirm the password "
                    type="password"
                    endIcon={false}
                  />
                </Grid>
              </Grid>
            </Grid>
          </form>

          <Grid
            item
            display="flex"
            justifyContent="center"
            mt={3}
            xs={12}
            sm={12}
            md={12}
          >
            <Button
              className="lgn-field"
              type="submit"
              sx={{
                width: "50%",
                background: "#FD9B13",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: "50px",
                color: "black",
                backgroundColor: "orange",
                fontFamily: "MS Reference Sans Serif",
                fontWeight: "400",
                fontSize: "15px",
                "&:hover": {
                  backgroundColor: "orange",
                  color: "white",
                },
              }}
              onClick={() => handleSave()}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ResetPassword;
