import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';

const CssButton = (props) => {
    const { btnType, required, outlineColor, hoverColor, callback, text, width, height, color, radius, fontSize, variant, onClick, icon, textColor, className, ...other } = props;
    return (
        <Button
            className={className ? className : "reusable-css-btn"}
            onClick={callback}
            required={required ? required : false}
            type={btnType ? btnType : ""}
            sx={{
                width: width,
                height: height,
                background: "#FD9B13",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: radius ? radius : "50px",
                color: textColor ? textColor : "black",
                backgroundColor: outlineColor ? outlineColor : color,
                fontFamily: 'MS Reference Sans Serif',
                fontWeight: "bolder",
                fontSize: fontSize,
                fontStyle: "toLowerCase",
                textTransform: "unset",
                border: '1px solid orange',

                '&:hover': {
                    backgroundColor: color,
                    color: hoverColor ? hoverColor : 'white',
                    border: '1px solid orange'

                },


            }}
            variant={variant ? variant : "contained"}
        >
            {icon === "upload" ? <FileUploadIcon /> : icon === "add" ? <AddIcon sx={{ fontSize: "18px" }} /> :
                icon === "cart" ? <p><ShoppingCartOutlinedIcon className="cartIcon" sx={{ position: 'absolute', transform: " translate(1.2vw, -35px)", fontSize: "25px", marginTop: '5px' }} /></p> : ""}

            {text === "Add To Cart" ? <p style={{ marginTop: '20px' }} >{text}</p> : text}

        </Button>
    );
};
export default CssButton;