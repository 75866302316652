import { Box, Grid, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import "./FooterNavbar.css";
import tounzLogo from "../assets/tounzLogo.png";
import { useEffect, useRef, useState } from "react";
import { Container } from "@mui/system";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import mail from "../assets/mail.png";
import wts from "../assets/wts.png";
import { FooterContents } from "../utils/Helper";
import axios from "axios";
const FooterNavbar = () => {
  const [ind, setInd] = useState(0);
  const navigate = useNavigate();
  const [toggleClassState, setToggleClassState] = useState("");
  const [toggleNavState, setToggleNavState] = useState("");

  const handleClick = () => {
    if (toggleClassState === "mobile-navF") {
      setToggleClassState("");
      setToggleNavState("");
    } else {
      setToggleClassState("mobile-navF");
      setToggleNavState("is-activeF");
    }
  };
  const [fData, setFData] = useState([]);

  const FooterData = async () => {
    let data = [];
    try {
      const res = await axios.get(FooterContents());
      if (res.status == 200 && res.data) {
        data = res.data;
        console.log("orders--", data);
        if (data?.status) {
          console.log("res", data?.data);
          setFData(data?.data);
        }
      } else {
        data = [];
      }
    } catch (e) {
      console.log(e);
      data = [];
    }
  };
  const wasCalled = useRef(false);

  useEffect(() => {
    if (wasCalled.current) return;
    wasCalled.current = true;
    FooterData();
  }, []);

  return (
    <Box>
      <Container
        maxWidth="lg"
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Grid container spacing={2} md={6} sm={12} xs={12} display="flex">
          {fData?.contact_info &&
            fData?.contact_info.length > 0 &&
            fData?.contact_info.map((val) => {
              return (
                <>
                  <Grid
                    item
                    md={6}
                    xs={12}
                    sm={6}
                    display="flex"
                    flexDirection="row"
                    justifyContent={{ xs: "center" }}
                  >
                    <img
                      src={val?.logo}
                      width="40px"
                      height="40px"
                      alt="flinks"
                    />
                    {val?.type === 1 ? (
                      <Box mt={1}>
                        <Typography
                          sx={{ fontFamily: "Calli Cat" }}
                          variant="body1"
                        >
                          <a
                            href={`mailto:${val?.link}`}
                            style={{ color: "black" }}
                          >
                            {" "}
                            {val?.sub_title}
                          </a>
                        </Typography>
                      </Box>
                    ) : (
                      <Box mt={1}>
                        <Typography
                          sx={{ fontFamily: "Calli Cat" }}
                          variant="body1"
                        >
                          <a
                            href={val?.link}
                            style={{ color: "black" }}
                            target="_blank"
                          >
                            {" "}
                            {val?.sub_title}
                          </a>
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                </>
              );
            })}
        </Grid>
      </Container>
      <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1.5rem",
          marginBottom: "1.5rem",
        }}
      >
        <Grid
          container
          spacing={2}
          md={6}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="center"
        >
          {fData?.social_media_links &&
            fData?.social_media_links.length > 0 &&
            fData?.social_media_links.map((val) => {
              return (
                <>
                  <a
                    href={val?.link ? val?.link : ""}
                    target="_blank"
                    className="mx-2"
                  >
                    <img src={val?.icon ? val?.icon : ""} alt="" width="40" />
                  </a>
                </>
              );
            })}
        </Grid>
      </Container>
      <Grid item xs={12} sm={12} md={12}>
        <center>
          {fData?.content &&
            fData?.content.length > 0 &&
            fData?.content.map((val) => {
              return (
                <>
                  <a href={val?.url} style={{ textDecorationColor: "black" }}>
                    <Typography
                      sx={{
                        fontStyle: "italic",
                        cursor: "pointer",
                        "&:hover": { color: "orange" },
                        fontFamily: "Calli Cat",
                        textDecoration: "none",
                        color: "black",
                      }}
                      variant="body1"
                      // onClick={downloadPdf}
                    >
                      {val?.text?val?.text:""}
                    </Typography>
                  </a>
                </>
              );
            })}
        </center>
      </Grid>
    </Box>
  );
};
export default FooterNavbar;
