import { Box, Grid, IconButton } from "@mui/material";
import React from "react";
import CssTextField from "../../Components/Reusables/CssTextField";
import { success } from "../../Components/ToasterFn";
import CheckIcon from "@mui/icons-material/Check";

const LinkNewTag = ({setChangeTag,id}) => {
  return (
    <Grid item xs={12} sm={6} md={6} key={id}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <CssTextField
          type="number"
          // callback={(e) => setNewTag(e)}
          width="100%"
          height="10px"
          placeholder="Enter tag number"
          size="small"
        />
        <IconButton
          onClick={() => {
            success("tag updated");
            setChangeTag(true);
          }}
          sx={{
            color: "black",
            backgroundColor: "orange",
            width: "30px",
            height: "30px",
            marginTop: "5px",
            marginLeft: "3px",
          }}
        >
          <CheckIcon />
        </IconButton>
      </Box>
    </Grid>
  );
};

export default LinkNewTag;
