import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Grow,
  IconButton,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import classes from "./UserAccount.module.css";
import "../UserAccount/useraccount.css";

import CssTextField from "../../Components/Reusables/CssTextField";
import CssSelect from "../../Components/Reusables/CssSelect";
import CssButton from "../../Components/Reusables/CssButton";
import CssUploadButton from "../../Components/Reusables/CssUploadButton";
import S3 from "react-aws-s3";
import axios from "axios";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import UserContext from "../../Components/context/UserContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { errorMsg, success } from "../../Components/ToasterFn";
import { MuiTelInput } from "mui-tel-input";
import CssAutocomplete from "../../Components/Reusables/CssAuocomplete";
import { getCartCount, getLocalUserData } from "../../Components/utils/Util";
import {
  cancellUserPlan,
  editParentDetails,
  getTagAndSubsDetail,
  getUserPetsInfo,
  getUsersTagLists,
  planRenewal,
} from "../../Components/utils/Helper";
import PopupState from "material-ui-popup-state";
import ChangePassword from "./ChangePassword";
import { fontFamily, fontWeight } from "@mui/system";
import CancelPlanModal from "./CancelPlanModal";
window.Buffer = window.Buffer || require("buffer").Buffer;

const UserAccount = ({ count, setCount }) => {
  const [isPlan, setIsPlan] = useState(false);

  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState([]);

  const uid = getLocalUserData().token;

  const getUsersData = async () => {
    const param = {
      token: uid,
    };
    var data = [];

    try {
      const res = await axios.post(getUserPetsInfo(), param);
      if (res.status == 200 && res.data) {
        data = res.data;
        console.log("user/data ", data);
        setUserInfo(data?.user);
      } else {
        data = [];
      }
    } catch (e) {
      console.log(e);
      data = [];
    }
  };
  const [tagDetail, setTagDetail] = useState([]);
  console.log("tags", tagDetail);
  const getTagsDetails = async () => {
    const param = {
      token: uid,
    };
    var data = [];

    try {
      const res = await axios.post(getTagAndSubsDetail(), param);
      if (res.status == 200 && res.data) {
        data = res.data;
        console.log("user---data ", data);
        setTagDetail(data?.tag_details);
      } else {
        data = [];
      }
    } catch (e) {
      console.log(e);
      data = [];
    }
  };

  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [userMobile, setUserMobile] = useState("");
  const [userAdd, setUserAdd] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userCountry, setUserCountry] = useState("");
  const [userCity, setUserCity] = useState("");
  const [userIdNum, setUserIdNum] = useState("");
  const [userEmerName, setUserEmerName] = useState("");
  const [userEmerName1, setUserEmerName1] = useState("");
  const [userEmerRel, setUserEmerRel] = useState("");
  const [userEmerCon, setUserEmerCon] = useState("");

  const [uploadedFile, setUploadedFile] = useState("");

  useEffect(() => {
    setUserId(userInfo?.id);
    setUserName(userInfo?.full_name);
    setUserMobile(userInfo?.mobile);
    setUserAdd(userInfo?.address);
    setUserEmail(userInfo?.email);
    setUserCountry(userInfo?.country);
    setUserCity(userInfo?.city);
    setUserIdNum(userInfo?.id_number);
    setUserEmerName(userInfo?.emergency_contact_f_name);
    setUserEmerName1(userInfo?.emergency_contact_l_name);
    setUserEmerCon(userInfo?.emergency_contact_number);
    setUserEmerRel(userInfo?.emergency_contact_relationship);
  }, [userInfo]);
  // useEffect(() => {
  //   setUserId(userInfo?.id);
  //   setUserName(userInfo?.full_name);
  //   setUserMobile(userInfo?.mobile);
  //   setUserAdd(userInfo?.address);
  //   setUserEmail(userInfo?.email);
  //   setUserCountry(userInfo?.country);
  //   setUserCity(userInfo?.city);
  //   setUserIdNum(userInfo?.id_number);
  //   setUserEmerName(userInfo?.emergency_contact_f_name);
  //   setUserEmerName1(userInfo?.emergency_contact_l_name);
  //   setUserEmerCon(userInfo?.emergency_contact_number)
  //   setUserEmerRel(userInfo?.emergency_contact_relationship);
  // }, []);
  const [isImage,setIsImage] = useState(false);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    
    if(file){
      setUploadedFile(file);
      setIsImage(true);
    }
    
   
  console.log("changedimg",isImage)

  };
  console.log("changed",isImage)
  console.log("userdetails", userName);

  const saveddata = async (anchor) => {
    const formData = new FormData();
    formData.append("token", uid);
    formData.append("full_name", userName);
    formData.append("mobile", userMobile);
    formData.append("address", userAdd);
    formData.append("email", userEmail);
    formData.append("country", userCountry);
    formData.append("city", userCity);
    formData.append("id_number", userIdNum);
    if(isImage) {
      formData.append("photo_id", uploadedFile);
    }
    formData.append("emergency_contact_f_name", userEmerName);
    formData.append("emergency_contact_l_name", userEmerName1);
    formData.append("emergency_contact_number", userEmerCon);
    formData.append("emergency_contact_relationship", userEmerRel);

    var data = [];

    try {
      const res = await axios.post(editParentDetails(userId), formData);
      if (res.status && res.data) {
        data = res.data;
        console.log("useredit--- ", data);
        if (data?.status) {
          success(data?.message);
          window.location.reload();
        } else {
          errorMsg(data?.message);
        }
      } else {
        data = [];
        console.log(res?.response?.data?.message);
        errorMsg(res?.data?.message);
      }
    } catch (e) {
      console.log(e);
      errorMsg(e?.response?.data?.message);

      data = [];
    }
  };
  const [myTag, setMyTag] = useState([]);
  const token = getLocalUserData()?.token;
  const myTagLists = async () => {
    const params = {
      token: token,
    };
    var data = [];

    try {
      const res = await axios.post(getUsersTagLists(), params);
      if (res.status == 200 && res.data) {
        data = res.data;
        console.log("useredit--- ", data);
        if (data?.status) {
          setMyTag(data?.data);
          // success(data?.message);
          // window.location.reload();
        } else {
          // errorMsg(data?.message);
        }
      } else {
        data = [];
        console.log(res?.response?.data?.message);
        // errorMsg(res?.data?.message);
      }
    } catch (e) {
      console.log(e);
      // errorMsg(e?.response?.data?.message);

      data = [];
    }
  };
  const [tagId, setTagID] = useState("");

  const cancellPlan = async () => {
    const token = getLocalUserData()?.token;
    const formdata = new FormData();
    formdata.append("code", tagId);
    formdata.append("token", token);

    var data = [];

    try {
      const res = await axios.post(cancellUserPlan(), formdata);
      if (res.status == 200 && res.data) {
        data = res.data;
        console.log("cancell--- ", data);
        if (data?.status) {
          myTagLists();
          success(data?.message);
          setOpenFounIt(false);
          console.log("plan cancelled");
        } else {
          // errorMsg(data?.message);
        }
      } else {
        data = [];
        console.log(res?.response?.data?.message);
        // errorMsg(res?.data?.message);
      }
    } catch (e) {
      console.log(e);
      // errorMsg(e?.response?.data?.message);

      data = [];
    }
  };

  const RenewMyPlan = async (id) => {
    const tokenParam = {
      token: token,
    };
    const formdata = new FormData();
    formdata.append("token", token);
    formdata.append("code", id);
    var data = [];

    try {
      const res = await axios.post(planRenewal(), formdata);
      if (res.status == 200 && res.data) {
        data = res.data;
        console.log("useredit--- ", data);
        if (data?.status) {
          navigate("/Usercart");
          getCartCount(tokenParam, count, setCount);
          // setMyTag(data?.data);
          // success(data?.message);
          // window.location.reload();
        } else {
          errorMsg(data?.message);
        }
      } else {
        data = [];
        console.log(res?.response?.data?.message);
        // errorMsg(res?.data?.message);
      }
    } catch (e) {
      console.log(e);
      // errorMsg(e?.response?.data?.message);

      data = [];
    }
  };
  const wasCalled = useRef(false);

  useEffect(() => {
    if (wasCalled.current) return;
    wasCalled.current = true;
    myTagLists();
    getUsersData();
    getTagsDetails();
  }, [uid]);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const [foundIt, setOpenFounIt] = useState(false);
  const foundPet = (id) => {
    setOpenFounIt(true);
    setTagID(id);
  };
  return (
    <div style={{ marginTop: "20px" }} className="mobileViewStyling">
      <Container>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6.5}
            style={{ overflow: "scroll" }}
          >
            <Grid item container>
              <Grid item container xs={12} sm={12} md={12}>
                <Box>
                  <p className={classes["payment-header"]}>Tags:</p>
                </Box>

              
                {myTag && myTag?.length > 0 ? (
                  <>
                    <Grid item container xs={12} sm={12} md={12} mt={3} mb={1}>
                      <Grid
                        container
                        item
                        xs={1}
                        sm={1}
                        md={1.2}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item sx={{ fontSize: "17px", fontWeight: "800" }}>
                          Type
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={1}
                        sm={1}
                        md={1.5}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item sx={{ fontSize: "17px", fontWeight: "800" }}>
                          Tag Id
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        item
                        xs={1}
                        sm={1}
                        md={0.7}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item sx={{ fontSize: "17px", fontWeight: "800" }}>
                          Size
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={3}
                        sm={3}
                        md={1.5}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item sx={{ fontSize: "17px", fontWeight: "800" }}>
                          Plan
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={3}
                        sm={3}
                        md={2}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item sx={{ fontSize: "17px", fontWeight: "800" }}>
                          Valid Upto
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={3}
                        sm={3}
                        md={1.5}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item sx={{ fontSize: "17px", fontWeight: "800" }}>
                          Status
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={4}
                        sm={4}
                        md={3}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item sx={{ fontSize: "17px", fontWeight: "800" }}>
                          Subscription
                        </Grid>
                      </Grid>
                    </Grid>
                    {myTag?.map((tag) => {
                      return (
                        <>
                          <Grid
                            item
                            container
                            xs={12}
                            sm={12}
                            md={12}
                            className="mt-3"
                          >
                            <Grid
                              container
                              item
                              xs={1}
                              sm={1}
                              md={1.2}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Grid item>
                                {tag?.tag_instance?.tag_type
                                  ? tag?.tag_instance?.tag_type?.slice(0, 4)
                                  : ""}
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              item
                              xs={1}
                              sm={1}
                              md={1.5}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Grid item>{tag?.tag_instance?.qr_code}</Grid>
                            </Grid>

                            <Grid
                              container
                              item
                              xs={1}
                              sm={1}
                              md={0.7}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Grid item>
                                {tag?.tag_instance?.size?.display_title}
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              item
                              xs={3}
                              sm={3}
                              md={1.5}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Grid item>
                                {tag?.subscription_plan_instance
                                  ?.subscription_plan?.plan_name
                                  ? tag?.subscription_plan_instance?.subscription_plan?.plan_name.slice(
                                      0,
                                      7
                                    )
                                  : ""}
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              item
                              xs={3}
                              sm={3}
                              md={2}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Grid item>
                                {tag?.tag_instance?.valid_til
                                  ? tag?.tag_instance?.valid_til?.slice(0, 10)
                                  : "--"}
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              item
                              xs={3}
                              sm={3}
                              md={1.5}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Grid item>
                                {tag?.subscription_plan_instance?.tag
                                  ?.has_subscription
                                  ? "Active"
                                  : "Inactive"}
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              item
                              xs={4}
                              sm={4}
                              md={3}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Grid item>
                                {" "}
                                <Box>
                                  {tag?.subscription_plan_instance?.tag
                                    ?.has_subscription ? (
                                    <CssButton
                                      callback={() =>
                                        // cancellPlan(tag?.tag_instance?.qr_code)
                                        foundPet(tag?.tag_instance?.qr_code)
                                      }
                                      text={`Cancel Subscription`}
                                      color="orange"
                                      fontSize="15px"
                                      lineHeight="14px"
                                    />
                                  ) : (
                                    <CssButton
                                      callback={() =>
                                        RenewMyPlan(tag?.tag_instance?.qr_code)
                                      }
                                      text={"Buy Subscription"}
                                      color="orange"
                                      fontSize="15px"
                                    />
                                  )}
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item COntainer xs={12} sm={12} md={12}>
                            <div className={classes.Horizontalspliter}></div>
                          </Grid>
                        </>
                      );
                    })}
                  </>
                ) : (
                  <div className={`${classes["payment-header"]} ms-3`}>
                    No Tags Found
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={0} sm={0} md={0} lg={0.5}>
            <div className={classes.spliter}></div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4.5}>
            <Grid item xs={12} sm={12} md={12}>
              <Box>
                <p className={classes["payment-header"]}>Account Settings</p>
              </Box>
            </Grid>
            <form onSubmit={(e) => e.preventDefault()}>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={12} sm={12} md={6}>
                  <p className="pet-info-title3">Parent Info</p>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <CssTextField
                    defaultV={userName}
                    callback={(e) => {
                      setUserName(e);
                    }}
                    field="Full Name"
                    width="100%"
                    height="10px"
                    placeholder="Enter Full Name"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <p className="pet-info-title3">Contact Number</p>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Box className="tel-box">
                    <MuiTelInput
                      onlyCountries={["AE", "CA", "GB", "KW"]}
                      size="small"
                      defaultCountry={
                        userCountry === "Canada"
                          ? "CA"
                          : userCountry === "United Kingdom"
                          ? "GB"
                          : userCountry === "Kuwait"
                          ? "KW"
                          : "AE"
                      }
                      sx={{
                        width: "100%",
                        "&.MuiFormControl-root": {
                          "& .MuiInputBase-root": {
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "1px solid #bfb7aa",
                            },
                          },
                        },
                      }}
                      placeholder="Enter your Number"
                      value={userMobile}
                      onChange={(data) => setUserMobile(data)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  {" "}
                  <p className="pet-info-title3">Address</p>{" "}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  {" "}
                  <CssTextField
                    defaultV={userAdd}
                    callback={(e) => {
                      setUserAdd(e);
                    }}
                    field="address"
                    width="100%"
                    height="10px"
                    placeholder="Enter your Address"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  {" "}
                  <p className="pet-info-title3">Email Address</p>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <CssTextField
                    defaultV={userEmail}
                    callback={(e) => {
                      setUserEmail(e);
                    }}
                    field="email"
                    width="100%"
                    height="10px"
                    placeholder="Enter your Email"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  {" "}
                  <p className="pet-info-title3">Country</p>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <CssAutocomplete
                    placeholder="Choose your Country"
                    value={userCountry}
                    callback={(e) => setUserCountry(e)}
                    options={[
                      "Canada",
                      "United Arab Emirates",
                      "United Kingdom",
                      "Kuwait",
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  {" "}
                  <p className="pet-info-title3">City</p>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <CssTextField
                    callback={(e) => {
                      setUserCity(e);
                    }}
                    defaultV={userCity}
                    field="city"
                    width="100%"
                    height="10px"
                    placeholder="Enter your City"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <p
                    className="pet-info-title3"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <span>ID Number</span>{" "}
                    <span style={{ marginTop: "-5px" }}>
                      {!!userCountry ? <MyPopup country={userCountry} /> : null}
                    </span>
                  </p>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <CssTextField
                    callback={(e) => {
                      setUserIdNum(e);
                    }}
                    defaultV={userIdNum}
                    field="idNumber"
                    width="100%"
                    height="10px"
                    placeholder="Enter your ID"
                    size="small"
                  />
                </Grid>
                {/* <Grid item xs={12} sm={12} md={6} > <p className="pet-info-title3" >Photo ID</p></Grid> */}
                {/* <Grid item xs={12} sm={12} md={6} ><CssUploadButton callback={(e) => setParentPhoto(e)} width="100%" height="100%" text={parentPhoto ? parentPhoto.name : "Upload Photo"} color="orange" fontSize="15px" icon="upload" ></CssUploadButton></Grid> */}
                <Grid item xs={12} sm={12} md={6}>
                  {" "}
                  <p className="pet-info-title2">Photo Id</p>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <label htmlFor="upload-photo">
                    <input
                      style={{ display: "none" }}
                      id="upload-photo"
                      name="upload-photo"
                      type="file"
                      // value={uploadedFile}
                      onChange={(e)=>handleImageChange(e)}
                    />

                    <Button
                      color="warning"
                      variant="contained"
                      component="span"
                      className={classes.photoIdBtn}
                    >
                      Upload Photo ID
                    </Button>
                  </label>
                </Grid>{" "}
                <Grid item xs={12} sm={12} md={6}>
                  {" "}
                  <p className="pet-info-title3">Emergency Contact FirstName</p>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <CssTextField
                    callback={(e) => setUserEmerName(e)}
                    defaultV={userEmerName}
                    field="name"
                    width="100%"
                    height="10px"
                    placeholder="Enter Emergency Contact Firstname"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  {" "}
                  <p className="pet-info-title3">Emergency Contact Lastname</p>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <CssTextField
                    callback={(e) => setUserEmerName1(e)}
                    defaultV={userEmerName1}
                    field="name"
                    width="100%"
                    height="10px"
                    placeholder="Enter Emergency Contact Lastname"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <p className="pet-info-title3">Emer. Contact Number</p>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Box className="tel-box">
                    <MuiTelInput
                      onlyCountries={["AE", "CA", "GB", "KW"]}
                      size="small"
                      defaultCountry={
                        userCountry === "Canada"
                          ? "CA"
                          : userCountry === "United Kingdom"
                          ? "GB"
                          : userCountry === "Kuwait"
                          ? "KW"
                          : "AE"
                      }
                      sx={{
                        width: "100%",
                        "&.MuiFormControl-root": {
                          "& .MuiInputBase-root": {
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "1px solid #bfb7aa",
                            },
                          },
                        },
                      }}
                      placeholder="Enter Emergency Contact Number"
                      value={userEmerCon}
                      onChange={(data) => setUserEmerCon(data)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  {" "}
                  <p className="pet-info-title3">
                    Emergency Contact Relationship{" "}
                  </p>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <CssTextField
                    callback={(e) => setUserEmerRel(e)}
                    defaultV={userEmerRel}
                    field="relation"
                    height="10px"
                    placeholder="Enter Emergency Contact Relationship"
                    size="small"
                  />
                </Grid>
              </Grid>

              <Grid
                item
                display="flex"
                justifyContent="flex-end"
                container
                xs={8.2}
                sm={7.5}
                md={8}
              >
                <Box className="save-pet-btn" sx={{ mt: 3 }}>
                  <CssButton
                    callback={(e) => {
                      e.preventDefault();
                      saveddata();
                    }}
                    width="160px"
                    height="35px"
                    text="Save"
                    color="orange"
                    fontSize="15px"
                  />
                </Box>
              </Grid>
            </form>

            <ChangePassword classes={classes} />
          </Grid>
        </Grid>
      </Container>
      {foundIt ? (
        <CancelPlanModal
          foundIt={foundIt}
          tagId={tagId}
          setOpenFounIt={setOpenFounIt}
          cancellPlan={cancellPlan}
          //   {...{ foundIt, setOpenFounIt }}
          //   callBack={() => updateMissing("found")}
        />
      ) : null}
    </div>
  );
};

export default UserAccount;

function MyPopup({ country }) {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <PopupState variant="popper">
      {(popupState) => (
        <div>
          <IconButton
            onMouseOver={(event) => {
              setIsOpen(true);
              setAnchorEl(event.currentTarget);
            }}
            onMouseLeave={() => setIsOpen(false)}
          >
            <InfoOutlinedIcon />
          </IconButton>
          <Popper
            open={isOpen}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transition
          >
            {({ TransitionProps }) => (
              <Grow {...TransitionProps} timeout={350}>
                <Paper>
                  <Typography sx={{ p: 2 }}>
                    {country === "Canada"
                      ? "Put your PR Card Number"
                      : country === "United Kingdom"
                      ? "Put your CitizenCard Number"
                      : country === "Kuwait"
                      ? "Put your CID Number"
                      : "Put your EID Card Number"}
                  </Typography>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      )}
    </PopupState>
  );
}

{
  /* <Grid item xs={12} sm={12} md={12}>
  <CssAutocomplete
    placeholder="Choose Plan"
    value={userPlan}
    callback={(e) => {
      setUserPlan(e);
      handleChangePlan(e);
      console.log("e==", e);
    }}
    options={["1 Month", "6 Months", "1 Year"]}
  />
</Grid>; */
}
