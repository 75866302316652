import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import ArrowLeftRoundedIcon from "@mui/icons-material/ArrowLeftRounded";
const TagsCounterBtn = ({ counter,setCounter }) => {
  // const [counter, setCounter] = useState(0);

  const counterminusCount = () => {
    counter <= 0 ? setCounter(0) : setCounter(counter - 1);
  };
  const counterPlusCount = () => {
    counter >= 10 ? setCounter(10) : setCounter(counter + 1);
  };

  // useEffect(() => {
  //   setCounterval(counter);
  // }, [counter]);

  return (
    <>
      <Grid item xs={6} sm={3} md={6}>
        <p className="tag-specs">Number of Tags:</p>
      </Grid>
      <Grid item xs={6} sm={9} md={6}>
        <Box
          color="#FD9B13"
          fontSize="15px"
          fontWeight="bolder"
          sx={{ marginLeft: "-10px", marginTop: "-5px" }}
        >
          <ArrowLeftRoundedIcon
            sx={{ cursor: "pointer" }}
            fontSize="large"
            onClick={() => counterminusCount()}
          />
          {counter}
          <ArrowRightRoundedIcon
            sx={{ cursor: "pointer" }}
            fontSize="large"
            onClick={() => counterPlusCount()}
          />
        </Box>
      </Grid>
    </>
  );
};

export default TagsCounterBtn;
