import { Box, Container, Grid, Typography } from "@mui/material";
import CssButton from "../../Components/Reusables/CssButton";
import headerTitle from "../../Components/assets/tranquility.png";
import Img1 from "../../Components/assets/1.png";
import Img2 from "../../Components/assets/2.png";
import Img3 from "../../Components/assets/3.png";
import Img4 from "../../Components/assets/4.png";
import Img5 from "../../Components/assets/5.png";
import Img6 from "../../Components/assets/6.png";
import Img7 from "../../Components/assets/7.png";
import Img8 from "../../Components/assets/8.png";
import Img13 from "../../Components/assets/13.png";
import Img14 from "../../Components/assets/14.png";
import styles from "./HomePage.module.css";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Plans from "../Plans/Plans";
import { getAllPlans } from "../../Components/utils/Helper";

const HomePage = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    localStorage.clear();
  }, []);
  const navigate = useNavigate();

  return (
    <Container fixed>
      <center>
        <Box sx={{ m: 5 }}>
          <Grid container rowSpacing={10}>
            
            <Grid container rowSpacing={{ xs: 1, sm: 1 }} item md={12}>
              <Grid
                order={{ xs: 2, sm: 2, md: 1 }}
                container
                item
                xs={12}
                sm={12}
                md={6}
                spacing={0}
              >
                <Grid
                  container
                  display="flex"
                  textAlign={{ xs: "center", sm: "center", md: "left" }}
                  justifyContent={{ xs: "center", sm: "center", md: "left" }}
                  columnSpacing={1}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <Box className={styles.contentBox}>
                    <img
                      style={{
                        textAlign: "center",
                        width: "70%",
                        marginLeft: "-15px",
                      }}
                      src={headerTitle}
                      className={styles.firstHeader}
                    ></img>
                    <p className={styles.content}>
                      <p>
                        Create a digital passport for
                        <br /> your beloved one(s).
                      </p>
                      <p>
                        Advanced ID tag allows you to
                        <br /> generate a digital ID for your
                        <br /> pet including all its details.
                      </p>
                    </p>
                  </Box>
                  <Grid item xs={12} sm={5} md={5}>
                    <CssButton
                      callback={() => navigate("/Tags")}
                      height="45px"
                      color="orange"
                      text="Order Your Tag"
                      fontSize="19px"
                      width="100%"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={5}>
                    <Box>
                      <button
                        onClick={() => navigate("/login")}
                        className={styles.subBtn}
                      >
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <span>Already Bought A Tag</span>
                          <span style={{ marginTop: "-5px" }}>
                            Subscribe Now
                          </span>
                        </Box>
                      </button>
                    </Box>
                  </Grid>
                 
                </Grid>
              </Grid>
              <Grid order={{ xs: 1, sm: 1 }} item xs={12} sm={12} md={6}>
                <Box sx={{ width: "80%", height: "90%" }}>
                  <img
                    src={Img1}
                    width="100%"
                    height="100%"
                    style={{ objectFit: "contain" }}
                  />
                </Box>
              </Grid>
            </Grid>
            
            <Grid container columnSpacing={15} item md={12}>
              <Grid item xs={12} sm={12} md={6}>
                <Box sx={{ width: "100%", height: "100%" }}>
                  <img
                    src={Img2}
                    width="100%"
                    height="100%"
                    style={{ objectFit: "contain" }}
                  />
                </Box>
              </Grid>
              <Grid
                display="flex"
                textAlign={{ xs: "center", sm: "center", md: "center" }}
                item
                xs={12}
                sm={12}
                md={6}
              >
                <Box
                  className={styles.secondCtBx}
                  sx={{ width: "100%", mt: { xs: "40px", sm: 0, md: 0 } }}
                >
                  <p style={{ textAlign: "center" }}>
                    Efficient Measures <br />
                    In Case Your Pet Gets Lost!
                    <br />{" "}
                  </p>
                  <p>
                    A pet tag is a quick, simple, and effective way for the
                    finder of a lost pet to contact its owner.
                  </p>
                </Box>
              </Grid>
            </Grid>
            
            <Grid item display="flex" justifyContent="center" md={12}>
              <Box sx={{ mb: -5 }} className={styles.thirdSecTitle}>
                <p>It’s Beyond A Stylish Tag</p>
              </Box>
            </Grid>
            
            <Grid container item rowSpacing={5}>
              <Grid
                item
                container
                columnSpacing={10}
                rowSpacing={5}
                display="flex"
                justifyContent="center"
                md={12}
              >
                <Grid
                  rowSpacing={3}
                  justifyContent="center"
                  container
                  item
                  xs={12}
                  sm={6}
                  md={4}
                >
                  <Grid item xs={12} sx={12} md={12}>
                    {" "}
                    <Box className={styles.fourthSecImg}>
                      {" "}
                      <img src={Img3} />{" "}
                    </Box>{" "}
                  </Grid>
                  <Grid item xs={12} sx={12} md={12}>
                    {" "}
                    <Box className={styles.fourthSecSubImg}>
                      {" "}
                      <img src={Img13} />{" "}
                    </Box>{" "}
                  </Grid>
                  <Grid item xs={12} sx={12} md={12}>
                    <Box className={styles.fourthSubHeader}>
                      <p>
                        All information
                        <br /> is in one place
                        <br /> & ready to go
                      </p>
                    </Box>
                  </Grid>
                </Grid>
                <Grid
                  rowSpacing={3}
                  justifyContent="center"
                  container
                  item
                  xs={12}
                  sm={6}
                  md={4}
                >
                  <Grid item xs={12} sx={12} md={12}>
                    {" "}
                    <Box className={styles.fourthSecImg}>
                      {" "}
                      <img src={Img4} />{" "}
                    </Box>{" "}
                  </Grid>
                  <Grid item xs={12} sx={12} md={12}>
                    {" "}
                    <Box className={styles.fourthSecSubImg}>
                      {" "}
                      <img src={Img14} />{" "}
                    </Box>{" "}
                  </Grid>
                  <Grid item xs={12} sx={12} md={12}>
                    <Box className={styles.fourthSubHeader}>
                      <p>
                        Powerful <br /> protection linked
                        <br /> to one single tag
                      </p>
                    </Box>
                  </Grid>
                </Grid>
                <Grid
                  rowSpacing={3}
                  justifyContent="center"
                  container
                  item
                  xs={12}
                  sm={6}
                  md={4}
                >
                  <Grid item xs={12} sx={12} md={12}>
                    {" "}
                    <Box className={styles.fourthSecImg}>
                      {" "}
                      <img src={Img5} />{" "}
                    </Box>{" "}
                  </Grid>
                  <Grid item xs={12} sx={12} md={12}>
                    {" "}
                    <Box className={styles.fourthSecSubImg}>
                      {" "}
                      <img src={Img13} />{" "}
                    </Box>{" "}
                  </Grid>
                  <Grid item xs={12} sx={12} md={12}>
                    <Box className={styles.fourthSubHeader}>
                      <p>
                        Critical
                        <br /> medical data <br /> are known to all
                      </p>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                item
                columnSpacing={5}
                display="flex"
                justifyContent="center"
                md={12}
              >
                <Grid item xs={12} sm={5} md={3}>
                  <CssButton
                    callback={() => navigate("/Tags")}
                    height="45px"
                    color="orange"
                    text="Order Your Tag"
                    fontSize="19px"
                    width="100%"
                  />
                </Grid>
                <Grid item xs={12} sm={5} md={3}>
                  <Box>
                    <button
                      onClick={() => navigate("/login")}
                      className={styles.subBtn}
                    >
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <span>Already Bought A Tag</span>
                        <span style={{ marginTop: "-5px" }}>Subscribe Now</span>
                      </Box>
                    </button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            
            <Grid
              container
              columnSpacing={3}
              rowSpacing={{ xs: "30px", sm: "30px", md: "" }}
              item
              md={12}
            >
              <Grid
                order={{ xs: 2, sm: 2, md: 1 }}
                display="flex"
                justifyContent="center"
                textAlign="center"
                alignItems="center"
                item
                md={6}
              >
                <Box className={styles.sixthCtBx}>
                  <p style={{ textAlign: "center" }}>A Digital Profile</p>
                  <p>
                    Using your phone, scanning a tag with a QR code redirects
                    you to the detailed pet’s profile.
                  </p>

                  <p>
                    This stores critical medical, dietary, and license
                    information.
                  </p>
                </Box>
              </Grid>
              <Grid order={{ xs: 1, sm: 1 }} item xs={12} sm={12} md={6}>
                <Box sx={{ width: "100%", height: "100%" }}>
                  <img
                    src={Img6}
                    width="80%"
                    height="100%"
                    style={{ objectFit: "contain" }}
                  />
                </Box>
              </Grid>
            </Grid>
            
            <Grid
              container
              columnSpacing={5}
              rowSpacing={{ xs: 5, sm: 5 }}
              item
              md={12}
            >
              <Grid item xs={12} sm={12} md={6}>
                <Box sx={{ width: "100%", height: "100%" }}>
                  <img
                    src={Img7}
                    width="100%"
                    height="100%"
                    style={{ objectFit: "contain" }}
                  />
                </Box>
              </Grid>
              <Grid container rowSpacing={2} item md={6}>
                <Grid
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  textAlign="center"
                  alignItems="center"
                  item
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <Box className={styles.seventhCtBx}>
                    <p>Choose The Tag</p>
                    <p>
                      This ID tag is made of strong and durable stainless steel
                      material.
                    </p>
                  </Box>
                </Grid>
                <Grid item justifyContent="center" xs={12} sm={12} md={12}>
                  <CssButton
                    callback={() => navigate("/Tags")}
                    height="45px"
                    width="180px"
                    color="orange"
                    text="Order Now"
                    fontSize="20px"
                  />
                </Grid>
              </Grid>
            </Grid>
            
            <Grid
              container
              columnSpacing={3}
              rowSpacing={{ xs: 5, sm: 5 }}
              item
              xs={12}
              md={12}
            >
              <Grid
                order={{ xs: 2, sm: 2, md: 1 }}
                display="flex"
                alignItems="center"
                justifyContent={{ sm: "center", xs: "center" }}
                container
                item
                xs={12}
                sm={12}
                md={6}
              >
                <Grid
                  textAlign={{ sm: "center", xs: "center", md: "center" }}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <Box className={styles.seventhCtBx}>
                    <p>Already Bought A Tag</p>
                    <p>
                      But your pet’s digital
                      <br /> Passport is not set yet?
                    </p>
                  </Box>
                  <Box sx={{ mt: 3 }}>
                    <CssButton
                      callback={() => navigate("/login")}
                      height="45px"
                      width="180px"
                      color="orange"
                      text="Subscribe Now"
                      fontSize="18px"
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid order={{ xs: 1, sm: 1 }} item xs={12} sm={12} md={6}>
                <Box sx={{ width: "100%", height: "100%" }}>
                  <img
                    src={Img8}
                    width="100%"
                    height="100%"
                    style={{ objectFit: "contain" }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </center>
      <Box mt={7}>
        <Plans />
      </Box>
    </Container>
  );
};

export default HomePage;
