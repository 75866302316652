import { Avatar, Grid } from '@mui/material';
import React, { useState } from 'react'

const TagColors = ({ item, setSize, infoMsg, val }) => {

  const [checkVal, setCheckVal] = useState("");
  console.log("tagColor", checkVal)
  return (
    <Grid item xs={4} sm={1.5} md={1} className="mt-2 mb-2">
      <Avatar
        onClick={() => {
          setSize(item?.id);
          setCheckVal(item?.title)
          infoMsg(`${item?.title} ${val?.pet_type} Tag Selected`);
        }}
        sx={{
          border: item?.title == checkVal ? '1px solid black' : 'none',
          cursor: 'pointer',
          bgcolor: "#FD9B13",
          width: "22px",
          height: "21px",
          fontSize: "15px",
          marginTop: "-5px",
        }}
      >
        {item?.display_title ? item?.display_title : ""}
      </Avatar>
    </Grid>
  )
}

export default TagColors