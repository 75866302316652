import React, { useState } from "react";
import { Box, Grid, IconButton } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CssTextField from "../../Components/Reusables/CssTextField";
import { errorMsg, success } from "../../Components/ToasterFn";
import { getLocalUserData } from "../../Components/utils/Util";
import axios from "axios";
import { linkNewTagToPet } from "../../Components/utils/Helper";
import CssAutocomplete from "../../Components/Reusables/CssAuocomplete";

const LinkedTag = ({
  pet_id,
  getUsersData,
  code,
  type,
  dogAllTags,
  catAllTags,
}) => {
  const [changeTag, setChangeTag] = useState(true);
  const [enteredTag, setEnteredTag] = useState("");
  const [tagToSend, setTagToSend] = useState("");

  const saveddata = async () => {
    const token = getLocalUserData()?.token;
    const formData = new FormData();
    formData.append("token", token);
    formData.append("pet_id", pet_id);
    formData.append("code", tagToSend);
    var data = [];

    try {
      const res = await axios.post(linkNewTagToPet(), formData);
      if (res.status && res.data) {
        data = res.data;
        console.log("add pets info ", data);
        if (data.status) {
          success(data?.message);
          getUsersData();
        } else {
          // errorMsg(data?.message);
        }
      } else {
        data = [];
        console.log(res?.response?.data?.message);
        errorMsg(res?.data?.message);
      }
    } catch (e) {
      console.log(e);
      errorMsg(e?.response?.data?.message);
      getUsersData();

      data = [];
    }
  };

  return (
    <>
      {changeTag ? (
        <>
          <Grid item xs={6} sm={6} md={6}>
            <p className="pet-info-3">
              {code ? code : ""} <CheckIcon sx={{ color: "#08c704" }} />{" "}
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            {" "}
            <Box
              onClick={() => {
                setChangeTag(false);
              }}
            >
              <p className="pet-info-3 change-tag-url ">Change the old Tag </p>
            </Box>
          </Grid>{" "}
        </>
      ) : (
        <Grid item xs={12} sm={6} md={6}>
          <Box
            sx={
              {
                // display: "flex",
                // flexDirection: "row",
              }
            }
          >
            {type === "Dog" ? (
              <CssAutocomplete
                placeholder="9876543"
                callback={(e) => {
                  setEnteredTag(e)
                  setTagToSend(Number(e.split('-')[0].trim()))
                }}
                options={dogAllTags?dogAllTags:[]}
                value={enteredTag}
                sx={{
                  width: "100%",
                }}
              // height="10px"
              />
            ) : (
              <CssAutocomplete
                placeholder="9876543"
                callback={(e) => {
                  setEnteredTag(e)
                  setTagToSend(Number(e.split('-')[0].trim()))
                }}
                options={catAllTags?catAllTags:[]}
                value={enteredTag}
                sx={{
                  width: "100%",
                }}
              // height="10px"
              />
            )}
            {/* <CssAutocomplete
              placeholder="9876543"
              callback={(e) => setEnteredTag(e)}
              options={[6458783, 7866756, 4567765, 6576878]}
              value={enteredTag}
              sx={{
                width: "100%",
              }}
              // height="10px"
            /> */}
            {/* <CssTextField
              type="number"
              callback={(e) => setEnteredTag(e)}
              width="100%"
              height="10px"
              placeholder="Enter tag number"
              size="small"
              defaultV={enteredTag}
            /> */}
            <IconButton
              onClick={() => {
                saveddata();
                setChangeTag(true);
              }}
              sx={{
                color: "black",
                backgroundColor: "orange",
                width: "30px",
                float: "right",
                height: "30px",
                marginTop: "5px",
                marginLeft: "3px",
              }}
            >
              <CheckIcon />
            </IconButton>
          </Box>
        </Grid>
      )}
    </>
  );
};

export default LinkedTag;
