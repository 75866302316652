import { useContext } from "react";
import AdminNavbar from "../AdminNavbar/AdminNavbar";
import UserContext from "../context/UserContext";
import Footer from "../Footer/Footer";
import FooterNavbar from "../FooterNavbar/FooterNavbar";
import Navbar from "../Navbar/Navbar";
import UserNavbar from "../UserNavbar.js/UserNavbar";
import { getLocalUserData } from "../utils/Util";

const Layout = ({ children,count,setCount }) => {
  const { userCtx, setUserCtx } = useContext(UserContext);
  const uid = getLocalUserData();
  console.log("udata", uid?.token);
  return (
    <>
      {uid.token ? <UserNavbar count={count} setCount={setCount}/> : <Navbar />}
      {children}
      {uid.token ? (
        <>
        <Footer />
        <FooterNavbar />
        </>
      ) : (
        <>
          <Footer />
          <FooterNavbar />
        </>
      )}
    </>
  );
};

export default Layout;
