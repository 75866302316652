import { Container, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Navbar from "../../Components/Navbar/Navbar";
import "../Login/Login.css";
import axios from "axios";
import UserContext from "../../Components/context/UserContext";
import { redirect, useNavigate } from "react-router-dom";
import { errorMsg, success } from "../../Components/ToasterFn";
import CssButton from "../../Components/Reusables/CssButton";
import { authUser } from "../../Components/utils/Helper";
import {
  createLocalStorage,
  getLocalUserData,
} from "../../Components/utils/Util";
import CssTextField from "../../Components/Reusables/CssTextField";
const PersonalInfoLogin = ({tagId}) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const { userCtx, setUserCtx } = useContext(UserContext);
  const uid = getLocalUserData();
  console.log("udata", uid?.token);
  const handleLogin = async () => {
    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    formData.append("tag_id", tagId);


    // Perform login API request
    try {
      const responce = axios.post(authUser(), formData).then((response) => {
        console.log("validate pass", response);
        if (response?.status === 200) {
          // setOtpData(false);
          if (response?.data?.status) {
            // clearGuestToken()
            createLocalStorage(response?.data);
            // success(response?.data?.message);
            setUsername("");
            setPassword("");
            console.log("login", response);
            navigate("/UserPets");
            window.location.reload();
          } else {
            errorMsg(response?.data?.message);
          }

          return;
        } else {
          // errorMsg(data?.message);
        }
      });
    } catch (error) {
      console.log(error);
      errorMsg("Failed To Login");
    }
  };
  return (
    <Box
      component="form"
      onSubmit={(e) => {
        e.preventDefault();
        handleLogin();
      }}
    >
      <Container sx={{ display: "flex", justifyContent: "center" }}>
        <Grid
          container
          item
          justifyContent="center"
          xs={11}
          sm={8}
          md={5}
          mt={10}
        >
          <Grid item xs={12} sm={12} md={12}>
            <Box className="login-form-title">
              <p className="login-title">Log in Now</p>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={12} className="mb-3">
            <CssTextField
            
              required="true"
              field="idNumber"
              height="10px"
              size="small"
              defaultV={tagId}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              className="lgn-field"
              required
              sx={{
                "& label.Mui-focused": {
                  color: "black",
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "orange",
                  },
                },
                width: "100%",
                height: " 61px",
                [`& fieldset`]: {
                  borderRadius: 48,
                },
              }}
              onChange={(e) => setUsername(e.target.value)}
              label="Enter Username"
              id="custom-css-outlined-input"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <FormControl sx={{ mt: 1, width: "100%" }} variant="outlined">
              <TextField
                className="lgn-field"
                required
                sx={{
                  "& label.Mui-focused": {
                    color: "#20130f",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "orange",
                    },
                  },

                  height: " 61px",
                  [`& fieldset`]: {
                    borderRadius: 48,
                  },
                }}
                type="password"
                label="Enter Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item mt={2} xs={12} sm={12} md={12}>
            <Button
              className="lgn-field"
              sx={{
                width: "100%",
                height: "51px",
                background: "#FD9B13",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: "50px",
                color: "black",
                backgroundColor: "orange",
                fontFamily: "MS Reference Sans Serif",
                fontWeight: "400",
                textTransform: "none",
                fontSize: "20px",
                fontWeight: "bold",
                "&:hover": {
                  backgroundColor: "orange",
                  color: "white",
                },
              }}
              type="submit"
            >
              Log In
            </Button>
          </Grid>
          <Grid
            display="flex"
            justifyContent="center"
            item
            mt={2}
            xs={12}
            sm={12}
            md={12}
          >
            <Typography
              onClick={() => navigate("/ForgetPassword")}
              sx={{
                color: "black",
                cursor: "pointer",
                "&:hover": { color: "orange", textDecoration: "underline" },
              }}
            >
              Forgot Password?
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default PersonalInfoLogin;
