import React from "react";

const Vaccination = () => {
  return (
    <div style={{ width: "100%", height: "300px" }}>
      <div className="d-flex justify-content-center">
        <iframe
          src="https://www.africau.edu/images/default/sample.pdf"
          frameborder="0"
          style={{ height: "60vh", width: "60vw" }}
        ></iframe>
      </div>
    </div>
  );
};

export default Vaccination;
