import { useState } from "react";
import Select from '@mui/material/Select';
import { MenuItem, } from "@mui/material";

const CssSelect = (props) => {
    const [value, setValue] = useState("none");
    const [showPlaceholder, setShowPlaceholder] = useState(value === "none");

    const { required, defaultV, callback, size, width, items, placeholder, field } = props
    return (
        <Select
            required={required ? required : ""}
            sx={{
                borderRadius: "23px",
                width: width,
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'orange',
                },

            }}
            id="form-select"
            size={size}
            value={!!defaultV ? defaultV : value}
            variant="outlined"
            defaultValue=""
            onFocus={(e) => setShowPlaceholder(false)}
            onClose={(e) => setShowPlaceholder(e.target.value === undefined)}
            onChange={(e) => { setValue(e.target.value); callback(e.target.value, field) }}
        >
            {!defaultV && <MenuItem sx={{ display: showPlaceholder ? "block" : "none", color: 'gray !important' }} key="0" value="none" ><span style={{ color: "#ccc7bf" }} >{placeholder}</span></MenuItem>}
            {items && items.map((item, key) => {
                return (
                    <MenuItem key={key} sx={{ "&:hover": { backgroundColor: "#FFEFD5", color: "#FD9B13" } }} value={item}>{item}</MenuItem>
                )
            })}
        </Select>
    );
}

export default CssSelect;