import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Box, Container, Divider, Grid } from "@mui/material";
import DogImg from "../../assets/dog-img.png";
import CssButton from "../../Reusables/CssButton";
import "./PetDetailsDialog.css";
import UserContext from "../../context/UserContext";
import axios from "axios";

export default function PetDetailsDialog({
  openDetails,
  setOpenDetails,
  petDetails,
}) {
  const { userCtx } = React.useContext(UserContext);

  const [open, setOpen] = React.useState(false);
  const [parentData, setParentData] = React.useState([]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClose = () => {
    setOpenDetails(false);
  };
  const getPetParent = async () => {
    await axios
      .get(`${userCtx.mainUrl}/auth/userProfile/${petDetails.userId}`)
      .then((res) => {
        setParentData(res.data.user);
        setOpenDetails(true);
      })
      .catch((err) => {
        // err;
      });
  };

  React.useEffect(() => {
    if (petDetails) {
      getPetParent();
    }
  }, [petDetails]);
  console.log(parentData);
  return (
    <Dialog
      fullScreen={fullScreen}
      open={openDetails}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      maxWidth="lg"
    >
      <DialogContent>
        <Box sx={{ overflow: "hidden", padding: "10px" }}>
          <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid
              item
              display="flex"
              justifyContent="center"
              container
              xs={12}
              sm={12}
              md={3}
            >
              <Grid
                sm={4}
                xs={10}
                md={12}
                item
                container
                className="photo-box"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  order={{ xs: 2, sm: 2, md: 1 }}
                  className="photo-card"
                >
                  <img
                    className="pet-img"
                    width="100%"
                    height="100%"
                    src={petDetails?.pet?.pet_pic}
                  />
                </Grid>
                <Grid
                  item
                  marginBottom={{ xs: "20px", md: "0px", sm: "20px" }}
                  sm={12}
                  xs={12}
                  md={12}
                  order={{ xs: 1, sm: 1, md: 2 }}
                  onClick={handleClose}
                  className="report-btn"
                  sx={{ mt: 2 }}
                >
                  <CssButton
                    width="100%"
                    height="100%"
                    text="Back"
                    textColor="black"
                    color="#FD9B13"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={9}>
              <Grid container item xs={12} sm={12} md={12}>
                <Grid item xs={12} sm={12} md={12}>
                  <Box className="pet-detail-header-box">
                    <p className="pet-detail-header">Pet Info</p>
                  </Box>
                </Grid>
              </Grid>
              <Grid
                columns={24}
                xs={24}
                sm={24}
                md={24}
                item
                container
                rowSpacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={24} sm={8} md={4.8}>
                  <Grid item xs={24} sm={24} md={24}>
                    {" "}
                    <p className="pet-details-title">Name of Pet</p>{" "}
                  </Grid>
                  <Grid item xs={24} sm={24} md={24}>
                    {" "}
                    <p className="pet-title-content">
                      {" "}
                      {petDetails?.pet?.name}{" "}
                    </p>{" "}
                  </Grid>
                </Grid>
                <Grid item xs={24} sm={8} md={4.8}>
                  <Grid item xs={24} sm={24} md={24}>
                    {" "}
                    <p className="pet-details-title">Pet Gender</p>{" "}
                  </Grid>
                  <Grid item xs={24} sm={24} md={24}>
                    {" "}
                    <p className="pet-title-content">
                      {petDetails?.pet?.gender}
                    </p>{" "}
                  </Grid>
                </Grid>
                <Grid item xs={24} sm={8} md={4.8}>
                  <Grid item xs={24} sm={24} md={24}>
                    <p className="pet-details-title">Breed</p>{" "}
                  </Grid>
                  <Grid item xs={24} sm={24} md={24}>
                    <p className="pet-title-content">
                      {petDetails?.pet?.breed}
                    </p>{" "}
                  </Grid>
                </Grid>
                <Grid item xs={24} sm={8} md={4.8}>
                  <Grid item xs={24} sm={24} md={24}>
                    <p className="pet-details-title">Pet Vaccination</p>{" "}
                  </Grid>
                  <Grid item xs={24} sm={24} md={24}>
                    {" "}
                    <p className="pet-title-content">
                      {petDetails?.pet?.vaccination === true
                        ? "Vaccinated"
                        : "Not Vaccinated"}
                    </p>{" "}
                  </Grid>
                </Grid>
                <Grid item xs={24} sm={8} md={4.8}>
                  <Grid item xs={24} sm={24} md={24}>
                    {" "}
                    <p className="pet-details-title">Comments</p>{" "}
                  </Grid>
                  <Grid item xs={24} sm={24} md={24}>
                    <p className="pet-title-content">
                      {petDetails?.pet?.description}
                    </p>{" "}
                  </Grid>
                </Grid>
              </Grid>
              <Divider
                className="section-divider"
                sx={{
                  bgcolor: "#fec06c",
                  height: "2px",
                  textAlign: "center",
                  width: "100%",
                }}
              />

              <Grid xs={12} sm={12} md={12} rowSpacing={2} item container>
                <Grid xs={12} sm={6} md={1} item>
                  <Grid item xs={12} sm={12} md={12}>
                    {" "}
                    <p className="pet-details-title">Allergy</p>{" "}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    {" "}
                    <p className="pet-title-content">
                      {petDetails?.is_allergy === true ? "True" : "False"}
                    </p>{" "}
                  </Grid>
                </Grid>
                <Grid xs={12} sm={6} md={2} item>
                  <Grid item xs={12} sm={12} md={12}>
                    {" "}
                    <p className="pet-details-title">Color</p>{" "}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    {" "}
                    <p className="pet-title-content">
                      {petDetails?.pet?.color}
                    </p>{" "}
                  </Grid>
                </Grid>
                <Grid xs={12} sm={6} md={2.5} item>
                  <Grid item xs={12} sm={12} md={12}>
                    {" "}
                    <p className="pet-details-title">last seen location</p>{" "}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    {" "}
                    <p className="pet-title-content">
                      {petDetails?.last_seen_location}
                    </p>{" "}
                  </Grid>
                </Grid>
                <Grid xs={12} sm={6} md={2.5} item>
                  <Grid item xs={12} sm={12} md={12}>
                    {" "}
                    <p className="pet-details-title">Pet Parent Name</p>{" "}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    {" "}
                    <p className="pet-title-content">{petDetails?.user}</p>{" "}
                  </Grid>
                </Grid>
                <Grid xs={12} sm={6} md={2} item>
                  <Grid item xs={12} sm={12} md={12}>
                    <p className="pet-details-title">Contact Number</p>{" "}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <p className="pet-title-content">{petDetails?.mobile}</p>{" "}
                  </Grid>
                </Grid>
                <Grid xs={12} sm={6} md={2} item>
                  <Grid item xs={12} sm={12} md={12}>
                    <p className="pet-details-title">
                      Emergency Contact Number
                    </p>{" "}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <p className="pet-title-content pet-ctnt-mail">
                      {petDetails?.emergency_contact
                        ? petDetails?.emergency_contact
                        : "-"}{" "}
                    </p>{" "}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
