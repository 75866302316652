import { Container, Grid, Box, MenuItem, IconButton } from "@mui/material";
import "./UserPets.css";
import CssButton from "../../Components/Reusables/CssButton";
import CssTextField from "../../Components/Reusables/CssTextField";
import { useContext, useEffect, useRef, useState } from "react";
import CatImg from "../../Components/assets/cat-img.png";
import DogImg from "../../Components/assets/dog-img.png";
import CheckIcon from "@mui/icons-material/Check";
import Footer from "../../Components/Footer/Footer";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import UserContext from "../../Components/context/UserContext";
import ReportPetDialog from "../../Components/Dialogs/ReportPetDialog";
import FoundPetDialog from "../../Components/Dialogs/FoundPetDialog";
import { errorMsg, infoMsg, success } from "../../Components/ToasterFn";
import EditUserInfo from "./EditUserInfo";
import EditPetInfo from "./EditPetInfo";
import { getUserPetsInfo } from "../../Components/utils/Helper";
import { getLocalUserData, PetsQr } from "../../Components/utils/Util";
import LinkedTag from "./LinkedTag";
import LinkNewTag from "./LinkNewTag";

const UserPets = () => {
  const uid = getLocalUserData().token;
  console.log("token", uid);
  const navigate = useNavigate();
  const [userPets, setUserPets] = useState([]);
  const [userInfo, setUserInfo] = useState([]);
  const [foundIt, setOpenFounIt] = useState(false);
  const [changeTag, setChangeTag] = useState(true);
  const [openReportPet, setOpenReportPet] = useState(false);
  const [petId, setPetID] = useState("");

  // console.log("udata", uid?.token);
  const getUsersData = async () => {
    const param = {
      token: uid,
    };
    var data = [];

    try {
      const res = await axios.post(getUserPetsInfo(), param);
      if (res.status == 200 && res.data) {
        data = res.data;
        console.log("userpets ", data);
        setUserPets(data?.pets);
        setUserInfo(data?.user);
      } else {
        data = [];
      }
    } catch (e) {
      console.log(e);
      data = [];
    }
  };
  const foundPet = (id) => {
    setOpenFounIt(true);
    setPetID(id);
  };
  const reportMissed = (id) => {
    setOpenReportPet(true);
    setPetID(id);
  };
  const [dogAllTags, setDogAllTags] = useState([]);
  const [catAllTags, setCatAllTags] = useState([]);
  const wasCalled = useRef(false);

  useEffect(() => {
    if(wasCalled.current) return;
    wasCalled.current = true;
    getUsersData();
    PetsQr(setDogAllTags, setCatAllTags);
  }, [uid]);
  const location = useLocation();
  const locData = location.pathname.split("/");
  console.log("xyz", locData[1]);
  return (
    <>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Box className="add-pet-main-box">
          {userPets && userPets?.length > 0 ? (
            <>
              {userPets.map((val, ind) => {
                return (
                  <Grid
                    container
                    rowSpacing={7}
                    sx={ind === 0 ? "" : { marginTop: "2rem" }}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    {ind === 0 ? (
                      <Grid item xs={12} sm={12} md={12}>
                        <Box className="parent-info-title">Pet's Info</Box>
                        <Box className="pet-info-title-btn" mb={4}>
                          <CssButton
                            callback={() => navigate("/AddPet")}
                            icon="add"
                            height="27px"
                            text="Add New Pet"
                            color="orange"
                            fontSize="15px"
                          />
                        </Box>
                      </Grid>
                    ) : (
                      ""
                    )}

                    <Grid container rowGap={10}>
                      <Grid container item rowGap={5} columnSpacing={3}>
                        <Grid item xs={12} sm={12} md={3}>
                          <Box className="photo-box">
                            <Box
                              className="photo-card"
                              sx={{
                                border: val?.is_missing
                                  ? "3px solid red"
                                  : null,
                              }}
                            >
                              <img
                                className="pet-img"
                                width="100%"
                                height="100%"
                                src={val?.pet_pic}
                                alt="missing imag"
                              />
                            </Box>

                            {val?.is_missing ? (
                              <Box
                                sx={{
                                  mt: 2,
                                  textAlign: "center",
                                  color: "red",
                                  fontSize: "19px",
                                  fontWeight: "bolder",
                                }}
                              >
                                <p>Missing !</p>
                              </Box>
                            ) : null}

                            <Box className="report-btn" sx={{ mt: 2 }}>
                              <CssButton
                                fontSize="17px"
                                callback={() => {
                                  val?.is_missing
                                    ? foundPet(val?.id)
                                    : reportMissed(val?.id);
                                  //   setId(item._id);
                                }}
                                width="100%"
                                height="100%"
                                text={
                                  val?.is_missing
                                    ? "FOUND IT"
                                    : "Report Missing Pet"
                                }
                                textColor={
                                  val?.is_missing ? "black" : "#FF0000"
                                }
                                color={val?.is_missing ? "orange" : "#ffc3c3"}
                              />
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item md={9}>
                          <Box
                            className="pet-info-card"
                            sx={{
                              backgroundColor: val?.is_missing
                                ? "#ffcbbc"
                                : null,
                            }}
                          >
                            <Box sx={{ p: 1.5 }}>
                              <Grid
                                container
                                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                              >
                                <Grid item xs={12} sm={12} md={12}>
                                  {/* <Box className="edit-info-btn-box">
                                <button className="edit-info-btn">Edit Info</button>
                              </Box> */}
                                  <EditPetInfo data={val} />
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                  <p className="pet-info-2">Pet Name</p>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                  <p className="pet-info-3">{val?.name}</p>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                  <p className="pet-info-2">Pet Species</p>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                  <p className="pet-info-3">{val?.type}</p>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                  <p className="pet-info-2">Pet Gender</p>{" "}
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                  <p className="pet-info-3">{val?.gender}</p>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                  <p className="pet-info-2">Pet Age</p>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                  <p className="pet-info-3">{val?.age}</p>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                  <p className="pet-info-2">Breed</p>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                  <p className="pet-info-3">{val?.breed}</p>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                  <p className="pet-info-2">Pet Shade</p>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                  <p className="pet-info-3">{val?.color}</p>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                  <p className="pet-info-2">Allergies</p>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                  <p className="pet-info-3">
                                    {val?.is_allergy_affected === true
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                  <p className="pet-info-2">Pet Status</p>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                  <p className="pet-info-3">
                                    {val?.health_condition}
                                  </p>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                  <p className="pet-info-2">Vaccination</p>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                  <p className="pet-info-3 ">
                                    {val?.vaccination === true ? (
                                      <a
                                        className="report-url"
                                        href={
                                          val?.vacinated_file
                                            ? val?.vacinated_file
                                            : ""
                                        }
                                        target="_blank"
                                      >
                                        Vaccinated
                                      </a>
                                    ) : (
                                      "Not Vaccinated"
                                    )}
                                  </p>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                  <p className="pet-info-2">Vet’s Name</p>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                  <p className="pet-info-3">{val?.vet_name}</p>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                  <p className="pet-info-2">Comments</p>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                  <p className="pet-info-3">
                                    {" "}
                                    {val?.description}
                                  </p>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                  <p className="pet-info-2">Link the tag</p>
                                </Grid>

                                {/* <Grid item xs={12} sm={12} md={12}>
                                  {" "}
                                  <Box>
                                    <p className="pet-info-3 change-tag-url ">
                                      Change the old Tag{" "}
                                    </p>
                                  </Box>
                                </Grid> */}

                                <LinkedTag
                                  pet_id={val?.id}
                                  getUsersData={getUsersData}
                                  code={val?.code}
                                  type={val?.type}
                                  dogAllTags={dogAllTags}
                                  catAllTags={catAllTags}
                                />
                              </Grid>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </>
          ) : (
            <Grid
              container
              rowSpacing={7}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} sm={12} md={12}>
                <Box className="parent-info-title">Pet's Info</Box>
                <Box className="pet-info-title-btn" mb={4}>
                  <CssButton
                    callback={() => navigate("/AddPet")}
                    icon="add"
                    height="27px"
                    text="Add New Pet"
                    color="orange"
                    fontSize="15px"
                  />
                </Box>
              </Grid>
            </Grid>
          )}
          {userInfo ? (
            <Box>
              <Grid
                container
                rowSpacing={7}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={12} sm={12} md={12} mt={3}>
                  <Box className="parent-info-title">Parent’s Info</Box>
                </Grid>
                <Grid container item columnSpacing={3}>
                  <Grid item xs={0} sm={0} md={3}></Grid>
                  <Grid item xs={12} sm={12} md={9}>
                    <Box className="user-parent-info-card">
                      <Box sx={{ p: 1.5 }}>
                        <Grid container columnSpacing={{ xs: 1, sm: 0, md: 3 }}>
                          <Grid item xs={12} sm={12} md={12}>
                            {/* <Box className="edit-info-btn-box">
                    <button className="edit-info-btn">Edit Info</button>
                  </Box> */}
                            <EditUserInfo data={userInfo} />
                          </Grid>
                          <Grid item xs={6} sm={6} md={6}>
                            <p className="pet-info-2">Parent Name</p>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6}>
                            <p className="pet-info-3">{userInfo?.full_name}</p>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6}>
                            <p className="pet-info-2">Contact Number</p>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6}>
                            <p className="pet-info-3">{userInfo?.mobile}</p>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6}>
                            <p className="pet-info-2">Address</p>{" "}
                          </Grid>
                          <Grid item xs={6} sm={6} md={6}>
                            <p className="pet-info-3">{userInfo?.address}</p>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6}>
                            <p className="pet-info-2">Email Address</p>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sm={6}
                            md={6}
                            style={{ wordBreak: "break-word" }}
                          >
                            <p className="pet-info-3">{userInfo?.email}</p>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sm={6}
                            md={6}
                            sx={{
                              mt: {
                                xs: 3,
                                sm: 0,
                                lg: 0,
                                md: 0,
                                xl: 0,
                                xxl: 0,
                              },
                            }}
                          >
                            <p className="pet-info-2">Country</p>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sm={6}
                            md={6}
                            style={{ wordBreak: "break-word" }}
                            sx={{
                              mt: {
                                xs: 3,
                                sm: 0,
                                lg: 0,
                                md: 0,
                                xl: 0,
                                xxl: 0,
                              },
                            }}
                          >
                            <p className="pet-info-3">{userInfo?.country}</p>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sm={6}
                            md={6}
                            sx={{
                              mt: {
                                xs: 3,
                                sm: 0,
                                lg: 0,
                                md: 0,
                                xl: 0,
                                xxl: 0,
                              },
                            }}
                          >
                            <p
                              className="pet-info-2"
                              sx={{
                                mt: {
                                  xs: 3,
                                  sm: 0,
                                  lg: 0,
                                  md: 0,
                                  xl: 0,
                                  xxl: 0,
                                },
                              }}
                            >
                              City
                            </p>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sm={6}
                            md={6}
                            style={{ wordBreak: "break-word" }}
                            sx={{
                              mt: {
                                xs: 3,
                                sm: 0,
                                lg: 0,
                                md: 0,
                                xl: 0,
                                xxl: 0,
                              },
                            }}
                          >
                            <p className="pet-info-3">{userInfo?.city}</p>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6}>
                            <p className="pet-info-2">ID Number</p>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6}>
                            <p className="pet-info-3">{userInfo?.id_number}</p>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6}>
                            <p className="pet-info-2">Photo ID </p>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6}>
                            <p className="pet-info-3 report-url ">
                              <a
                                className="report-url"
                                href={
                                  userInfo?.photo_id ? userInfo?.photo_id : ""
                                }
                                target="_blank"
                              >
                                Image
                              </a>
                            </p>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6}>
                            <p className="pet-info-2">Emergency Contact Firstname</p>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sm={6}
                            md={6}
                            style={{ wordBreak: "break-word" }}
                          >
                            <p className="pet-info-3">
                              {userInfo?.emergency_contact_f_name}
                            </p>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6}>
                            <p className="pet-info-2">Emergency Contact Lastname</p>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sm={6}
                            md={6}
                            style={{ wordBreak: "break-word" }}
                          >
                            <p className="pet-info-3">
                              {userInfo?.emergency_contact_l_name}
                            </p>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6}>
                            <p className="pet-info-2">Emergency Contact No.</p>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sm={6}
                            md={6}
                            style={{ wordBreak: "break-word" }}
                          >
                            <p className="pet-info-3">
                              {userInfo?.emergency_contact_number?userInfo?.emergency_contact_number:""}
                            </p>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sm={6}
                            md={6}
                            sx={{
                              mt: {
                                xs: 3,
                                sm: 0,
                                lg: 0,
                                md: 0,
                                xl: 0,
                                xxl: 0,
                              },
                            }}
                          >
                            <p className="pet-info-2">
                              Emergency Contact Relationship
                            </p>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sm={6}
                            md={6}
                            sx={{
                              mt: {
                                xs: 3,
                                sm: 0,
                                lg: 0,
                                md: 0,
                                xl: 0,
                                xxl: 0,
                              },
                            }}
                          >
                            <p className="pet-info-3">
                              {userInfo?.emergency_contact_relationship}{" "}
                            </p>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          ) : (
            ""
          )}
        </Box>
      </Box>
      {/* <Box></Box>

      {openReportPet ? (
        <ReportPetDialog
          {...{ openReportPet, setOpenReportPet, setLastSeen }}
          callBack={() => updateMissing("missing")}
        />
      ) : null}*/}
      {openReportPet ? (
        <ReportPetDialog
          pid={petId}
          openReportPet={openReportPet}
          setOpenReportPet={setOpenReportPet}
        />
      ) : null}
      {foundIt ? (
        <FoundPetDialog
          foundIt={foundIt}
          pid={petId}
          setOpenFounIt={setOpenFounIt}
          //   {...{ foundIt, setOpenFounIt }}
          //   callBack={() => updateMissing("found")}
        />
      ) : null}
    </>
  );
};

export default UserPets;
