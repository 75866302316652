export const generate_url = (data) => {
  // console.log("env",process.env.REACT_APP_API_URL);
  var base_url = process.env.REACT_APP_API_URL;
  return base_url + data;
};

export const getAllPlans = () => {
  return generate_url("subcription-plan-list");
};
export const authUser = () => {
  return generate_url("login/");
};
export const getTagLists = () => {
  return generate_url("tag_list/");
};
export const getMissingPetsDetails = () => {
  return generate_url("missing_pet_list/");
};
export const getUserPetsInfo = () => {
  return generate_url("user_pet_info/");
};

export const getPetById = () => {
  return generate_url("search_qrcode/");
};

export const addUserPetDetail = () => {
  return generate_url("add_pet/");
};

export const editPetDetails = (slug) => {
  return generate_url(`edit_pet/${slug}/`);
};
export const editParentDetails = (slug) => {
  return generate_url(`edit_parent_info/${slug}/`);
};
export const reportMissed = () => {
  return generate_url(`add_missing_pet_status/`);
};

export const missedPetFound = () => {
  return generate_url(`remove_missing_pet_status/`);
};

export const addPersonalInfo = () => {
  return generate_url(`personal_info/`);
};

export const addTagsToCart = () => {
  return generate_url(`add_to_cart/`);
};

export const getCartList = () => {
  return generate_url(`cart_list/`);
};

export const getTagAndSubsDetail = () => {
  return generate_url(`user_tag_details/`);
};
export const changePassword = () => {
  return generate_url(`change_password/`);
};
export const changePlanOnChange = () => {
  return generate_url(`add_subscription_to_cart/`);
};
export const changeCountryCurrency = () => {
  return generate_url(`price_change_acoording_to_country/`);
};
export const cartCount = () => {
  return generate_url(`cart_count/`);
};
export const deleteItemsFromCart = () => {
  return generate_url(`delete_item_from_cart/`);
};
export const forgotPassword = () => {
  return generate_url(`forgot_password/`);
};
export const resetNewPassword = () => {
  return generate_url(`ResetPassword/`);
};
export const linkNewTagToPet = () => {
  return generate_url(`add_code_to_pet/`);
};
export const petDetailByScan = (slug) => {
  return generate_url(`tag_details/${slug}/`);
};
export const getUsersTagLists = () => {
  return generate_url(`tag_list_for_user/`);
};
export const cancellUserPlan = () => {
  return generate_url(`cancel_subscription/`);
};

export const planRenewal = () => {
  return generate_url(`renewal_plan/`);
};

export const enablepaymentbtn = () => {
  return generate_url(`payment-intend/`);
};

export const isPaymentCompleted = () => {
  return generate_url(`stripe-complete/`);
};
export const UserOrderDetails = () => {
  return generate_url(`order-list/`);
};
export const ParticularOrderDetail = () => {
  return generate_url(`order-detail/`);
};
export const isMissedpetFound = () => {
  return generate_url(`pet_found/`);
};

export const myAllPetsQr = () => {
  return generate_url(`tags_by_pet_type/`);
};

export const Sendpetpic = (slug) => {
  return generate_url(`pet_pic/${slug}/`);
};
export const allBreeds = () => {
  return generate_url(`get_breed/`);
};

export const editAllUploadedPetFiles = () => {
  return generate_url(`edit_pet_files/`)
}

export const HomePageApi = () => {
  return generate_url(`home_content/`)
}

export const FooterContents = () => {
  return generate_url(`get_footer_links/`)
}
