import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const CancelPlanModal = ({ cancellPlan, foundIt, setOpenFounIt }) => {
  const handleClose = () => {
    setOpenFounIt(false);
  };
  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        {/* <DialogTitle id="responsive-dialog-title">

              </DialogTitle> */}
        <DialogContent>
          <DialogContentText>
            Do You Really Wish To Cancel This Subscription{" "}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            sx={{
              "&:hover": {
                border: "1px solid #fd9b13",
                bgcolor: "white",
              },
              color: "black",
              bgcolor: "#fff",
              borderRadius: "30px",
              border: "1px solid orange",
            }}
            variant="outlined"
          >
            Discard
          </Button>
          <Button
            sx={{
              "&:hover": {
                color: "black",
                bgcolor: "#fd9b13",
              },
              bgcolor: "#fd9b13",
              borderRadius: "30px",
            }}
            variant="contained"
            onClick={cancellPlan}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CancelPlanModal;
