import { useState } from "react";
import { createRoot } from "react-dom/client";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import { Box } from "@mui/material";
import CssButton from "../../Components/Reusables/CssButton";

const getSrcFromFile = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.originFileObj);
    reader.onload = () => resolve(reader.result);
  });
};
const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

const AntdImg = ({ imageBinary, setImageBinary }) => {
  const [fileList, setFileList] = useState([]);

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);

    // Check if a new file has been selected
    if (newFileList.length > 0) {
      // Read the selected file and store it in binary format
      const reader = new FileReader();
      reader.readAsDataURL(newFileList[0].originFileObj);
      reader.onload = () => {
        setImageBinary(reader.result);
        const base64Data = imageBinary.replace(/^data:image\/\w+;base64,/, "");
        const binaryData = atob(base64Data);

        // Now, `binaryData` contains the binary representation of the image
        console.log("bind", binaryData);
      };
    } else {
      // Reset the imageBinary state if no file is selected
      setImageBinary(null);
    }
  };
  const onPreview = async (file) => {
    const src = file.url || (await getSrcFromFile(file));
    const imgWindow = window.open(src);

    if (imgWindow) {
      const image = new Image();
      image.src = src;
      imgWindow.document.write(image.outerHTML);
    } else {
      window.location.href = src;
    }
  };

  return (
    <>
      <Box className="upload-photo-card">
        <ImgCrop showGrid rotationSlider aspectSlider showReset>
          <Upload
                    customRequest={dummyRequest}
            listType="picture-card"
            fileList={fileList}
            onChange={onChange}
            onPreview={onPreview}
          >
            {fileList.length < 1 && "+ Upload Pet Pic"}
          </Upload>
        </ImgCrop>
      </Box>

      <div className="d-flex justify-content-center mt-3">
        {/* <span onClick={()=>setFileList([])}>del</span> */}
        {fileList && fileList.length > 0 ? (
          <CssButton
            btnType="button"
            callback={() => setFileList([])}
            height="25px"
            color="orange"
            text={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="2"
                height="1.3em"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                />
              </svg>
            }
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};
export default AntdImg;
