import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Container,
  Grid,
  Button,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import classes from "./Cart.module.css";
import Visa from "../../Components/assets/visa.jpg";
import Mc from "../../Components/assets/mc.jpg";
import Ppl from "../../Components/assets/ppl.jpg";
import firstproduct from "../../Components/assets/dog-logo.png";
import secondproduct from "../../Components/assets/cat-logo.png";
// import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import UserContext from "../../Components/context/UserContext";
import axios from "axios";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { errorMsg, success } from "../../Components/ToasterFn";
import S3 from "react-aws-s3";
import CssAutocomplete from "../../Components/Reusables/CssAuocomplete";
import TextField from "@mui/material/TextField";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import cart from "../Cart/cart.css";
import visaCreditCard from "../../Components/assets/visa-credit-card.svg";
import masterCard from "../../Components/assets/mastercard.svg";
import {
  getCartList,
  changePlanOnChange,
  changeCountryCurrency,
  enablepaymentbtn,
} from "../../Components/utils/Helper";
import {
  getGuestUserCart,
  getLocalUserData,
} from "../../Components/utils/Util";
import SubsCribePlansOpt from "./SubsCribePlansOpt";
import CartCard from "./CartCard";
import "../Cart/cart.css";
import Dummy from "./DummyPayment";
import emptycart from "../../assests/emptycart.png";

const Cart = ({ count, setCount }) => {
  const params = useLocation();
  console.log("params", params);
  const navigate = useNavigate();
  const d1 = new Date();
  const d2 = new Date();
  const d3 = new Date();
  const date = d1.setMonth(d1.getMonth() + 1);
  const date2 = d2.setMonth(d2.getMonth() + 6);
  const date3 = d3.setMonth(d3.getMonth() + 12);
  const [save, setSave] = useState(0);
  const [totlAmount, setTotlAmount] = useState("");
  const { userCtx } = useContext(UserContext);
  // const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
  const [dogTags, setDogTags] = useState(0);
  const [catTags, setCatTags] = useState(0);
  const [plan, setPlan] = useState("");
  const totalPrice = "1200";
  const [value, setValue] = React.useState("female");
  const [userCountry, setUserCountry] = useState("");

  const totalSelectedPlans =
    parseInt(dogTags != 0 ? dogTags?.number : 0) +
    parseInt(catTags != 0 ? catTags?.number : 0);
  const [total, setTotal] = useState("00.00");
  const [totalIncTax, setTotalIncTax] = useState("00.00");
  const [Tax, setTax] = useState("00.00");


  // const [payment, setPayment] = useState();
  // const handlePaymentClick = (e) => {
  //     setPayment(e.target.value)
  // }

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const guestCart = getGuestUserCart();
  const [cartItems, setCartItems] = useState([]);
  const [shopping_id, setShopping_id] = useState("");
  console.log("abcde", shopping_id);

  console.log("cart items are", cartItems);
  const uid = getLocalUserData().token;

  const token_id = uid;
  const [currency, setCurrency] = useState("");
  const getUsersData = async () => {
    const formData = new FormData();
    formData.append("token", token_id ? token_id : "");
    formData.append("cart_id", guestCart ? guestCart : "");
    var data = [];

    try {
      const res = await axios.post(getCartList(), formData);
      if (res.status == 200 && res.data) {
        data = res.data;
        console.log("cart list ", data);
        if (data?.status) {
          setUserCountry(data?.country);
          setCurrency(data?.currency_code);
          setCartItems(data?.data);
          setTotal(data?.total_price_including_plan_without_tax);
          setTotalIncTax(data?.total_price_including_plan_with_tax);
          setTax(data?.total_tax)
        } else {
        }
      } else {
        data = [];
      }
    } catch (e) {
      console.log(e);
      data = [];
    }
  };
  const wasCalled = useRef(false);

  useEffect(() => {
    if(wasCalled.current) return;
    wasCalled.current = true;
    getUsersData();
  }, [guestCart]);

  // const defeletedCountry = () => {
  //   if (val?.country === 1) {
  //     return "1 Month";
  //   } else if (val?.country === 2) {
  //     return "6 Months";
  //   } else if (val?.country === 3) {
  //     return "1 Year";
  //   } else {
  //     return;
  //   }
  // };
  const handleChangeCountry = async () => {
    const formData = new FormData();
    formData.append("token", token_id ? token_id : "");
    formData.append("cart_id", guestCart ? guestCart : "");
    var data = [];

    try {
      const res = await axios.post(changePlanOnChange(), formData);
      if (res.status == 200 && res.data) {
        data = res.data;
        console.log("cart list ", data);
      } else {
        data = [];
      }
    } catch (e) {
      console.log(e);
      data = [];
    }
  };
  const handleCurrency = async (e) => {
    const plansSelect = () => {
      if (e === "United Arab Emirates") {
        return 1;
      } else if (e === "Canada") {
        return 2;
      } else if (e === "United Kingdom") {
        return 3;
      } else if (e === "Kuwait") {
        return 4;
      } else {
        return "";
      }
    };
    const param = {
      country_id: plansSelect(),
      shopping_cart_id: shopping_id,
    };

    var data = [];

    try {
      const res = await axios.post(changeCountryCurrency(), param);
      if (res.status == 200 && res.data) {
        data = res.data;
        if (data?.status) {
          getUsersData();
        } else {
        }
        console.log("countryChange ", data);
      } else {
        data = [];
      }
    } catch (e) {
      console.log(e);
      data = [];
    }
  };
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  //enabling and disabling the content
  const [disClass, setDisClass] = useState(true);
  const [clentsecretkey, setClientsecretKey] = useState("");
  const [paymentEnable, setPaymentEnable] = useState(false);

  const paymentdivEnable = async () => {
    let formdata = new FormData();
    formdata.append("token", token_id);
    let data = [];
    try {
      const res = await axios.post(enablepaymentbtn(), formdata);
      if (res.status == 200 && res.data) {
        data = res.data;
        if (data?.status) {
          if(data?.status&&data?.superbuy){
            navigate(`/Payment-status?payment_intent=f598ce58-80a3-4098-8dc2-8bf10fe238a2-33903da2-88f7-448b-ae4c-8aced8939567::${data?.orderid}`)
          }
          setClientsecretKey(data?.client_secret);
          setDisClass(false);
          setPaymentEnable(true);
        } else {
          errorMsg(data?.message);
        }
        console.log("payment ", data);
      } else {
        data = [];
      }
    } catch (e) {
      console.log(e);
      data = [];
    }
  };

  console.log("cartItems", cartItems);

  return (
    <div className="cartWrapperGrid">
      <Container>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            className={disClass ? "" : "target-div disabled"}
          >
            <div className="d-flex justify-content-center">
              <h4 className={classes.subtitle + " font-family"}>
                Choose Your Country
              </h4>
            </div>

            <div className="d-flex justify-content-center">
              <Grid item xs={12} sm={12} md={6}>
                <CssAutocomplete
                  placeholder="Select Country"
                  value={userCountry}
                  callback={(e) => {
                    setUserCountry(e);
                    handleCurrency(e);
                  }}
                  options={[
                    "United Arab Emirates",
                    "Canada",
                    "United Kingdom",
                    "Kuwait",
                  ]}
                />
              </Grid>
            </div>

            <h4 className={classes.subtitle + " font-family"}>Cart</h4>
            {cartItems && cartItems.length > 0 ? (
              <>
                {cartItems?.map((val) => {
                  // setShopping_id(val?.shopping_cart_id)
                  return (
                    <CartCard
                      val={val}
                      classes={classes}
                      setTotal={setTotal}
                      totalIncTax={totalIncTax}
                      total={total}
                      setTotalIncTax={setTotalIncTax}
                      setShopping_id={setShopping_id}
                      getUsersData={getUsersData}
                      currency={currency}
                      count={count}
                      setCount={setCount}
                      country={userCountry}
                    />
                  );
                })}
              </>
            ) : (
              ""
            )}
            {cartItems && cartItems.length > 0 ? (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                className={classes.itemsTotalLabel}
              >
                Total Price: {currency === "AED" ? totalIncTax : total}{" "}
                <span></span>
                {currency}
                {userCountry == "United Arab Emirates" ? (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      className={`mt-3 ${classes.itemsTotalLabel}`}
                      style={{ margin: "0 auto" }}
                    >
                      <div className="">
                        <div className="my-1 d-flex justify-content-between my-3">
                          {" "}
                          <span>Price (without tax):</span>
                          <span className="">
                            {total?total:""}
                            {currency}
                          </span>
                        </div>
                        <div className="my-1 d-flex justify-content-between my-3">
                          {" "}
                          <span> VAT 5% :</span>
                          <span>
                            {Tax?Tax:""}{" "}
                            {currency}
                          </span>
                        </div>
                        <hr />
                        <div className="my-1 d-flex justify-content-between my-3">
                          {" "}
                          <span>Total Price (Including VAT 5%)</span>
                          <span>
                            {currency === "AED" ? totalIncTax : total}{" "}
                            {currency}
                          </span>
                        </div>
                      </div>

                      {/* Total Price: {total} <span></span>
  {currency}
  {
    userCountry=="United Arab Emirates"?<span className="ms-3">( Including VAT 5% )</span>:""
  } */}
                    </Grid>
                  </>
                ) : (
                  ""
                )}
              </Grid>
            ) : (
              <>
                <img src={emptycart} alt="" />
                {/* <p className="text-center">Your cart is empty</p> */}
              </>
            )}

            {cartItems && cartItems.length > 0 ? (
              <div className="d-flex justify-content-center mt-4">
                <Button
                  className="proceedBtn"
                  onClick={() => paymentdivEnable()}
                >
                  Proceed to payment
                  <span className="mx-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      width="1.4em"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z"
                      />
                    </svg>
                  </span>
                </Button>
              </div>
            ) : (
              ""
            )}
          </Grid>

          <Grid item xs={0} sm={0} md={0.5}>
            <div
              className={`${classes.spliter} ${classes.cartmobileViewSplitter}`}
            ></div>{" "}
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={3.5}
            className={disClass ? `target-div disabled` : ""}
          >
            <Grid item container>
              <div className="d-flex justify-content-center mt-4">
                {/* <Button className="editcartBtn" onClick={() => paymentdivEnable()}>
                  Edit Cart
                  <svg width="1.3em" className="ms-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M4.00488 16V4H2.00488V2H5.00488C5.55717 2 6.00488 2.44772 6.00488 3V15H18.4433L20.4433 7H8.00488V5H21.7241C22.2764 5 22.7241 5.44772 22.7241 6C22.7241 6.08176 22.7141 6.16322 22.6942 6.24254L20.1942 16.2425C20.083 16.6877 19.683 17 19.2241 17H5.00488C4.4526 17 4.00488 16.5523 4.00488 16ZM6.00488 23C4.90031 23 4.00488 22.1046 4.00488 21C4.00488 19.8954 4.90031 19 6.00488 19C7.10945 19 8.00488 19.8954 8.00488 21C8.00488 22.1046 7.10945 23 6.00488 23ZM18.0049 23C16.9003 23 16.0049 22.1046 16.0049 21C16.0049 19.8954 16.9003 19 18.0049 19C19.1095 19 20.0049 19.8954 20.0049 21C20.0049 22.1046 19.1095 23 18.0049 23Z"></path></svg>
                  </Button> */}
              </div>
              <Grid item xs={12} sm={12} md={12}>
                <div className="my-1 d-flex justify-content-between my-3">
                  {" "}
                  <span>Collecting Payment of :</span>
                  <span>
                    {currency === "AED" ? totalIncTax : total} {currency}
                  </span>
                </div>
                <h4 className={classes.subtitle + " font-family"}>
                  Payment Method:
                </h4>
              </Grid>

              <Grid
                container
                rowSpacing={1}
                display="flex"
                justifyContent="space-around"
                className="ms-2"
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <div
                  className="mb-4"
                  // onClick={() => (window.location.href = "https://google.com")}
                >
                  <Grid item xs={6}>
                    <img
                      src="https://becexamguide.com/wp-content/uploads/2020/10/logo-stripe.png"
                      width="190%"
                    />
                  </Grid>
                </div>
                {paymentEnable ? <Dummy clentsecretkey={clentsecretkey} /> : ""}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Cart;
