import React, { useEffect, useState } from "react";
import { Box, Container, Grid } from "@mui/material"
import CssButton from "../../Components/Reusables/CssButton";
import CssTextField from "../../Components/Reusables/CssTextField";
import classes from './PaymentMethods.module.css'
import CssSelect from "../../Components/Reusables/CssSelect";
import Mc from "../../Components/assets/mc.jpg"
import Visa from "../../Components/assets/visa.jpg"
import Ppl from "../../Components/assets/ppl.jpg"
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";

const PaymentMethods = () => {

    // const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

    const [payment, setPayment] = useState();
    const handlePaymentClick = (e) => {
        setPayment(e.target.value)
    }

    const onCreateOrder = (data, actions) => {
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: "8.99",
                    },
                },
            ],
        });
    }

    const onApproveOrder = (data, actions) => {
        return actions.order.capture().then((details) => {
            const name = details.payer.name.given_name;
            alert(`Transaction completed by ${name}`);
        });
    }

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    return (
        <Box>
            <Box className="personal-info-main-box">
                <Container>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12}>
                            <Box>
                                <p className={classes.tc}>
                                    Select Payment Method
                                </p>
                            </Box>
                            <Box className={classes.flex + ' ' + classes['jc-se'] + ' ' + classes.payment}>
                                <img src={Visa} width={75} height={60}
                                />
                                <img src={Mc}
                                    width={75}
                                    height={60}
                                />
                                <img style={{ border: "2px solid orange", borderRadius: "10px" }} src={Ppl} width={75}
                                    height={60}
                                />

                            </Box>
                        </Grid>

                        {/* <Grid item display='flex' justifyContent='center' width='100%'>
                            {isPending ? <p>LOADING...</p> : (
                                <PayPalButtons
                                    style={{ layout: "vertical", tagline: false, shape: "pill" }}
                                    createOrder={(data, actions) => onCreateOrder(data, actions)}
                                    onApprove={(data, actions) => onApproveOrder(data, actions)}
                                    onError={() => alert("payment failed try again later")}
                                />
                            )}

                        </Grid> */}

                    </Grid>
                </Container>

            </Box>


        </Box>
    );
}

export default PaymentMethods;