import { Container, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import UserContext from "../../Components/context/UserContext";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "../../Components/utils/Helper";
import { errorMsg, success } from "../../Components/ToasterFn";
import axios from "axios";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const handleSubmit = async () => {
    const form_data = new FormData();
    form_data.append("email", email);
    var data = [];

    try {
      const res = await axios.post(forgotPassword(), form_data);
      if (res.status && res.data) {
        data = res.data;
        console.log("resetpass--- ", data);
        if (data?.status) {
            setEmail("")
          success(data?.message);
        } else {
          errorMsg(data?.message);
        }
        // setTotalPrice(data?.data);
      } else {
        data = [];
        console.log(res?.response?.data?.message);
        errorMsg(res?.data?.message);
      }
    } catch (e) {
      console.log(e);
      errorMsg(e?.response?.data?.message);

      data = [];
    }
  };
  // success("An email with a link has been sent")
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <Box component="form">
      <Container sx={{ display: "flex", justifyContent: "center" }}>
        <Grid container justifyContent="center" xs={11} sm={8} md={5} mt={10}>
          <Grid item xs={12} sm={12} md={12}>
            <Box className="login-form-title">
              <p className="login-title">Enter Your Email</p>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              className="lgn-field"
              required
              sx={{
                "& label.Mui-focused": {
                  color: "black",
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "orange",
                  },
                },
                width: "100%",
                height: " 61px",
                [`& fieldset`]: {
                  borderRadius: 48,
                },
              }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              label="Enter your Email"
              id="custom-css-outlined-input"
            />
          </Grid>

          <Grid item mt={2} xs={12} sm={12} md={12}>
            <Button
              className="lgn-field"
              type="submit"
              sx={{
                width: "100%",
                height: "51px",
                background: "#FD9B13",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: "50px",
                color: "black",
                backgroundColor: "orange",
                fontFamily: "Calli Cat",
                fontWeight: "400",
                fontSize: "20px",
                "&:hover": {
                  backgroundColor: "orange",
                  color: "white",
                },
              }}
              onClick={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ForgetPassword;
