import React from 'react'
import orderSuccess from "../../src/assests/failed-payment.jpg";

const OrderFailure = () => {
  return (
    <div className="container">
    <div className="my-5 Payment-order-wrapper text-center">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <h1 className="order-success">Order Not Placed</h1>
          <div className="Payment-order__media">
            {/* <img src={OrderMedia.src} className='img-fluid' alt='' /> */}
            <img
              src={orderSuccess}
              className="img-fluid img"
              alt=""
              width="250"
              height="50"
            />
          </div>
          <div className="Payment-order__content mt-3">
            <h4>Try Again for your purchase!</h4>
            <p>
              You will receive your order confirmation email with details of
              your order when It Will Be Placed.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default OrderFailure