import React, { useState } from "react";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

function PetVaccineUpload({vaccination0,vaccination1,vaccination2}) {
  const [files, setFiles] = useState([]);
  const [inputCount, setInputCount] = useState(1);
  const [allImg, setAllImg] = useState([]);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles([...files, ...selectedFiles]);
  };



  const handleDeleteClick = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const AppendDiv = () => {
    setInputCount(inputCount + 1);
  };
  const uploadVaccineFile = (e,index) => {
    if(index == 0){
      vaccination0(e.target.files[0])
    }if( index== 1){
      vaccination1(e.target.files[0])
    }if(index == 2){
      vaccination2(e.target.files[0])
    }
  }

  return (
    <div>
      {Array.from({ length: inputCount }).map((_, index) => (
        <div className="xyz my-2 d-flex justify-content-center" key={index}>
          {/* Replace the input tag with a Material-UI file input */}
          <input
            type="file"
            onChange={(e) => {handleFileChange(e)
              uploadVaccineFile(e,index)}}
            style={{ display: "none" }}
            id={`file-input-${index}`}
          />
          <label htmlFor={`file-input-${index}`}>
            <Button
              component="span"
              variant="contained"
              color="warning"
              startIcon={<CloudUploadIcon />}
            >
              {files[index] ? files[index].name : "Upload File"}
            </Button>
          </label>
          {files[index] && (
            <button onClick={() => handleDeleteClick(index)} className="btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="1.2em"
              >
                <path d="M4 8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8ZM6 10V20H18V10H6ZM9 12H11V18H9V12ZM13 12H15V18H13V12ZM7 5V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V5H22V7H2V5H7ZM9 4V5H15V4H9Z"></path>
              </svg>{" "}
            </button>
          )}
        </div>
      ))}
      
        <div className="d-flex justify-content-center">
          {inputCount < 3 ? (
            <button
              onClick={AppendDiv}
              className="btn "
              disabled={inputCount > 4}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="1.1em"
              >
                <path d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"></path>
              </svg> Add
            </button>
          ) : (
            ""
          )}
        </div>
      

      {/* <button onClick={handleUploadClick} className="btn btn-outline-dark">
        Upload
      </button> */}
    </div>
  );
}

export default PetVaccineUpload;
