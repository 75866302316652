import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useState } from "react";
import CssButton from "../../Components/Reusables/CssButton";
import CssTextField from "../../Components/Reusables/CssTextField";
import { errorMsg, success } from "../../Components/ToasterFn";
import { changePassword } from "../../Components/utils/Helper";
import { getLocalUserData } from "../../Components/utils/Util";

const ChangePassword = ({ classes }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  console.log("oldp", password);
  console.log("newp", confirmPassword);

  const uid = getLocalUserData().token;

  const handleCart = async () => {
    const formData = new FormData();
    formData.append("token", uid);
    formData.append("password", password);
    formData.append("password2", confirmPassword);

    var data = [];

    try {
      const res = await axios.post(changePassword(), formData);
      if (res.status == 200 && res.data) {
        data = res.data;
        if (data?.status) {
          console.log("confirm password ", data);
          success(data?.message);
          setPassword("");
          setConfirmPassword("");
        } else {
          errorMsg(data?.message);
        }
      } else {
        data = [];
        console.log(res?.response?.data?.message);
        errorMsg(res?.data?.message);
      }
    } catch (e) {
      console.log(e);
      errorMsg(e?.response?.data?.message);

      data = [];
    }
  };
  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Grid item>
        <Grid item xs={12} sm={12} md={12}>
          <h4 className={classes["payment-header"]}>Change Password:</h4>
        </Grid>
        <Grid item rowSpacing={2} container md={12}>
          <Grid item xs={12} sm={12} md={6}>
            <Box className="form-titles">
              <p>New Password</p>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <CssTextField
              required={true}
              callback={(dt) => setPassword(dt)}
              width="100%"
              height="33.09px"
              size="small"
              placeholder="Enter New password "
              type="password"
              defaultV={password}
              endIcon={false}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box className="form-titles">
              <p>Confirm Password</p>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <CssTextField
              required={true}
              callback={(dt) => setConfirmPassword(dt)}
              width="100%"
              height="33.09px"
              size="small"
              placeholder="Confirm New password "
              type="password"
              defaultV={confirmPassword}
              endIcon={false}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        columnGap={1}
        item
        md={12}
        justifyContent="flex-end"
        marginTop="20px"
      >
        <Grid item>
          <CssButton
            btnType="reset"
            width="100px"
            variant="outlined"
            height="35px"
            text="Discard"
            hoverColor="black"
            outlineColor="white"
            fontSize="15px"
          />
        </Grid>
        <Grid item>
          <CssButton
            width="100px"
            height="35px"
            text="Save"
            color="orange"
            fontSize="15px"
            callback={() => handleCart()}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default ChangePassword;
