import {
  Container,
  Grid,
  Box,
  MenuItem,
  IconButton,
  CircularProgress,
} from "@mui/material";
import * as React from "react";

import CssButton from "../../Components/Reusables/CssButton";
import CssTextField from "../../Components/Reusables/CssTextField";
import { useContext, useEffect, useState } from "react";
import CatImg from "../../Components/assets/cat-img.png";
import DogImg from "../../Components/assets/dog-img.png";
import CheckIcon from "@mui/icons-material/Check";
import Footer from "../../Components/Footer/Footer";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import UserContext from "../../Components/context/UserContext";
import ReportPetDialog from "../../Components/Dialogs/ReportPetDialog";
import FoundPetDialog from "../../Components/Dialogs/FoundPetDialog";
import { errorMsg, infoMsg, success } from "../../Components/ToasterFn";
import Styles from "./Pets.module.css";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import tounzLogo from "../../Components/assets/tounzLogo.png";
import paw from "../../../src/assests/paw_icon.png";

import { Style } from "@mui/icons-material";
import {
  petDetailByScan,
  isMissedpetFound,
} from "../../Components/utils/Helper";
import ReportMissedPet from "./ReportMissedPet";
import { useRef } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: "auto",
  bgcolor: "background.paper",
  border: "5px solid #FDC",
  boxShadow: 24,
  p: 6,
};
const PetDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  console.log("param", params);
  const [item, setItem] = useState("");
  const { userCtx } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  // const [errorMsg, setErrorMsg] = useState("");
  const [showDetail, setShowDetail] = useState(false);
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const location = useLocation();
  const locData = location.pathname.split("/");
  console.log("xyz", locData[1]);
  // const getPetDetails = async () => {
  //     setIsLoading(true)
  //     const req = axios.get(`${userCtx.mainUrl}/pet/getPetByTag/${params.id}`)
  //         .then(res => { setIsLoading(false); setItem(res.data) })
  //         .catch(err => { setIsLoading(false); setErrorMsg("Pet tag is not found") })
  // }

  // useEffect(() => {
  //     getPetDetails()
  // }, [])
  const [petDetail, setPetDetail] = useState("");
  const [msg, setMsg] = useState("");
  const [pId, setPId] = useState("");

  const getPetInfo = async () => {
    var data = [];

    try {
      const res = await axios.get(petDetailByScan(params.slug));
      if (res.status == 200 && res.data) {
        data = res.data;
        console.log("dataxyz ", data);
        setOpen(false);
        if (data?.type == "1") {
          return navigate(`/UserInfo/${params?.slug}`);
        } else if (data?.type == "2") {
          return navigate("/Login");
        } else if (data?.type == "3") {
          return navigate("/Login");
        } else if (data?.type == "4") {
          setPetDetail(data?.data);
        }
        setPId(data?.data?.pet?.id);
      } else {
        console.log("xyz", res?.data);
        setMsg(res?.data?.message);

        data = [];
      }
    } catch (e) {
      setMsg(e?.response?.data?.message);

      console.log(e);
      data = [];
    }
  };
  console.log("pd", petDetail);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  console.log("lon", longitude);
  console.log("lat", latitude);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []);

  const wasCalled = useRef(false);

  useEffect(() => {
    if (wasCalled.current) return;
    wasCalled.current = true;
    
    getPetInfo();
    
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  useEffect(() => {
    if (pId) {
      sendpetlocation();
    }
  }, [pId]);

  const [openReportPet, setOpenReportPet] = useState(false);

  const reportMissed = () => {
    setOpenReportPet(true);
    // setPetID(id);
  };
  const [seenLocation, setSeenLocation] = useState();
  const sendpetlocation = async () => {
    const params = {
      pet_id: pId,
      longitude: longitude,
      latitude: latitude,
      location: seenLocation ? seenLocation : "",
    };
    // const formdata = new FormData();
    // formdata.append("token", token);
    // formdata.append("code", id);
    var data = [];

    try {
      const res = await axios.post(isMissedpetFound(), params);
      if (res.status == 200 && res.data) {
        data = res.data;
        // console.log("useredit--- ", data);
        if (data?.status) {
          setOpenReportPet(false);
          success(data?.message);
        } else {
          errorMsg(data?.message);
          // setErrorMsg(data?.message);
        }
      } else {
        data = [];
        console.log(res?.response?.data?.message);
        // errorMsg(res?.data?.message);
      }
    } catch (e) {
      console.log(e);
      // errorMsg(e?.response?.data?.message);

      data = [];
    }
  };

  return (
    <>
      {/* <h1>{params.slug}</h1> */}
      {petDetail ? (
        <Container maxWidth="lg">
          <Grid
            container
            display="flex"
            justifyContent="center"
            alignItems="center"
            rowGap={10}
          >
            <Grid item md={6}>
              <Box
                className={Styles.mainBox}
                sx={{
                  padding: "20px",
                  backgroundColor: petDetail?.pet?.is_missing
                    ? "#ffcbbc"
                    : "white",
                  borderRadius: "35px",
                  marginTop: "8%",
                }}
              >
                <Box>
                  <Grid
                    item
                    container
                    display="flex"
                    justifyContent={{ xs: "center", md: "right" }}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid item container xs={12} sm={12} md={12}>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <p className={Styles.petDetailsHeader}>Pet’s Info</p>
                      </Box>
                    </Grid>
                    <Grid item container xs={12} sm={12} md={12}>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          width="50%"
                          alt="pet image"
                          src={petDetail?.pet?.pet_pic}
                        />
                      </Box>
                    </Grid>
                    {petDetail?.pet?.is_missing ? (
                      <Grid
                        item
                        container
                        sx={{ marginTop: "1rem" }}
                        justifyContent={{
                          xs: "center",
                          sm: "center",
                          md: "center",
                        }}
                        md={12}
                      >
                        <CssButton
                          fontSize="17px"
                          callback={() => {
                            reportMissed();
                            //   setId(item._id);
                          }}
                          height="100%"
                          xs={{ width: "fit-content" }}
                          text="Report Pet Location"
                          // text={
                          //   val?.is_missing ? "FOUND IT" : "Report Missing Pet"
                          // }
                          // textColor={val?.is_missing ? "black" : "#FF0000"}
                          color="orange"
                        />
                      </Grid>
                    ) : (
                      ""
                    )}
                    <Grid item xs={6} sm={6} md={6}>
                      <p className="pet-info-2" style={{ marginTop: "20px" }}>
                        Pet Name
                      </p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                      <p className="pet-info-3" style={{ marginTop: "20px" }}>
                        {petDetail?.pet?.name}{" "}
                      </p>
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md={6}>
                      <p className="pet-info-2">Pet Species</p>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <p className="pet-info-3">labradour</p>
                    </Grid> */}
                    <Grid item xs={6} sm={6} md={6}>
                      <p className="pet-info-2">Pet Gender</p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                      <p className="pet-info-3">{petDetail?.pet?.gender}</p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                      <p className="pet-info-2">Pet Age</p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                      <p className="pet-info-3">{petDetail?.pet?.age}</p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                      <p className="pet-info-2">Breed</p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                      <p className="pet-info-3">{petDetail?.pet?.breed}</p>
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md={6}>
                      <p className="pet-info-2">Pet Shade</p>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <p className="pet-info-3">labradour</p>
                    </Grid> */}
                    <Grid item xs={6} sm={6} md={6}>
                      <p className="pet-info-2">Allergies</p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                      <p className="pet-info-3">
                        {petDetail?.pet?.is_allergy_affected == "true"
                          ? "Yes"
                          : "No"}
                      </p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                      <p className="pet-info-2">Pet Status</p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                      <p className="pet-info-3">
                        {petDetail?.pet?.health_condition}
                      </p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                      <p className="pet-info-2">Comments</p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                      <p className="pet-info-3">
                        {" "}
                        {petDetail?.pet?.description == "null" || ""
                          ? ""
                          : petDetail?.pet?.description}
                      </p>
                    </Grid>
                    <Grid item container xs={12} sm={12} md={12}>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <p className={Styles.petDetailsHeader}>
                          Pet Parent’s Info
                        </p>
                      </Box>
                    </Grid>

                    <Grid item xs={6} sm={6} md={6}>
                      <p className="pet-info-2">Pet Parent Name</p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                      <p className="pet-info-3">
                        {petDetail?.user_data?.pet_parent_name}
                      </p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                      <p className="pet-info-2">Contact Number</p>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                      <p className="pet-info-3">
                        {petDetail?.user_data?.contact_no}
                      </p>
                    </Grid>
                    {/* <Grid item xs={6} sm={6} md={6}>
                      <p className="pet-info-2">Address</p>{" "}
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      style={{ wordBreak: "break-word" }}
                    >
                      <p className="pet-info-3">
                        {petDetail?.user_data?.address}
                      </p>
                    </Grid> */}
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      sx={{
                        mt: {
                          xs: 3,
                          sm: 0,
                          lg: 0,
                          md: 0,
                          xl: 0,
                          xxl: 0,
                        },
                      }}
                      style={{ wordBreak: "break-word" }}
                    >
                      <p className="pet-info-2">Emergency Contact First Name</p>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      sx={{
                        mt: {
                          xs: 3,
                          sm: 0,
                          lg: 0,
                          md: 0,
                          xl: 0,
                          xxl: 0,
                        },
                      }}
                    >
                      <p className="pet-info-3">
                        {petDetail?.user_data?.emergency_contact_f_name
                          ? petDetail?.user_data?.emergency_contact_f_name
                          : "-"}
                      </p>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      sx={{
                        mt: {
                          xs: 3,
                          sm: 0,
                          lg: 0,
                          md: 0,
                          xl: 0,
                          xxl: 0,
                        },
                      }}
                      style={{ wordBreak: "break-word" }}
                    >
                      <p className="pet-info-2">Emergency Contact Last Name</p>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      sx={{
                        mt: {
                          xs: 3,
                          sm: 0,
                          lg: 0,
                          md: 0,
                          xl: 0,
                          xxl: 0,
                        },
                      }}
                    >
                      <p className="pet-info-3">
                        {petDetail?.user_data?.emergency_contact_l_name
                          ? petDetail?.user_data?.emergency_contact_l_name
                          : "-"}
                      </p>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      sx={{
                        mt: {
                          xs: 3,
                          sm: 0,
                          lg: 0,
                          md: 0,
                          xl: 0,
                          xxl: 0,
                        },
                      }}
                    >
                      <p className="pet-info-2">Emergency Contact Number</p>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      sx={{
                        mt: {
                          xs: 3,
                          sm: 0,
                          lg: 0,
                          md: 0,
                          xl: 0,
                          xxl: 0,
                        },
                      }}
                    >
                      <p className="pet-info-3">
                        {petDetail?.user_data?.emergency_contact_number
                          ? petDetail?.user_data?.emergency_contact_number
                          : ""}
                      </p>
                    </Grid>

                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      sx={{
                        mt: {
                          xs: 3,
                          sm: 0,
                          lg: 0,
                          md: 0,
                          xl: 0,
                          xxl: 0,
                        },
                      }}
                    ></Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      sx={{
                        mt: {
                          xs: 3,
                          sm: 0,
                          lg: 0,
                          md: 0,
                          xl: 0,
                          xxl: 0,
                        },
                      }}
                    >
                      <Link to="/login" className="pet-info-3">
                        <img src={paw} alt="" width={70} height={70} />
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
          {/* Latitude: {latitude}<br/>
      Longitude: {longitude} */}
        </Container>
      ) : (
        ""
      )}

      <div>
        <Modal
          keepMounted
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="d-flex justify-content-center">
              <img src={tounzLogo} alt="logo" width={150} />
            </div>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <div className="text-center" style={{ fontFamily: "Calli Cat" }}>
                <h3>Welcome to Tounz</h3>
                <h5>{msg ? msg : ""}</h5>
              </div>

              {/* <div className="mb-5 mt-2">
                <h6 className="text-center">
                  Dou you have an existing account?
                </h6>
                <div className={Styles.buttonAligenmentsDiv}>
                  <div className="ms-3">
                    <button
                      className="btn btn-outline-success"
                      onClick={() => {
                        setShowDetail(true);
                        handleClose();
                      }}
                    >
                      LOG IN
                    </button>
                  </div>
                  <div className={Styles.popUpmiddleBtnDiv}>
                    <button
                      className={`btn btn-success ${Styles.popUpmiddleBtn} `}
                      onClick={() => {
                        setShowDetail(true);
                        handleClose();
                      }}
                    >
                      REGISTER
                    </button>
                  </div>
                  <div className="me-3">
                    <button
                      className="btn btn-warning"
                      onClick={() => {
                        navigate("/UserInfo");
                        handleClose();
                      }}
                    >
                      I'M NEW
                    </button>
                  </div>
                </div>
              </div> */}

              {/* if already registered click{" "}
              <button
              className="btn btn-outline-success"
              onClick={() => {
              setShowDetail(true);
              handleClose();
              }}
              >
              here
              </button> */}
            </Typography>
            {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  if not click{" "}
                  <button
                  className="btn btn-outline-warning"
                  onClick={() => {
                  navigate("/Plans");
                  handleClose();
                  }}
                  >
                  here
                  </button>
                </Typography> */}
          </Box>
        </Modal>
      </div>

      {openReportPet ? (
        <ReportMissedPet
          openReportPet={openReportPet}
          setOpenReportPet={setOpenReportPet}
          sendpetlocation={sendpetlocation}
          setSeenLocation={setSeenLocation}
        />
      ) : null}
    </>
  );
};

export default PetDetails;
