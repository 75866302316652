import { Grid, Box } from "@mui/material";
import "../AddUserPet/AddUserPet.css";
import CssButton from "../../Components/Reusables/CssButton";
import CssTextField from "../../Components/Reusables/CssTextField";
import { useContext, useEffect, useRef, useState } from "react";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import CssUploadButton from "../../Components/Reusables/CssUploadButton";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { errorMsg, success } from "../../Components/ToasterFn";
import DB from "../../Components/assets/dogBreeds.json";
import CB from "../../Components/assets/catBreeds.json";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import CssAutocomplete from "../../Components/Reusables/CssAuocomplete";
import { addUserPetDetail, allBreeds } from "../../Components/utils/Helper";
import { getLocalUserData, PetsQr } from "../../Components/utils/Util";
import AntdImg from "./AntdImg";
import FileUploadMultiple from "./FileUploadMultiple";
import PetVaccineUpload from "./PetVaccineUpload";
import PetHistory from "./PetHistory";
import PetInsuranceUpload from "./PetInsuranceUpload";

window.Buffer = window.Buffer || require("buffer").Buffer;

const AddUserPet = () => {
  const uid = getLocalUserData().token;

  const [selectedImage, setSelectedImage] = useState("");
  const [petName, setPetName] = useState("");
  const [petSpec, setPetSpec] = useState("");
  const [petGender, setPetGender] = useState("");
  const [petAge, setPetAge] = useState("");
  const [petBreed, setPetBreed] = useState("");
  const [petColor, setPetColor] = useState("");
  const [petAllergy, setPetAllergy] = useState("");
  const [petStatus, setPetStatus] = useState("");
  const [petVaccine, setPetVaccine] = useState(false);
  const [petVet, setPetVet] = useState("");
  const [petComm, setPetComm] = useState("");
  const [petTagId, setPetTagId] = useState("");
  const [petTagToShow, setPetTagToShow] = useState("");

  const [imageBinary, setImageBinary] = useState(null);

  const showPreview = (e) => {
    setSelectedImage(e.target.files[0]);
    if (e.target.files.length > 0) {
      var src = URL.createObjectURL(e.target.files[0]);
      var preview = document.getElementById("file-ip-1-preview");
      preview.src = src;
      preview.style.display = "block";
    }
  };

  const navigate = useNavigate();
  const [uploadedFile0, setUploadedFile0] = useState(null);
  const [uploadedFile1, setUploadedFile1] = useState(null);
  const [uploadedFile2, setUploadedFile2]= useState(null);
  const [petHistory,setPetHistory] = useState(null)
  const [petInsure,setPetInsurance] = useState(null)
  const saveddata = async () => {
    const formData = new FormData();
    formData.append("token", uid);
    formData.append("name", petName);
    formData.append("pet_type", petSpec);
    formData.append("breed", petBreed);
    formData.append("age", petAge);
    formData.append("color", petColor);
    formData.append("is_allergy_affected", petAllergy);
    formData.append("tag_id", petTagId);
    formData.append("insurance_file",petInsure)
    formData.append("history_file",petHistory)
    formData.append("vaccination_file1", uploadedFile0);
    if(uploadedFile1){ formData.append("vaccination_file2", uploadedFile1)}
    if(uploadedFile2){ formData.append("vaccination_file3", uploadedFile2)}
    formData.append("health_condition", petStatus);
    formData.append("vaccination", petVaccine);
    formData.append("vet_name", petVet);
    formData.append("gender", petGender);
    formData.append("description", petComm);
    formData.append("pet_pic", imageBinary);

    var data = [];
    if (petTagId === "No Tag to Add") {
      errorMsg("Tag not availabble for selected species");
    } else {
      try {
        const res = await axios.post(addUserPetDetail(), formData);
        if (res.status && res.data) {
          data = res.data;
          console.log("userpets ", data);
          if (data?.status) {
            navigate("/userPets");
            setPetName("");
            setPetSpec("");
            setPetGender("");
            success(data?.message);
          } else {
            errorMsg(data?.message);
          }
        } else {
          data = [];
          console.log(res?.response?.data?.message);

          errorMsg(res?.data?.message);
        }
      } catch (e) {
        console.log(e);
        errorMsg(e?.response?.data?.message);

        data = [];
      }
    }
  };

  const getUsersData = () => {
    console.log("pnm", petName);
    console.log("pspc", petSpec);
    console.log("pgen", petGender);
    console.log("page", petAge);
    console.log("pbreeed", petBreed);
    console.log("pclr", petColor);
    console.log("paller", petAllergy);
    console.log("pstatus", petStatus);
    console.log("pvac", petVaccine);
    console.log("pvett", petVet);
    console.log("pcomm", petComm);
    console.log("ptag", petTagId);
    console.log("image", selectedImage);
  };
  const [dogAllTags, setDogAllTags] = useState([]);
  const [catAllTags, setCatAllTags] = useState([]);
  const wasCalled = useRef(false);

  const [dBreed, setDBreed] = useState([]);
  const [cBreed, setCBreed] = useState([]);

  const petBreeds = async () => {
    var data = [];

    try {
      const res = await axios.get(allBreeds());
      if (res.status && res.data) {
        data = res.data;
        console.log("userpetssas ", data.dog_breeds);
        if (data?.status) {
          setDBreed(data?.data?.dog_breeds);
          setCBreed(data?.data?.cat_breeds);
        } else {
          errorMsg(data?.message);
        }
      } else {
        data = [];
        console.log(res?.response?.data?.message);

        errorMsg(res?.data?.message);
      }
    } catch (e) {
      console.log(e);
      errorMsg(e?.response?.data?.message);

      data = [];
    }
  };

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (wasCalled.current) return;
    wasCalled.current = true;
    petBreeds();

    PetsQr(setDogAllTags, setCatAllTags);
  }, []);

  // console.log(dogAllTags, "dgt");
  // console.log(catAllTags, "cgt");

  return (
    <Box
      // component="form"
      sx={{ width: "100%", display: "flex", justifyContent: "center" }}
    >
      <Box className="add-pet-main-box">
        <Grid container rowSpacing={7} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} sm={12} md={12}>
            <Box className="parent-info-title">Pet's Info</Box>
          </Grid>
          <Grid container item rowGap={5} columnSpacing={3}>
            <Grid item xs={12} sm={12} md={2.5}>
              <Box className="upload-photo-box">
                {/* <Box className="upload-photo-card">
                  <Box className="form-input">
                    <Box
                      style={{ display: selectedImage ? "block" : "none" }}
                      className="preview"
                    >
                      <img id="file-ip-1-preview" />
                    </Box>
                    {!selectedImage ? (
                      <>
                        <label for="file-ip-1">
                          <Box className="upload-inner-text">
                            <AddAPhotoIcon
                              sx={{ "&:hover": { color: "#ffa500" } }}
                              fontSize="large"
                            />
                          </Box>
                        </label>
                        <input
                          type="file"
                          id="file-ip-1"
                          accept="image/*"
                          onChange={(e) => showPreview(e)}
                        />
                      </>
                    ) : null}
                  </Box>
                </Box> */}
              </Box>
              {/* <Box
                display={selectedImage ? "block" : "none"}
                className="clear-image-btn"
                mt={2}
                textAlign="center"
              >
                <CssButton
                  btnType="button"
                  callback={() => setSelectedImage("")}
                  height="25px"
                  color="orange"
                  text={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      height="1.3em"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                      />
                    </svg>
                  }
                />
              </Box> */}
              <AntdImg
                setImageBinary={setImageBinary}
                imageBinary={imageBinary}
              />
            </Grid>
            <Grid item md={9.5}>
              <Box className="pet-info-card">
                <Box sx={{ p: 1.5 }}>
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid item xs={12} sm={12} md={6}>
                      <p className="pet-info-title2">Pet Name</p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <CssTextField
                        required="true"
                        defaultV={petName}
                        callback={(e) => {
                          setPetName(e);
                        }}
                        field="name"
                        width="100%"
                        height="10px"
                        placeholder="Enter Pet's Name"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <p className="pet-info-title2">Pet Species</p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <CssAutocomplete
                        placeholder="Choose Species"
                        required={true}
                        callback={(e) => {
                          setPetSpec(e);
                        }}
                        options={["Dog", "Cat"]}
                        value={petSpec}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      {" "}
                      <p className="pet-info-title2">Pet Gender</p>{" "}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <CssAutocomplete
                        placeholder="Choose Gender"
                        required={true}
                        callback={(e) => setPetGender(e)}
                        options={["Female", "Male"]}
                        value={petGender}
                      />{" "}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      {" "}
                      <p className="pet-info-title2">Pet Age</p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <CssTextField
                        required="true"
                        callback={(e) => setPetAge(e)}
                        field="age"
                        width="100%"
                        height="10px"
                        placeholder="Enter Pet's Age"
                        size="small"
                        value={petAge}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                      {" "}
                      <p className="pet-info-title2">Breed</p>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                      {petSpec == "Dog" ? (
                        <CssAutocomplete
                          placeholder="Choose Breed"
                          callback={(e) => setPetBreed(e)}
                          options={dBreed ? dBreed : []}
                          value={petBreed}
                        />
                      ) : (
                        <CssAutocomplete
                          placeholder="Choose Breed"
                          callback={(e) => setPetBreed(e)}
                          options={cBreed ? cBreed : []}
                          value={petBreed}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      {" "}
                      <p className="pet-info-title2">Pet Shade</p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <CssTextField
                        required="true"
                        callback={(e) => setPetColor(e)}
                        field="shade"
                        width="100%"
                        height="10px"
                        placeholder="Enter Pet's Shade"
                        size="small"
                        value={petColor}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      {" "}
                      <p className="pet-info-title2">Allergies</p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <CssAutocomplete
                        placeholder="Choose Allergies"
                        required={true}
                        callback={(e) => setPetAllergy(e)}
                        options={["Yes", "No"]}
                        value={petAllergy}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      {" "}
                      <p className="pet-info-title2">Pet Status</p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <CssAutocomplete
                        placeholder="Choose Pet's Status"
                        required={true}
                        callback={(e) => setPetStatus(e)}
                        options={["Healthy", "Unhealthy"]}
                        value={petStatus}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      {" "}
                      <p className="pet-info-title2">Pet History</p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <PetHistory history = {setPetHistory}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <p className="pet-info-title2">Pet Insurance</p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <PetInsuranceUpload insure = {setPetInsurance}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}></Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      className="d-flex justify-content-center"
                    >
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              //   checked={checked}
                              onChange={(e) => setPetVaccine(e.target.checked)}
                              defaultChecked={petVaccine}
                              color="warning"
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                          label=" Vaccinated!"
                        />
                      </FormGroup>
                    </Grid>
                    {petVaccine ? (
                      <>
                        {" "}
                        <Grid item xs={12} sm={12} md={6}>
                          {" "}
                          <p className="pet-info-title2">Pet Vaccination</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <PetVaccineUpload vaccination0 = {setUploadedFile0} vaccination1 = {setUploadedFile1} vaccination2 = {setUploadedFile2}/>
                        </Grid>
                      </>
                    ) : (
                      ""
                    )}

                    {/* <Grid item xs={12} sm={12} md={6}>
                      {" "}
                      <p className="pet-info-title2">Vaccination</p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <CssUploadButton
                        callback={(event) => setUploadedFile(event)}
                        width="100%"
                        height="100%"
                        text={uploadedFile ? uploadedFile.name : "Upload Image"}
                        color="orange"
                        fontSize="15px"
                        icon="upload"
                        
                      >
                      </CssUploadButton>
                       <p className="text-center">
                         {uploadedFile?.name}
                        </p>
                    </Grid> */}

                    <Grid item xs={12} sm={12} md={6}>
                      {" "}
                      <p className="pet-info-title2">Vet’s Name</p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <CssTextField
                        required="true"
                        callback={(e) => setPetVet(e)}
                        field="vetName"
                        width="100%"
                        height="10px"
                        placeholder="Enter Pet's Vet. Name"
                        size="small"
                        value={petVet}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      {" "}
                      <p className="pet-info-title2">Comments</p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <CssTextField
                        required="true"
                        callback={(e) => setPetComm(e)}
                        field="comments"
                        width="100%"
                        height="10px"
                        placeholder="Enter Pet's Comments"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      {" "}
                      <p className="pet-info-title2">Link the tag</p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      {/* <CssTextField
                        type="number"
                        required="true"
                        value={petTagId}
                        callback={(e) => setPetTagId(e)}
                        field="tagNumber"
                        width="100%"
                        height="10px"
                        placeholder="Enter the tag number"
                        size="small"
                      /> */}
                      {petSpec == "Dog" ? (
                        <CssAutocomplete
                          placeholder="9876543"
                          callback={(e) => {
                            console.log("petTag", e.split("-")[0].trim());
                            setPetTagId(e.split("-")[0].trim());
                            setPetTagToShow(e);
                          }}
                          options={dogAllTags ? dogAllTags : ["No Tag to Add"]}
                          value={petTagToShow}
                        />
                      ) : petSpec == "Cat" ? (
                        <CssAutocomplete
                          placeholder="9876543"
                          callback={(e) => {
                            console.log("petTag", e.split("-")[0].trim());
                            setPetTagId(e.split("-")[0].trim());
                            setPetTagToShow(e);
                          }}
                          options={catAllTags ? catAllTags : ["No Tag to Add"]}
                          value={petTagToShow}
                        />
                      ) : (
                        <>
                          <CssAutocomplete
                            placeholder="Choose Tag"
                            callback={(e) => setPetTagId(e)}
                            options={[]}
                            value={petTagId}
                          />
                          <p className="ms-2 mt-2 text-danger">
                            First select Pet Species <sup>*</sup>{" "}
                          </p>
                        </>
                      )}
                      {/* <CssAutocomplete
                        placeholder="9876543"
                        callback={(e) => setPetTagId(e)}
                        options={[6458783, 7866756, 4567765,   6576878]}  
                        value={petTagId}
                      /> */}
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid
            item
            display="flex"
            justifyContent="flex-end"
            container
            xs={8.2}
            sm={7.5}
            md={8}
          >
            <Box className="save-pet-btn">
              <CssButton
                callback={(e) => {
                  e.preventDefault();
                  saveddata();
                }}
                btnType="submit"
                width="160px"
                height="35px"
                text="Save"
                color="orange"
                fontSize="15px"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* <FileUploadMultiple/> */}
    </Box>
  );
};

export default AddUserPet;
