import React, { useContext, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import { Container } from "@mui/material";
import "./MissingPets.css";
import PetDetailsDialog from "../../Components/Dialogs/PetDetailsDialog/PetDetailsDialog";

import axios from "axios";
import UserContext from "../../Components/context/UserContext";
import DogInfo from "../../Components/assets/dog-img.png";

import missingPets from "../../Components/assets/missing-pets.png";
import { getMissingPetsDetails } from "../../Components/utils/Helper";

const MissingPets = () => {
  const [openDetails, setOpenDetails] = useState(false);
  const [petDetails, setPetDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [pets, setPets] = useState([]);
  const { userCtx } = useContext(UserContext);


  const getMissingPets = async () => {
    var data = [];

    try {
      const res = await axios.get(getMissingPetsDetails());
      if (res.status == 200 && res.data) {
        data = res.data;
        console.log("missingPets ", data?.data);
        setPets(data?.data);
      } else {
        data = [];
      }
    } catch (e) {
      console.log(e);
      data = [];
    }
  };
  const wasCalled = useRef(false);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setIsLoading(true);
    if(wasCalled.current) return;
    wasCalled.current = true;
    getMissingPets();
  }, []);
  const openModalFunc = (val) => {
    setPetDetails(val);
    setOpenDetails(true);
  };
  console.log("pd", petDetails);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Container sx={{ mt: 5 }}>
          {pets && pets?.length > 0 ? (
            <>
              <ul className="card-wrapper">
                {pets.map((val) => {
                  return (
                    <li className="card">
                      <img src={val?.pet?.pet_pic} alt="" />
                      <p className=" name-title">Name of Pet</p>
                      <p className=" pet-name">
                        {val?.pet?.name ? val?.pet?.name : ""}
                      </p>
                      <p className=" pet-location-title">
                        Location it was last seen in
                      </p>
                      <p className=" pet-location">
                        {val?.last_seen_location ? val?.last_seen_location : ""}
                      </p>
                      <p className=" contact-title">Contact Number</p>
                      <p className=" contact">{val?.mobile}</p>
                      <Box
                        className="missiing-pet-box"
                        onClick={() => {
                          openModalFunc(val);
                        }}
                      >
                        <button className="missing-pet-details-btn">
                          View details
                        </button>
                      </Box>
                    </li>
                  );
                })}
              </ul>
            </>
          ) : (
            <Box className="missingPetImg">
              <img
                style={{ width: "500px" }}
                src={missingPets}
                className="missing-pets-text"
              ></img>
            </Box>
          )}
          {/* {mapPets} */}

          {/* */}
        </Container>
        {openDetails ? (
          <PetDetailsDialog
            openDetails={openDetails}
            setOpenDetails={setOpenDetails}
            petDetails={petDetails}
          />
        ) : null}
      </Box>
    </>
  );
};
export default MissingPets;
