import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import CssTextField from "../../Components/Reusables/CssTextField";
import { Box, FormControlLabel, FormGroup, Grid, Switch } from "@mui/material";
import CssAutocomplete from "../../Components/Reusables/CssAuocomplete";
import CssUploadButton from "../../Components/Reusables/CssUploadButton";
import CssButton from "../../Components/Reusables/CssButton";
import axios from "axios";
import { getLocalUserData } from "../../Components/utils/Util";
import { errorMsg, success } from "../../Components/ToasterFn";
import { allBreeds, editPetDetails } from "../../Components/utils/Helper";
import EditUploadsModal from "../../Components/Common/EditUploadsModal";

const EditPetInfo = ({ data }) => {
  const [petId, setPetId] = useState(data?.id);

  const [uploadedFile, setUploadedFile] = useState(null);
  const [petName, setPetName] = useState(data?.name);
  const [petSpec, setPetSpec] = useState(data?.type);
  const [petGender, setPetGender] = useState(data?.gender);
  const [petAge, setPetAge] = useState(data?.age);
  const [petBreed, setPetBreed] = useState(data?.breed);
  const [petColor, setPetColor] = useState(data?.color);
  const [petAllergy, setPetAllergy] = useState(data?.is_allergy_affected);
  const [petStatus, setPetStatus] = useState(data?.health_condition);
  const [petVaccine, setPetVaccine] = useState(data?.vaccination);
  const [petVet, setPetVet] = useState(data?.vet_name);
  const [petComm, setPetComm] = useState(data?.description);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [textValue, setTextValue] = useState("");
  const [isImage, setIsImage] = useState(false);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploadedFile(file);
      setIsImage(true);
    }
  };
  const onTextChange = (e) => setTextValue(e.target.value);
  const handleSubmit = () => console.log(textValue);
  const handleReset = () => setTextValue("");
  console.log("page", petAge);
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const uid = getLocalUserData().token;

  const saveddata = async (anchor) => {
    const formData = new FormData();
    formData.append("token", uid);
    formData.append("name", petName);
    formData.append("pet_type", petSpec);
    formData.append("breed", petBreed);
    formData.append("age", petAge);
    formData.append("color", petColor);
    formData.append("is_allergy_affected", petAllergy);
    if (isImage) {
      formData.append("vacinated_file", uploadedFile);
    }
    formData.append("health_condition", petStatus);
    formData.append("vaccination", petVaccine);
    formData.append("vet_name", petVet);
    formData.append("gender", petGender);
    formData.append("description", petComm);

    var data = [];

    try {
      const res = await axios.post(editPetDetails(petId), formData);
      if (res.status == 200 && res.data) {
        data = res.data;
        if (data?.status) {
          success("Updated Sucessfully");
          window.location.reload();
        } else {
          errorMsg(data?.message);
        }
        console.log("userpets ", data);
      } else {
        data = [];
        console.log(res?.response?.data?.message);
        errorMsg(res?.data?.message);
      }
    } catch (e) {
      console.log(e);
      errorMsg(e?.response?.data?.message);

      data = [];
    }
  };

  const [dBreed, setDBreed] = useState([]);
  const [cBreed, setCBreed] = useState([]);

  const petBreeds = async () => {
    var data = [];

    try {
      const res = await axios.get(allBreeds());
      if (res.status && res.data) {
        data = res.data;
        console.log("userpetssas ", data.dog_breeds);
        if (data?.status) {
          setDBreed(data?.data?.dog_breeds);
          setCBreed(data?.data?.cat_breeds);
        } else {
          errorMsg(data?.message);
        }
      } else {
        data = [];
        console.log(res?.response?.data?.message);

        errorMsg(res?.data?.message);
      }
    } catch (e) {
      console.log(e);
      errorMsg(e?.response?.data?.message);

      data = [];
    }
  };

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    petBreeds();
  }, []);

  return (
    <div>
      {["right"].map((anchor) => (
        <>
          <Button
            onClick={toggleDrawer(anchor, true)}
            className="edit-info-btn"
          >
            Edit info
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            className="edit-info-offcanvas"
          >
            <div className="mb-5">
              <h6 className="ms-4 me-4 mt-4">Edit Info</h6>
              <div className="d-flex justify-content-center px-3 mt-3">
                <Grid item xs={12} sm={12} md={6}>
                  <p className="pet-info-title2">Pet Name</p>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <CssTextField
                    required="true"
                    defaultV={petName}
                    callback={(e) => {
                      setPetName(e);
                    }}
                    field="name"
                    width="100%"
                    height="10px"
                    placeholder="Enter Pet's Name"
                    size="small"
                  />
                </Grid>
              </div>
              <div className="d-flex justify-content-center px-3 mt-3">
                <Grid item xs={12} sm={12} md={6}>
                  <p className="pet-info-title2">Pet Species</p>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <CssAutocomplete
                    placeholder="Choose Species"
                    required={true}
                    options={["Dog", "Cat"]}
                    callback={(e) => setPetSpec(e)}
                    value={petSpec}
                  />
                </Grid>
              </div>
              <div className="d-flex justify-content-center px-3 mt-3">
                <Grid item xs={12} sm={12} md={6}>
                  {" "}
                  <p className="pet-info-title2">Pet Gender</p>{" "}
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <CssAutocomplete
                    placeholder="Choose Gender"
                    required={true}
                    options={["Female", "Male"]}
                    callback={(e) => setPetGender(e)}
                    value={petGender}
                  />{" "}
                </Grid>
              </div>
              <div className="d-flex justify-content-center px-3 mt-3">
                <Grid item xs={12} sm={12} md={6}>
                  {" "}
                  <p className="pet-info-title2">Pet Age</p>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <CssTextField
                    required="true"
                    defaultV={petAge}
                    callback={(e) => setPetAge(e)}
                    field="age"
                    width="100%"
                    height="10px"
                    placeholder="Enter Pet's Age"
                    size="small"
                  />
                </Grid>
              </div>
              <div className="d-flex justify-content-center px-3 mt-3">
                <Grid item xs={12} sm={12} md={6}>
                  {" "}
                  <p className="pet-info-title2">Breed</p>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  {petSpec == "Dog" ? (
                    <CssAutocomplete
                      placeholder="Choose Breed"
                      callback={(e) => setPetBreed(e)}
                      options={dBreed ? dBreed : []}
                      value={petBreed}
                    />
                  ) : (
                    <CssAutocomplete
                      placeholder="Choose Breed"
                      callback={(e) => setPetBreed(e)}
                      options={cBreed ? cBreed : []}
                      value={petBreed}
                    />
                  )}
                </Grid>
              </div>
              <div className="d-flex justify-content-center px-3 mt-3">
                <Grid item xs={12} sm={12} md={6}>
                  {" "}
                  <p className="pet-info-title2">Pet Shade</p>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <CssTextField
                    required="true"
                    field="shade"
                    width="100%"
                    height="10px"
                    placeholder="Enter Pet's Shade"
                    size="small"
                    callback={(e) => setPetColor(e)}
                    defaultV={petColor}
                  />
                </Grid>{" "}
              </div>
              <div className="d-flex justify-content-center px-3 mt-3">
                <Grid item xs={12} sm={12} md={6}>
                  {" "}
                  <p className="pet-info-title2">Allergies</p>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <CssAutocomplete
                    placeholder="Choose Pet's Allergies"
                    required={true}
                    options={["Yes", "No"]}
                    callback={(e) => setPetAllergy(e)}
                    value={petAllergy}
                  />
                </Grid>{" "}
              </div>
              <div className="d-flex justify-content-center px-3 mt-3">
                <Grid item xs={12} sm={12} md={6}>
                  {" "}
                  <p className="pet-info-title2">Pet Status</p>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <CssAutocomplete
                    placeholder="Choose Pet's Status"
                    required={true}
                    options={["Healthy", "Unhealthy"]}
                    callback={(e) => setPetStatus(e)}
                    value={petStatus}
                  />
                </Grid>{" "}
              </div>
              {/* <div className="d-flex justify-content-center px-3 mt-3">
                <Grid item xs={12} sm={12} md={6}>
                  {" "}
                  <p className="pet-info-title2">Vaccination</p>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <label htmlFor="upload-photo">
                    <input
                      style={{ display: "none" }}
                      id="upload-photo"
                      name="upload-photo"
                      type="file"
                      // value={uploadedFile}
                      onChange={handleImageChange}
                    />

                    <Button
                      color="warning"
                      variant="contained"
                      component="span"
                    >
                      Upload Vaccination File
                    </Button>
                  </label>
                </Grid>
              </div>
              <div className="d-flex justify-content-center px-3 mt-3">
                <Grid item xs={12} sm={12} md={6}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          // checked={checked}
                          onChange={(e) => setPetVaccine(e.target.checked)}
                          defaultChecked={petVaccine}
                          color="warning"
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Vaccinated!"
                    />
                  </FormGroup>
                </Grid>
              </div> */}
              <div className="d-flex justify-content-center px-3 mt-3">
                <Grid item xs={12} sm={12} md={6}>
                  {" "}
                  <p className="pet-info-title2">Vet’s Name</p>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <CssTextField
                    required="true"
                    callback={(e) => setPetVet(e)}
                    field="vetName"
                    width="100%"
                    height="10px"
                    placeholder="Enter Pet's Vet. name"
                    size="small"
                    defaultV={petVet}
                  />
                </Grid>
              </div>
              <div className="d-flex justify-content-center px-3 mt-3">
                <Grid item xs={12} sm={12} md={6}>
                  {" "}
                  <p className="pet-info-title2">Comments</p>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <CssTextField
                    required="true"
                    callback={(e) => setPetComm(e)}
                    field={petComm}
                    width="100%"
                    height="10px"
                    placeholder="Enter Comments"
                    size="small"
                    defaultV={petComm}
                  />
                </Grid>
              </div>

              <div className="text-center mt-4 mb-3">
                <Grid
                  item
                  display="flex"
                  justifyContent="center"
                  container
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <Box className="save-pet-btn">
                    <CssButton
                      callback={(e) => {
                        e.preventDefault();
                        saveddata(anchor);
                      }}
                      btnType="submit"
                      width="160px"
                      height="35px"
                      text="Save"
                      color="orange"
                      fontSize="15px"
                    />
                  </Box>
                </Grid>{" "}
              </div>
              <div className="d-flex justify-content-center">
                <EditUploadsModal petId = {petId}/>
              </div>
                <p className=" text-center mt-2"><sup className="text-danger">*</sup>( Pet Insurance,Vaccine & History )</p>
            </div>
          </Drawer>
        </>
      ))}
    </div>
  );
};
export default EditPetInfo;
