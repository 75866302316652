import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import './AdminNavbar.css'
import tounzLogo from '../assets/tounzLogo.png'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import QrCodeScannerOutlinedIcon from '@mui/icons-material/QrCodeScannerOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useContext } from 'react';
import UserContext from '../context/UserContext';
import { useNavigate } from 'react-router-dom';


const AdminNavbar = () => {

    const { userCtx, setUserCtx } = useContext(UserContext)
    const navigate = useNavigate()

    const logout = () => {
       
        navigate("/")
    }
    return (
        <Box>
            <Box className="sidenav">
                <Box>
                    <img height={150} width={150} className="adminNavLogo" alt="logo" src={tounzLogo} />
                </Box>
                <Link to="/Clients" className='adminNavLink' ><PeopleAltOutlinedIcon className='adminNavLinkIcon' /> Clients </Link>
                <Link to="/QrCodes" className='adminNavLink' ><QrCodeScannerOutlinedIcon className='adminNavLinkIcon' /> QR Codes</Link>
                <Link to="/Login" onClick={logout} className='adminNavLink' ><LogoutOutlinedIcon className='adminNavLinkIcon' />Logout</Link>
            </Box>
        </Box>
    );
}

export default AdminNavbar;