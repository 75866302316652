import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CssTextField from "../Reusables/CssTextField";
import { getLocalUserData } from "../utils/Util";
import { missedPetFound } from "../utils/Helper";
import { errorMsg } from "../ToasterFn";
import axios from "axios";

export default function FoundPetDialog({ foundIt, setOpenFounIt, pid }) {
  const handleClose = () => {
    setOpenFounIt(false);
  };
  const uid = getLocalUserData().token;
  const reportPet = async () => {
    const formData = new FormData();
    formData.append("token", uid);
    formData.append("pet_id", pid);

    var data = [];

    try {
      const res = await axios.post(missedPetFound(), formData);
      if (res.status == 200 && res.data) {
        data = res.data;
        console.log("userpets ", data);
        window.location.reload();
      } else {
        data = [];
        console.log(res?.response?.data?.message);
        errorMsg(res?.data?.message);
      }
    } catch (e) {
      console.log(e);
      errorMsg(e?.response?.data?.message);

      data = [];
    }
  };
  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        {/* <DialogTitle id="responsive-dialog-title">

                </DialogTitle> */}
        <DialogContent>
          <DialogContentText>
            Confirm that your pet is not missing anymore
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            sx={{
              "&:hover": {
                border: "1px solid #fd9b13",
                bgcolor: "white",
              },
              color: "black",
              bgcolor: "#fff",
              borderRadius: "30px",
              border: "1px solid orange",
            }}
            variant="outlined"
          >
            Discard
          </Button>
          <Button
            sx={{
              "&:hover": {
                color: "black",
                bgcolor: "#fd9b13",
              },
              bgcolor: "#fd9b13",
              borderRadius: "30px",
            }}
            variant="contained"
            onClick={reportPet}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
