

import React, { useState } from "react";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

function PetHistory({history}) {
  const [files, setFiles] = useState([]);

  const handleFileChange = (e) => {
    setFiles(e.target.files);
    history(e.target.files[0])
  };
  const handleDeleteClick = () => {
    setFiles([]);
  };



  return (
    <div>
      <div className="xyz my-2 d-flex justify-content-center">
        <input
          type="file"
          onChange={(e) => handleFileChange(e)}
          style={{ display: "none" }}
          id={`pInsur`}
        />
        <label htmlFor={`pInsur`}>
          <Button
            component="span"
            variant="contained"
            color="warning"
            startIcon={<CloudUploadIcon />}
          >
            {files.length > 0
              ? files[0].name
              : "Upload File"}
          </Button>
        </label>
        {files.length > 0 && (
          <button onClick={() => handleDeleteClick()} className="btn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="1.2em"
            >
              <path d="M4 8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8ZM6 10V20H18V10H6ZM9 12H11V18H9V12ZM13 12H15V18H13V12ZM7 5V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V5H22V7H2V5H7ZM9 4V5H15V4H9Z"></path>
            </svg>{" "}
          </button>
        )}
      </div>
      {/* <button onClick={handleUploadClick} className="btn btn-outline-dark">
        Upload
      </button> */}
    </div>
  );
}

export default PetHistory;
