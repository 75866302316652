import {
  Box,
  Button,
  Container,
  Grid,
  Grow,
  IconButton,
  Modal,
  Popover,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import {
  useHistory,
  useNavigate,
  useLocation,
  useParams,
  Link,
} from "react-router-dom";
import UserContext from "../../Components/context/UserContext";
import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/Navbar";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import CssButton from "../../Components/Reusables/CssButton";
import CssTextField from "../../Components/Reusables/CssTextField";
import { errorMsg, infoMsg, success } from "../../Components/ToasterFn";
import "./PersonalInformation.css";
import { MuiTelInput } from "mui-tel-input";
import CssUploadButton from "../../Components/Reusables/CssUploadButton";
import CssAutocomplete from "../../Components/Reusables/CssAuocomplete";
import Popper from "@mui/material/Popper";
import PopupState, {
  bindToggle,
  bindPopper,
  bindTrigger,
} from "material-ui-popup-state";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { addPersonalInfo } from "../../Components/utils/Helper";
import {
  getGuestUserCart,
  getLocalUserData,
} from "../../Components/utils/Util";
import Login from "../Login/Login";
import PersonalInfoLogin from "./PersonalInfoLogin";
import Checkbox from '@mui/material/Checkbox';
const PersonalInformation = () => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    height: 500,
    bgcolor: "background.paper",
    border: "5px solid #FDC",
    boxShadow: 24,
    p: 0,
  };

  const [phone, setPhone] = useState("");

  const handleChange = (newPhone) => {
    setPhone(newPhone);
  };
  const location = useLocation();
  const locData = location.pathname.slice(1);
  console.log("locca", locData);

  const { userCtx } = useContext(UserContext);
  const params = useParams();

  const [tagId, setTagId] = useState(params?.tag);

  console.log("param", params);
  const [petType, setPetType] = useState("");

  // const [username, setUsername] = useState("");
  const [parentPhoto, setParentPhoto] = useState("");

  const [selectedImage, setSelectedImage] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [mobile, setmobile] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [emerFirstname, setEmerFirstname] = useState(false);
  const [emerLastName, setEmerLastName] = useState("");
  const [emerRelation, setemerRelation] = useState("");
  const [emerContact, setEmerContact] = useState("");

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmDataShare, setConfirmDataShare] = useState(false);
  const navigate = useNavigate();
  const handleconfirmDataShare =() =>{
    setConfirmDataShare(!confirmDataShare)
    // alert(confirmDataShare)
  }
  const saveddata = async () => {
    const guest_id = getGuestUserCart();
    const token = getLocalUserData().token;
    const formData = new FormData();
    formData.append("tag_id", tagId ? tagId : "");
    formData.append("tag_type", petType ? petType : "");
    formData.append("first_name", name);
    formData.append("last_name", lastName);
    formData.append("email", email);
    formData.append("country", country);
    formData.append("city", city);
    formData.append("address", address);
    formData.append("mobile", mobile);
    formData.append("id_number", idNumber);
    formData.append("photo_id", selectedImage);
    formData.append("emergency_first_name", emerFirstname);
    formData.append("emergency_last_name", emerLastName);
    formData.append("emergency_contact_relationship", emerRelation);
    formData.append("emergency_contact_number", emerContact);
    formData.append("username", username);
    formData.append("password1", password);
    formData.append("password2", confirmPassword);
    formData.append("cart_id", guest_id);

    var data = [];

    if(confirmDataShare){
    try {
      const res = await axios.post(addPersonalInfo(), formData);
      if (res.status && res.data) {
        data = res.data;
        console.log("add pets info ", data);
        if (data?.status) {
          if (locData === "PersonalInfo") {
            if (guest_id) {
              success(data?.message);
              return navigate("/Login");
            } else if (token) {
              success(data?.message);
              return navigate("/UserCart");
            } else {
              errorMsg("Looks Like You Didn't Added Any Tag !");
            }
          } else {
            return navigate("/Login");
          }

          // window.location.reload();
        } else {
          errorMsg(data?.message);
        }
      } else {
        data = [];
        console.log(res?.response?.data?.message);
        errorMsg(res?.data?.message);
      }
    } catch (e) {
      console.log(e);
      errorMsg(e?.response?.data?.message);

      data = [];
    }
  }else{
    errorMsg("Data share is not clicked");
  }
  };

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <Box>
      <Box className="personal-info-main-box">
        <form>
          <Container>
            <Grid container rowSpacing={3}>
              {/* <Grid item xs={12} sm={12} md={6}>
                <p
                  className="pet-info-title3"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <span>Your Tag Id</span>{" "}
                </p>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <CssButton
                  callback={(e) => {
                    e.preventDefault();
                    navigate("/login");
                  }}
                  btnType="submit"
                  width="160px"
                  height="35px"
                  text="Login"
                  color="orange"
                  fontSize="15px"
                />
              </Grid> */}
              <Grid item xs={12} sm={12} md={12}>
                <Box className="personalinfologintext">
                  <p>
                    Already Registered ?{" "}
                    {locData === `UserInfo/${tagId}` ? (
                      <>
                        <span
                          className="text-warning"
                          onClick={() => setOpen(true)}
                          style={{ cursor: "pointer" }}
                        >
                          Login
                        </span>
                      </>
                    ) : (
                      <Link to="/Login" className="text-warning">
                        Login
                      </Link>
                    )}
                  </p>
                </Box>
                <Box className="personal-header">
                  <p>Personal Information</p>
                </Box>
              </Grid>
              <Grid item container rowSpacing={{ xs: 1, sm: 2, md: 2 }} md={12}>
                {locData === `UserInfo/${tagId}` ? (
                  <>
                    <Grid item xs={12} sm={12} md={6}>
                      <p
                        className="pet-info-title3"
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <span>Your Tag Id</span>{" "}
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <CssTextField
                        required="true"
                        // callback={(data) => setIdNumber(data)}
                        field="idNumber"
                        height="10px"
                        placeholder="Enter ID Number"
                        size="small"
                        defaultV={tagId}
                        // country={country}
                      />
                    </Grid>
                  </>
                ) : (
                  ""
                )}
                {locData === `UserInfo/${tagId}` ? (
                  <>
                    <Grid item xs={12} sm={12} md={6}>
                      <p
                        className="pet-info-title3"
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <span>Your Pet Type</span>{" "}
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <CssAutocomplete
                        value={petType}
                        required={true}
                        placeholder="Dog/Cat"
                        callback={(e) => setPetType(e)}
                        options={["Dog", "Cat"]}
                      />
                    </Grid>
                  </>
                ) : (
                  ""
                )}
                <Grid item xs={12} sm={12} md={6}>
                  <Box className="form-titles">
                    <p>First Name</p>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <CssTextField
                    callback={(e) => {
                      setName(e);
                    }}
                    defaultV={name}
                    required={true}
                    height="33.09px"
                    size="small"
                    placeholder="Enter First Name "
                    endIcon={false}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Box className="form-titles">
                    <p>Last Name</p>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <CssTextField
                    callback={(e) => {
                      setLastName(e);
                    }}
                    defaultV={lastName}
                    required={true}
                    height="33.09px"
                    size="small"
                    placeholder="Enter Last Name "
                    endIcon={false}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Box className="form-titles">
                    <p>Email</p>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <CssTextField
                    type="email"
                    defaultV={email}
                    required={true}
                    height="33.09px"
                    size="small"
                    placeholder="Enter Email "
                    endIcon={false}
                    callback={(e) => {
                      setEmail(e);
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  {" "}
                  <p className="pet-info-title3">Country</p>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <CssAutocomplete
                    value={country}
                    required={true}
                    placeholder="United Arab Emirates"
                    callback={(e) => setCountry(e)}
                    options={[
                      "Canada",
                      "United Arab Emirates",
                      "United Kingdom",
                      "Kuwait",
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  {" "}
                  <p className="pet-info-title3">City</p>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <CssTextField
                    required="true"
                    callback={(e) => {
                      setCity(e);
                    }}
                    defaultV={city}
                    field="city"
                    height="10px"
                    placeholder="Enter City"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Box className="form-titles">
                    <p>Address</p>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <CssTextField
                    callback={(data) => setAddress(data)}
                    required={true}
                    height="33.09px"
                    size="small"
                    placeholder="Enter Address "
                    endIcon={false}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Box className="form-titles">
                    <p>Contact Number</p>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Box className="tel-box">
                    <MuiTelInput
                      required
                      onlyCountries={["AE", "CA", "GB", "KW"]}
                      size="small"
                      defaultCountry={
                        country === "Canada"
                          ? "CA"
                          : country === "United Kingdom"
                          ? "GB"
                          : country === "Kuwait"
                          ? "KW"
                          : "AE"
                      }
                      sx={{
                        width: "100%",
                        "&.MuiFormControl-root": {
                          "& .MuiInputBase-root": {
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "1px solid #bfb7aa",
                            },
                          },
                        },
                      }}
                      value={mobile}
                      onChange={(data) => setmobile(data)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <p
                    className="pet-info-title3"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <span>ID Number</span>{" "}
                    <span style={{ marginTop: "-3px" }}>
                      {!!country ? <MyPopup country={country} /> : null}
                    </span>
                  </p>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <CssTextField
                    required="true"
                    callback={(data) => setIdNumber(data)}
                    field="idNumber"
                    height="10px"
                    placeholder="Enter ID Number"
                    size="small"
                    country={country}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  {" "}
                  <p className="pet-info-title3">Photo ID</p>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <CssUploadButton
                    callback={(event) => setSelectedImage(event)}
                    height="100%"
                    text={selectedImage ? selectedImage.name : "Upload Photo"}
                    color="orange"
                    fontSize="15px"
                    icon="upload"
                  ></CssUploadButton>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  {" "}
                  <p className="pet-info-title3">Emergency Contact Name</p>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <CssTextField
                    required="true"
                    callback={(e) => setEmerFirstname(e)}
                    field="name"
                    height="10px"
                    placeholder="First Name"
                    size="small"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  display="flex"
                  justifyContent="flex-end"
                >
                  <CssTextField
                    callback={(e) => setEmerLastName(e)}
                    field="lastName"
                    width="50%"
                    height="10px"
                    placeholder="Last Name"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Box className="form-titles">
                    <p>Emergency Contact No.</p>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Box className="tel-box">
                    <MuiTelInput
                      required
                      onlyCountries={["AE", "CA", "GB", "KW"]}
                      size="small"
                      defaultCountry={
                        country === "Canada"
                          ? "CA"
                          : country === "United Kingdom"
                          ? "GB"
                          : country === "Kuwait"
                          ? "KW"
                          : "AE"
                      }
                      sx={{
                        width: "100%",
                        "&.MuiFormControl-root": {
                          "& .MuiInputBase-root": {
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "1px solid #bfb7aa",
                            },
                          },
                        },
                      }}
                      value={emerContact}
                      onChange={(data) => setEmerContact(data)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  {" "}
                  <p className="pet-info-title3">
                    Emergency Contact Relationship{" "}
                  </p>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <CssTextField
                    callback={(e) => setemerRelation(e)}
                    field="relation"
                    height="10px"
                    placeholder="Son"
                    size="small"
                  />
                </Grid>

                {/* <Grid item xs={12} sm={12} md={6}>
                  <CssTextField
                    required={true}
                    callback={(dt) => setEmerContact(dt)}
                    height="33.09px"
                    size="small"
                    placeholder="Enter Conatct No. "
                    endIcon={false}
                  />
                </Grid> */}
                <Grid item xs={12} sm={12} md={6}>
                  <Box className="form-titles">
                    <p>Username</p>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <CssTextField
                    required={true}
                    callback={(dt) => setUsername(dt)}
                    height="33.09px"
                    size="small"
                    placeholder="Enter Username "
                    endIcon={false}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Box className="form-titles">
                    <p>Password</p>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <CssTextField
                    required={true}
                    callback={(dt) => setPassword(dt)}
                    height="33.09px"
                    size="small"
                    placeholder="Enter Password "
                    type="password"
                    endIcon={false}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Box className="form-titles">
                    <p>Confirm Password</p>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <CssTextField
                    callback={(dt) => setConfirmPassword(dt)}
                    required={true}
                    height="33.09px"
                    size="small"
                    placeholder="Confirm Password "
                    type="password"
                    endIcon={false}
                  />
                  {/* <p className="personal-info-terms-conditions">
                    Your use of our Site constitutes acceptance of the{" "}
                    <strong>Terms and Conditions</strong> of Use and your
                    agreement to be bound by them.
                  </p> */}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Box className="form-titles">
                    <p>Data will be shared with Tounz Partners</p>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                <Checkbox  onChange={()=>{handleconfirmDataShare();}} checked={confirmDataShare}/>
                  <p className="personal-info-terms-conditions">
                    Your use of our Site constitutes acceptance of the{" "}
                    <strong>Terms and Conditions</strong> of Use and your
                    agreement to be bound by them.
                  </p>
                </Grid>
              </Grid>
              <Grid container item md={11.9}>
                {locData === `UserInfo` ? (
                  <>
                    <Grid
                      item
                      container
                      justifyContent={{
                        xs: "center",
                        sm: "right",
                        md: "right",
                      }}
                      md={12}
                    >
                      <CssButton
                        callback={(e) => {
                          e.preventDefault();
                          saveddata();
                        }}
                        btnType="submit"
                        width="160px"
                        height="35px"
                        text="Save"
                        color="orange"
                        fontSize="15px"
                      />
                    </Grid>
                  </>
                ) : (
                  <Grid
                    item
                    container
                    justifyContent={{ xs: "center", sm: "right", md: "right" }}
                    md={12}
                  >
                    <CssButton
                      callback={(e) => {
                        e.preventDefault();
                        saveddata();
                      }}
                      btnType="submit"
                      width="160px"
                      height="35px"
                      text="Save"
                      color="orange"
                      fontSize="15px"
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Container>
        </form>
      </Box>
      <div>
        <Modal
          keepMounted
          open={open}
          disableBackdropClick={true}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div style={{ width: "100%", height: "300px" }}>
              <svg
                onClick={() => handleClose()}
                style={{ float: "right", cursor: "pointer" }}
                width="2em"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 10.5858L9.17157 7.75736L7.75736 9.17157L10.5858 12L7.75736 14.8284L9.17157 16.2426L12 13.4142L14.8284 16.2426L16.2426 14.8284L13.4142 12L16.2426 9.17157L14.8284 7.75736L12 10.5858Z"></path>
              </svg>
              <div className="d-flex justify-content-center">
                
                <PersonalInfoLogin tagId={tagId}/>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </Box>
  );
};

export default PersonalInformation;

function MyPopup({ country }) {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <PopupState variant="popper">
      {(popupState) => (
        <div>
          <IconButton
            onMouseOver={(event) => {
              setIsOpen(true);
              setAnchorEl(event.currentTarget);
            }}
            onMouseLeave={() => setIsOpen(false)}
          >
            <InfoOutlinedIcon />
          </IconButton>
          <Popper
            open={isOpen}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transition
          >
            {({ TransitionProps }) => (
              <Grow {...TransitionProps} timeout={350}>
                <Paper>
                  <Typography sx={{ p: 2 }}>
                    {country === "Canada"
                      ? "Put your PR Card Number"
                      : country === "United Kingdom"
                      ? "Put your CitizenCard Number"
                      : country === "Kuwait"
                      ? "Put your CID Number"
                      : "Put your EID Card Number"}
                  </Typography>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      )}
    </PopupState>
  );
}
