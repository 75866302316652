import { Box, Container, Grid, Typography } from "@mui/material";
import CssButton from "../../Components/Reusables/CssButton";
import headerTitle from "../../Components/assets/tranquility.png";
import Img1 from "../../Components/assets/1.png";
import Img2 from "../../Components/assets/2.png";
import Img3 from "../../Components/assets/3.png";
import Img4 from "../../Components/assets/4.png";
import Img5 from "../../Components/assets/5.png";
import Img6 from "../../Components/assets/6.png";
import Img7 from "../../Components/assets/7.png";
import Img8 from "../../Components/assets/8.png";
import Img13 from "../../Components/assets/13.png";
import Img14 from "../../Components/assets/14.png";
import styles from "./HomePage.module.css";
import parse from "html-react-parser";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import Plans from "../Plans/Plans";
import { getAllPlans, HomePageApi } from "../../Components/utils/Helper";
import { Btnurl } from "../../Components/utils/Util";

const HomepageNew = () => {
  const [homeData, setHomeData] = useState([]);

  const HomeContents = async () => {
    let data = [];
    try {
      const res = await axios.get(HomePageApi());
      if (res.status == 200 && res.data) {
        data = res.data;
        console.log("orders--", data);
        if (data?.status) {
          console.log("res", data?.data);
          setHomeData(data?.data);
        }
      } else {
        data = [];
      }
    } catch (e) {
      console.log(e);
      data = [];
    }
  };
  const wasCalled = useRef(false);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    localStorage.clear();
    if (wasCalled.current) return;
    wasCalled.current = true;
    HomeContents();
  }, []);
  const navigate = useNavigate();

  return (
    <Container fixed>
      <center>
        <Box sx={{ m: 5 }}>
          <Grid container rowSpacing={10}>
            {homeData && homeData?.length > 0 ? (
              <>
                {homeData?.map((data) => {
                  if (data?.block_type === 1 && data?.block1_serialized_data) {
                    return (
                      <>
                        {data?.block1_serialized_data.length > 0 ? (
                          <Grid
                            container
                            rowSpacing={{ xs: 1, sm: 1 }}
                            item
                            md={12}
                          >
                            <Grid
                              order={{ xs: 2, sm: 2, md: 1 }}
                              container
                              item
                              xs={12}
                              sm={12}
                              md={6}
                              spacing={0}
                            >
                              <Grid
                                container
                                display="flex"
                                textAlign={{
                                  xs: "center",
                                  sm: "center",
                                  md: "left",
                                }}
                                justifyContent={{
                                  xs: "center",
                                  sm: "center",
                                  md: "left",
                                }}
                                columnSpacing={1}
                                item
                                xs={12}
                                sm={12}
                                md={12}
                              >
                                <Box className={styles.contentBox}>
                                  <img
                                    style={{
                                      textAlign: "center",
                                      width: "70%",
                                      marginLeft: "-15px",
                                    }}
                                    src={
                                      data?.block1_serialized_data[0]?.image2
                                    }
                                    className={styles.firstHeader}
                                  ></img>
                                  <p className={styles.content}>
                                    <p>
                                      {/* {data?.block1_serialized_data[0]?.title} */}

                                      {data?.block1_serialized_data[0]
                                        ?.description
                                        ? parse(
                                            data?.block1_serialized_data[0]
                                              ?.description
                                          )
                                        : ""}
                                    </p>
                                  </p>
                                </Box>
                                <Grid item xs={12} sm={5} md={5}>
                                  <CssButton
                                    callback={() =>
                                      navigate(
                                        Btnurl(
                                          data?.block1_serialized_data[0]
                                            ?.button1_url
                                        )
                                      )
                                    }
                                    height="45px"
                                    color="orange"
                                    text={
                                      data?.block1_serialized_data[0]
                                        ?.button1_text
                                    }
                                    fontSize="19px"
                                    width="100%"
                                  />
                                </Grid>
                                <Grid item xs={12} sm={4} md={5}>
                                  <Box>
                                    <button
                                      onClick={() =>
                                        navigate(
                                          Btnurl(
                                            data?.block1_serialized_data[0]
                                              ?.button2_url
                                          )
                                        )
                                      }
                                      className={styles.subBtn}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <span>
                                          {" "}
                                          {data?.block1_serialized_data[0]
                                            ?.button2_subtitle
                                            ? data?.block1_serialized_data[0]
                                                ?.button2_subtitle
                                            : ""}
                                        </span>
                                        <span style={{ marginTop: "-5px" }}>
                                          {" "}
                                          {
                                            data?.block1_serialized_data[0]
                                              ?.button2_text
                                          }
                                        </span>
                                      </Box>
                                    </button>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid
                              order={{ xs: 1, sm: 1 }}
                              item
                              xs={12}
                              sm={12}
                              md={6}
                            >
                              <Box sx={{ width: "80%", height: "90%" }}>
                                <img
                                  src={data?.block1_serialized_data[0]?.image1}
                                  width="100%"
                                  height="100%"
                                  style={{ objectFit: "contain" }}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        ) : (
                          ""
                        )}
                      </>
                    );
                  } else if (
                    data?.block_type === 2 &&
                    data?.block2_serialized_data
                  ) {
                    return (
                      <>
                        {data?.block2_serialized_data.length > 0 ? (
                          data?.block2_serialized_data[0]?.img_is_left ? (
                            <>
                              <Grid container columnSpacing={15} item md={12}>
                                <Grid item xs={12} sm={12} md={6}>
                                  <Box sx={{ width: "100%", height: "100%" }}>
                                    <img
                                      src={
                                        data?.block2_serialized_data[0]?.image2
                                          ? data?.block2_serialized_data[0]
                                              ?.image2
                                          : ""
                                      }
                                      width="100%"
                                      height="100%"
                                      style={{ objectFit: "contain" }}
                                    />
                                  </Box>
                                </Grid>
                                <Grid
                                  display="flex"
                                  textAlign={{
                                    xs: "center",
                                    sm: "center",
                                    md: "center",
                                  }}
                                  item
                                  xs={12}
                                  sm={12}
                                  md={6}
                                >
                                  <Box
                                    className={styles.secondCtBx}
                                    sx={{
                                      width: "100%",
                                      mt: { xs: "40px", sm: 0, md: 0 },
                                    }}
                                  >
                                    <p style={{ textAlign: "center" }}>
                                      {data?.block2_serialized_data[0]
                                        ?.sub_title
                                        ? data?.block2_serialized_data[0]
                                            ?.sub_title
                                        : ""}
                                    </p>
                                    {data?.block2_serialized_data[0]
                                      ?.description
                                      ? parse(
                                          data?.block2_serialized_data[0]
                                            ?.description
                                        )
                                      : ""}
                                  </Box>
                                </Grid>
                              </Grid>
                            </>
                          ) : (
                            <Grid
                              container
                              columnSpacing={3}
                              rowSpacing={{ xs: "30px", sm: "30px", md: "" }}
                              item
                              md={12}
                            >
                              <Grid
                                order={{ xs: 2, sm: 2, md: 1 }}
                                display="flex"
                                justifyContent="center"
                                textAlign="center"
                                alignItems="center"
                                item
                                md={6}
                              >
                                <Box className={styles.sixthCtBx}>
                                  <p style={{ textAlign: "center" }}>
                                    {data?.block2_serialized_data[0]?.sub_title
                                      ? data?.block2_serialized_data[0]
                                          ?.sub_title
                                      : ""}
                                  </p>{" "}
                                  {data?.block2_serialized_data[0]?.description
                                    ? parse(
                                        data?.block2_serialized_data[0]
                                          ?.description
                                      )
                                    : ""}
                                </Box>
                              </Grid>
                              <Grid
                                order={{ xs: 1, sm: 1 }}
                                item
                                xs={12}
                                sm={12}
                                md={6}
                              >
                                <Box sx={{ width: "100%", height: "100%" }}>
                                  <img
                                    src={
                                      data?.block2_serialized_data[0]?.image2
                                        ? data?.block2_serialized_data[0]
                                            ?.image2
                                        : ""
                                    }
                                    width="80%"
                                    height="100%"
                                    style={{ objectFit: "contain" }}
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                          )
                        ) : (
                          ""
                        )}
                      </>
                    );
                  } else if (
                    data?.block_type === 3 &&
                    data?.block3_serialized_data
                  ) {
                    return (
                      <>
                        {data?.block3_serialized_data.length > 0 ? (
                          <>
                            <Grid
                              item
                              display="flex"
                              justifyContent="center"
                              md={12}
                            >
                              <Box
                                sx={{ mb: -5 }}
                                className={styles.thirdSecTitle}
                              >
                                <p>
                                  {data?.block3_serialized_data[0]?.description
                                    ? parse(
                                        data?.block3_serialized_data[0]
                                          ?.description
                                      )
                                    : ""}
                                </p>
                              </Box>
                            </Grid>
                            <Grid container item rowSpacing={5}>
                              <Grid
                                item
                                container
                                columnSpacing={10}
                                rowSpacing={5}
                                display="flex"
                                justifyContent="center"
                                md={12}
                              >
                                {data?.block3_serialized_data[0] &&
                                  data?.block3_serialized_data[0]?.subblock_serilized_data.map(
                                    (val) => {
                                      return (
                                        <Grid
                                          rowSpacing={3}
                                          justifyContent="center"
                                          container
                                          item
                                          xs={12}
                                          sm={6}
                                          md={4}
                                        >
                                          <Grid item xs={12} sx={12} md={12}>
                                            {" "}
                                            <Box
                                              className={styles.fourthSecImg}
                                            >
                                              {" "}
                                              <img src={val?.image} />{" "}
                                            </Box>{" "}
                                          </Grid>
                                          <Grid item xs={12} sx={12} md={12}>
                                            {" "}
                                            <Box
                                              className={styles.fourthSecSubImg}
                                            >
                                              {" "}
                                              <img src={val?.icon} />{" "}
                                            </Box>{" "}
                                          </Grid>
                                          <Grid item xs={12} sx={12} md={12}>
                                            <Box
                                              className={styles.fourthSubHeader}
                                            >
                                              <p>
                                                {val?.text
                                                  ? parse(val?.text)
                                                  : ""}
                                              </p>
                                            </Box>
                                          </Grid>
                                        </Grid>
                                      );
                                    }
                                  )}
                              </Grid>
                              <Grid
                                container
                                item
                                columnSpacing={5}
                                display="flex"
                                justifyContent="center"
                                md={12}
                              >
                                <Grid item xs={12} sm={5} md={3}>
                                  <CssButton
                                    callback={() =>
                                      navigate(
                                        Btnurl(
                                          data?.block3_serialized_data[0]
                                            ?.button1_url
                                        )
                                      )
                                    }
                                    height="45px"
                                    color="orange"
                                    text={Btnurl(
                                      data?.block3_serialized_data[0]
                                        ?.button1_text
                                        ? data?.block3_serialized_data[0]
                                            ?.button1_text
                                        : ""
                                    )}
                                    fontSize="19px"
                                    width="100%"
                                  />
                                </Grid>
                                <Grid item xs={12} sm={5} md={3}>
                                  <Box>
                                    <button
                                      onClick={() =>
                                        navigate(
                                          Btnurl(
                                            data?.block3_serialized_data[0]
                                              ?.button2_url
                                          )
                                        )
                                      }
                                      className={styles.subBtn}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <span
                                          onClick={() =>
                                            navigate(
                                              Btnurl(
                                                data?.block3_serialized_data[0]
                                                  ?.button2_url
                                              )
                                            )
                                          }
                                        >
                                          {data?.block3_serialized_data[0]
                                            ?.button2_subtitle
                                            ? data?.block3_serialized_data[0]
                                                ?.button2_subtitle
                                            : ""}
                                        </span>
                                        <span
                                          onClick={() =>
                                            navigate(
                                              Btnurl(
                                                data?.block3_serialized_data[0]
                                                  ?.button2_url
                                              )
                                            )
                                          }
                                          style={{ marginTop: "-5px" }}
                                        >
                                          {
                                            data?.block3_serialized_data[0]
                                              ?.button2_text
                                          }
                                        </span>
                                      </Box>
                                    </button>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Grid>
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    );
                  } else {
                    return (
                      <>
                        {data?.block4_serialized_data.length > 0 ? (
                          data?.block4_serialized_data[0]?.img_is_left ? (
                            <>
                              <Grid
                                container
                                columnSpacing={5}
                                rowSpacing={{ xs: 5, sm: 5 }}
                                item
                                md={12}
                              >
                                <Grid item xs={12} sm={12} md={6}>
                                  <Box sx={{ width: "100%", height: "100%" }}>
                                    <img
                                      src={
                                        data?.block4_serialized_data[0]?.image
                                          ? data?.block4_serialized_data[0]
                                              ?.image
                                          : ""
                                      }
                                      width="100%"
                                      height="100%"
                                      style={{ objectFit: "contain" }}
                                    />
                                  </Box>
                                </Grid>
                                <Grid container rowSpacing={2} item md={6}>
                                  <Grid
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    textAlign="center"
                                    alignItems="center"
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                  >
                                    <Box className={styles.seventhCtBx}>
                                      <p>
                                        {
                                          data?.block4_serialized_data[0]
                                            ?.sub_title
                                        }
                                      </p>
                                      {/* <p>
                                    This ID tag is made of strong and durable
                                    stainless steel material.
                                  </p> */}
                                      {data?.block4_serialized_data[0]
                                        ?.description
                                        ? parse(
                                            data?.block4_serialized_data[0]
                                              ?.description
                                          )
                                        : ""}
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    justifyContent="center"
                                    xs={12}
                                    sm={12}
                                    md={12}
                                  >
                                    <CssButton
                                      callback={() =>
                                        navigate(
                                          Btnurl(
                                            data?.block4_serialized_data[0]
                                              ?.button_url
                                          )
                                        )
                                      }
                                      height="45px"
                                      width="180px"
                                      color="orange"
                                      text={
                                        data?.block4_serialized_data[0]
                                          ?.button_title
                                      }
                                      fontSize="20px"
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </>
                          ) : (
                            <>
                              <Grid
                                container
                                columnSpacing={3}
                                rowSpacing={{ xs: 5, sm: 5 }}
                                item
                                xs={12}
                                md={12}
                              >
                                <Grid
                                  order={{ xs: 2, sm: 2, md: 1 }}
                                  display="flex"
                                  alignItems="center"
                                  justifyContent={{
                                    sm: "center",
                                    xs: "center",
                                  }}
                                  container
                                  item
                                  xs={12}
                                  sm={12}
                                  md={6}
                                >
                                  <Grid
                                    textAlign={{
                                      sm: "center",
                                      xs: "center",
                                      md: "center",
                                    }}
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                  >
                                    <Box className={styles.seventhCtBx}>
                                      <p>
                                        {
                                          data?.block4_serialized_data[0]
                                            ?.sub_title
                                        }
                                      </p>
                                      {/* <p>
                                    But your pet’s digital
                                    <br /> Passport is not set yet?
                                  </p> */}
                                      {data?.block4_serialized_data[0]
                                        ?.description
                                        ? parse(
                                            data?.block4_serialized_data[0]
                                              ?.description
                                          )
                                        : ""}
                                    </Box>
                                    <Box sx={{ mt: 3 }}>
                                      <CssButton
                                        callback={() =>
                                          navigate(
                                            Btnurl(
                                              data?.block4_serialized_data[0]
                                                ?.button_url
                                            )
                                          )
                                        }
                                        height="45px"
                                        width="180px"
                                        color="orange"
                                        text={
                                          data?.block4_serialized_data[0]
                                            ?.button_title
                                        }
                                        fontSize="18px"
                                      />
                                    </Box>
                                  </Grid>
                                </Grid>
                                <Grid
                                  order={{ xs: 1, sm: 1 }}
                                  item
                                  xs={12}
                                  sm={12}
                                  md={6}
                                >
                                  <Box sx={{ width: "100%", height: "100%" }}>
                                    <img
                                      src={
                                        data?.block4_serialized_data[0]?.image
                                          ? data?.block4_serialized_data[0]
                                              ?.image
                                          : ""
                                      }
                                      width="100%"
                                      height="100%"
                                      style={{ objectFit: "contain" }}
                                    />
                                  </Box>
                                </Grid>
                              </Grid>
                            </>
                          )
                        ) : (
                          ""
                        )}
                      </>
                    );
                  }
                })}
              </>
            ) : (
              ""
            )}
          </Grid>
        </Box>
      </center>
      <Box mt={7}>
        <Plans />
      </Box>
    </Container>
  );
};

export default HomepageNew;
