import { BrowserRouter, Route, Routes } from "react-router-dom";
import AddUserPet from "../Pages/AddUserPet/AddUserPet";
import Login from "../Pages/Login/Login";
import MissingPets from "../Pages/MissingPets/MissingPets";
import NotFound from "../Pages/NotFound";
import PaymentMethods from "../Pages/PaymentMethods/PaymentMethods";
import PersonalInformation from "../Pages/PersonalInformation/PersonalInformation";
import Plans from "../Pages/Plans/Plans";
import UserPets from "../Pages/UserPets/UserPets";
import Layout from "./Layouts/Layout";

import UserAccount from "../Pages/UserAccount/UserAccount";
import Cart from "../Pages/Cart/Cart";
import Tags from "../Pages/Tags/Tags";
import HomePage from "../Pages/HomePage/HomePage";

import Pets from "../Pages/Pets/Pets";
import PetDetails from "../Pages/Pets/PetDetails";
import ForgetPassword from "../Pages/ForgetPassword/ForgetPassword";
import ResetPassword from "../Pages/ResetPassword/ResetPassword";
import { useState } from "react";
import OrderSuccess from "../Orders/OrderSuccess";
import OrderFailure from "../Orders/OrderFailure";
import OrderList from "../Pages/OrderList/OrderList";
import OrderDetails from "../Pages/OrderDetails/OrderDetails";
import OrderStatus from "../Orders/OrderStatus";
import TermsandConditions from "../Pages/UserAccount/TermsandConditions";
import PrivacyPolicy from "../Pages/UserAccount/PrivacyPolicy";
import Vaccination from "../Pages/VaccineCertificate/Vaccination";
import Mypet from "../Pages/Mypet/Mypet";
import Modal1 from "./Common/EditUploadsModal";
import HomepageNew from "../Pages/HomePage/HomepageNew";
const AppRoutes = ({ props }) => {
  const [count, setCount] = useState("0");

  return (
    <BrowserRouter>
      <Layout count={count} setCount={setCount}>
        <Routes>
          {/* <Route element={<PublicRoutes />}> */}
          <Route exact path="/MissingPets" element={<MissingPets />} />{" "}
          {/* done */}
          <Route
            exact
            path="/Tags"
            element={<Tags count={count} setCount={setCount} />}
          />{" "}
          {/* done,  add to cart pend. */}
          {/* <Route exact path="/" element={<HomePage />} />  */}
          <Route exact path="/" element={<HomepageNew />} /> {/* done */}
          <Route exact path="/Plans" element={<Plans />} />{" "}
          {/* done, palns add to cart pend. */}
          <Route
            exact
            path="/Cart"
            element={<Cart count={count} setCount={setCount} />}
          />
          <Route exact path="/PaymentMethods" element={<PaymentMethods />} />
          <Route exact path="/Login" element={<Login />} /> {/* done */}
          <Route exact path="/PersonalInfo" element={<PersonalInformation />} />
          <Route
            exact
            path="/UserInfo/:tag"
            element={<PersonalInformation />}
          />
          <Route path="/ForgetPassword" element={<ForgetPassword />} />
          {/* </Route> */}
          {/* here are the users routes */}
          {/* <Route element={<PrivateUserRoutes />}> */}
          <Route exact path="/UserPets" element={<UserPets />} />
          {/* <Route exact path="/AddPet" element={<AddUserPet />} /> */}
          <Route exact path="/AddPet" element={<Mypet />} />
          {/* modified */}
          <Route
            exact
            path="/UserAccount"
            element={<UserAccount count={count} setCount={setCount} />}
          />
          {/* modified */}
          <Route exact path="/UserPlans" element={<Plans />} />
          {/* modified */} {/* same as plans */}
          <Route
            exact
            path="/UserCart"
            element={<Cart count={count} setCount={setCount} />}
          />{" "}
          {/* modified */} {/* same as cart */}
          {/* </Route> */}
          {/* here are the Admin routes */}
          {/* <Route element={<PrivateAdminRoutes />}> */}
          {/* <Route exact path="/QrCodes" element={<QrCodes />} /> 
            <Route path="/Clientss" element={<ClientDetails />} />
          <Route exact path="/Clients" element={<Clients />} />   */}
          {/* </Route> */}
          <Route path="*" element={<NotFound />} />
          <Route path="/Pets" element={<Pets />} />
          {/* <Route path="/ne" element={<Modal1 />} /> */}
          {/* modified */}
          {/* done,some key needs to add from backend */}
          <Route path="Pets/:slug" element={<PetDetails />} />
          <Route path="vaccination/:slug" element={<Vaccination />} />
          {/* <Route path="mypet/:slug" element={<Mypet />} /> */}
          <Route path="Payment/success" element={<OrderSuccess />} />
          <Route path="Payment/failure" element={<OrderFailure />} />
          <Route
            path="Payment-status"
            element={<OrderStatus count={count} setCount={setCount} />}
          />
          <Route path="OrderList" element={<OrderList />} />
          <Route path="Orderdetails/:oid" element={<OrderDetails />} />
          <Route path="Terms-Conditions" element={<TermsandConditions />} />
          <Route path="Privacy-Policy" element={<PrivacyPolicy />} />
          {/* modified */}
          <Route path="/ResetPassword/:token" element={<ResetPassword />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
};

export default AppRoutes;
