import { Box, Card, Container, Grid } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/Navbar";
import CssButton from "../../Components/Reusables/CssButton";
import "./Plans.css";
import { errorMsg, infoMsg } from "../../Components/ToasterFn";
import { useEffect, useRef, useState } from "react";
import { getAllPlans } from "../../Components/utils/Helper";
import axios from "axios";
import {
  getGuestUserCart,
  getLocalUserData,
} from "../../Components/utils/Util";
const Plans = () => {
  //   console.log("plan", data);
  const navigate = useNavigate();
  const location = useLocation();

  const [plans, setPlans] = useState("");
  const getPlansInfo = async () => {
    var data = [];

    try {
      const res = await axios.get(getAllPlans());
      if (res.status == 200 && res.data) {
        data = res.data;
        console.log("data ", data?.data);
        setPlans(data?.data);
      } else {
        data = [];
      }
    } catch (e) {
      console.log(e);
      data = [];
    }
  };

  const planRedirect = () => {
    const guest_id = getGuestUserCart();
    const token = getLocalUserData().token;
    if (token) {
      navigate("/Cart");
    } else if (guest_id) {
      navigate("/PersonalInfo");
    } else {
      errorMsg("Looks like you didn't add any Tag!");
    }
    // if (guest_id) {
    //   return navigate("/Cart");
    // } else if (token) {
    //   return navigate("/UserCart");
    // } else {
    //   errorMsg("Looks Like You Didn't Added Any Tag !");
    // }
  };
  const wasCalled = useRef(false);

  useEffect(() => {
    if(wasCalled.current) return;
    wasCalled.current = true;
    getPlansInfo();
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <Box>
      <Box className="header-box">
        <Box className="header-content-box">
          <p className="header-title">Flexible Plans</p>
          <p className="header-sub-title">
            Choose a plan that works best for you!
          </p>
        </Box>
      </Box>
      <Box className="plans-main-box">
        <Box>
          <Grid
            columns={6}
            item
            container
            rowSpacing={{ xs: 5 }}
            columnSpacing={{ xs: 4, sm: 5, md: 6 }}
            xs={12}
            sm={12}
            md={12}
          >
            <Grid
              rowSpacing={{ xs: 3, md: 5 }}
              item
              container
              xs={12}
              sm={4}
              md={2}
            >
              <Grid
                display="flex"
                justifyContent="right"
                item
                xs={12}
                sm={12}
                md={12}
              >
                <Box className="secondary-card first-card">
                  <p className="plan-head">
                    {plans[0]?.name ? plans[0]?.name : ""}
                  </p>
                  {/* <p>{plans[0]?.description}</p> */}
                  <hr />
                  <p style={{ fontWeight: "bold", fontSize: "25px" }}>
                    {plans[0]?.price ? plans[0]?.price : ""} AED
                  </p>
                </Box>
              </Grid>
              {location.pathname == "/Plans" ||
              location.pathname == "/UserPlans" ? (
                <Grid
                  display="flex"
                  justifyContent="right"
                  item
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <CssButton
                    callback={() => planRedirect()}
                    // callback={() => handlePlan("1 Month", 30)}
                    width="172px"
                    height="38px"
                    text="Choose Plan"
                    color="orange"
                    fontSize="18px"
                  />
                </Grid>
              ) : (
                ""
              )}
            </Grid>
            <Grid
              rowSpacing={{ xs: 3, md: 5 }}
              item
              container
              xs={12}
              sm={4}
              md={2}
            >
              <Grid
                display="flex"
                justifyContent="center"
                item
                xs={12}
                sm={12}
                md={12}
              >
                <Box className="main-card">
                  <p>{plans[1]?.name ? plans[1]?.name : ""}</p>
                  {/* <p>Plan</p> */}
                  <hr />
                  <p style={{ fontWeight: "bold", fontSize: "25px" }}>
                    {plans[1]?.price ? plans[1]?.price : ""} AED{" "}
                  </p>
                </Box>
              </Grid>
              {location.pathname == "/Plans" ||
              location.pathname == "/UserPlans" ? (
                <Grid
                  display="flex"
                  justifyContent="right"
                  item
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <CssButton
                    callback={() => planRedirect()}
                    // callback={() => handlePlan("1 Month", 30)}
                    width="172px"
                    height="38px"
                    text="Choose Plan"
                    color="orange"
                    fontSize="18px"
                  />
                </Grid>
              ) : (
                ""
              )}
            </Grid>
            <Grid
              rowSpacing={{ xs: 3, md: 5 }}
              item
              container
              xs={12}
              sm={4}
              md={2}
            >
              <Grid
                display="flex"
                justifyContent="left"
                item
                xs={12}
                sm={12}
                md={12}
              >
                <Box className="secondary-card third-card">
                  <p className="plan-head">
                    {plans[2]?.name ? plans[2]?.name : ""}
                  </p>
                  {/* <p>Plan</p> */}
                  <hr />
                  <p style={{ fontWeight: "bold", fontSize: "25px" }}>
                    {plans[2]?.price ? plans[2]?.price : ""} AED{" "}
                  </p>
                </Box>
              </Grid>
              {location.pathname == "/Plans" ||
              location.pathname == "/UserPlans" ? (
                <Grid
                  display="flex"
                  justifyContent="right"
                  item
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <CssButton
                    callback={() => planRedirect()}
                    // callback={() => handlePlan("1 Month", 30)}
                    width="172px"
                    height="38px"
                    text="Choose Plan"
                    color="orange"
                    fontSize="18px"
                  />
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Plans;
