import { Autocomplete, TextField } from "@mui/material";

const CssAutocomplete = ({ callback, value, options, required, placeholder }) => {
    return (
        <>
            <Autocomplete disablePortal

                onChange={(event, newInputValue) => { callback(newInputValue) }}
                size="small"
                id="controllable-states-demo"
                value={value}
                options={options}
                sx={{
                    "&.MuiAutocomplete-root": {
                        "& .MuiInputBase-root": {
                            borderRadius: "30px",
                        },
                        '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                                borderColor: 'orange',
                            },
                        },
                    }
                }}
                renderInput={(params) => <TextField required={required} placeholder={placeholder} {...params} />} />
        </>
    );
}

export default CssAutocomplete;