import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import CssAutocomplete from "../../Components/Reusables/CssAuocomplete";

const SubsCribePlansOpt = ({
  userPlan,
  setUserPlan,
  handleChangePlan,
  val,
}) => {

  // const[plan,setPlan]=useState("")
  // useEffect(()=>{
  //   setPlan(userPlan)
  // },[userPlan])
  return (
    <Grid item xs={12} sm={12} md={12}>
      <CssAutocomplete
        placeholder="Choose Plan"
        value={userPlan}
        callback={(e) => {
          setUserPlan(e);
          handleChangePlan(e);
          console.log("e==", e);
        }}
        options={["1 Month", "6 Months", "1 Year"]}
      />
    </Grid>
  );
};

export default SubsCribePlansOpt;
