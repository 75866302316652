import { Grid } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { errorMsg, success } from "../../Components/ToasterFn";
import {
  changePlanOnChange,
  deleteItemsFromCart,
} from "../../Components/utils/Helper";
import { getCartCount, getLocalUserData } from "../../Components/utils/Util";
import SubsCribePlansOpt from "./SubsCribePlansOpt";

const CartCard = ({
  val,
  classes,
  setTotal,
  setShopping_id,
  getUsersData,
  currency,
  count,
  setCount,
  country,
  setTotalIncTax,
  totalIncTax,
  total,
}) => {
  const seletedPlans = () => {
    console.log("val", val);
    if (val?.subscription?.id === 1) {
      return "1 Month";
    } else if (val?.subscription?.id === 2) {
      return "6 Months";
    } else if (val?.subscription?.id === 3) {
      return "1 Year";
    } else {
      return;
    }
  };
  const token = getLocalUserData()?.token;
  const tokenParam = {
    token: token,
  };
  const [userPlan, setUserPlan] = useState("");

  useEffect(() => {
    if (val) {
      setUserPlan(seletedPlans());
    }
    return;
  }, [val]);

  const [totalPrice, setTotalPrice] = useState("");

  const prices = () => {
    const totalp = Number(val?.tag?.price);
    var price = "";
    if (userPlan == "1 Month") {
      return (price = 45 + totalp);
    } else if (userPlan == "6 Months") {
      return (price = 115 + totalp);
    } else if (userPlan == "1 Year") {
      return (price = 200 + totalp);
    } else {
      return (price = 0);
    }
  };
  console.log("value", val);

  const handleChangePlan = async (e) => {
    const plansSelect = () => {
      if (e === "1 Month") {
        return 1;
      } else if (e === "6 Months") {
        return 2;
      } else if (e === "1 Year") {
        return 3;
      } else {
        return "";
      }
    };
    const item_id = val?.cart_item_id;
    const shoppind_id = val?.shopping_cart_id;
    const plan_id = plansSelect();

    const param = {
      subscription_plan_id: plan_id,
      shopping_cart_id: shoppind_id,
      item_id: item_id,
    };
    var data = [];

    try {
      const res = await axios.post(changePlanOnChange(), param);
      if (res.status && res.data) {
        data = res.data;
        console.log("onchnge--- ", data);
        // setTotalPrice(data?.data);
        setTotal(data?.data?.total_price);
        if (data?.status) {
          getUsersData();
          success(data?.message);
        } else {
          errorMsg(data?.message);
        }
      } else {
        data = [];
        console.log(res?.response?.data?.message);
        errorMsg(res?.data?.message);
      }
    } catch (e) {
      console.log(e);
      errorMsg(e?.response?.data?.message);

      data = [];
    }
  };
  // const itemPrice = (data, price) => {
  //   var totalPriceData = price ? price : "";
  //   totalPrice?.cart_items?.map((val) => {
  //     if (val.id == data) {
  //       totalPriceData = val?.total_price;
  //     }
  //   });
  //   return totalPriceData;
  // };
  const deleteCartitem = async (cart_id, shop_id) => {
    const param = {
      shopping_cart_id: shop_id,
      item_id: cart_id,
    };
    var data = [];

    try {
      const res = await axios.post(deleteItemsFromCart(), param);
      if (res.status == 200 && res.data) {
        data = res.data;
        console.log("deleted data--- ", data);
        if (data?.status) {
          getUsersData();
          getCartCount(tokenParam, count, setCount);
        } else {
        }
      } else {
        data = [];
      }
    } catch (e) {
      console.log(e);

      data = [];
    }
  };
  useEffect(() => {
    setShopping_id(val?.shopping_cart_id);
  }, [val]);

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      container
      columnGap={1}
      marginBottom="20px"
    >
      <Grid item xs={8.5} sm={8.5} md={8.5} className={classes.items} container style={{height:"fit-content"}}>
        <Grid
          item
          xs={2}
          sm={2}
          md={2}
          justifyContent="center"
          display="flex"
          flexDirection="column"
        >
          <img src={val?.tag?.pic} width="86" height="86" />
        </Grid>

        <Grid
          item
          xs={2}
          sm={2}
          md={2}
          textAlign="center"
          display="flex"
          flexDirection="column"
          justifyContent="space-evenly"
        >
          <div>Tag Type</div>
          <div>{val?.tag?.pet_type}</div>
        </Grid>
        <Grid
          item
          xs={2}
          sm={2}
          md={2}
          textAlign="center"
          display="flex"
          flexDirection="column"
          justifyContent="space-evenly"
        >
          <div>Tag Price</div>
          <div>{val?.price}</div>
        </Grid>
        <Grid
          item
          xs={3.5}
          sm={3.5}
          md={3.5}
          textAlign="center"
          display="flex"
          flexDirection="column"
          justifyContent="space-evenly"
        >
          <div>Plan</div>
          <div>
            <SubsCribePlansOpt
              val={val?.subscription}
              userPlan={userPlan}
              setUserPlan={setUserPlan}
              handleChangePlan={handleChangePlan}
            />
          </div>
        </Grid>
        <Grid
          item
          xs={2.5}
          sm={2.5}
          md={2.5}
          textAlign="center"
          display="flex"
          flexDirection="column"
          justifyContent="space-evenly"
        >
          <div>Subscription Price</div>
          <div>{val?.subscription_price}</div>
        </Grid>
        <Grid style={{display:"flex",justifyContent:"center",width:"100%"}}>
        <Grid
          item
          xs={3}
          sm={3}
          md={3}
          textAlign="center"
          display="flex"
          flexDirection="row"
          justifyContent="space-evenly"
        >
          <div className="fw-bold">Size&nbsp;: </div>
          <div>{val?.tag?.size?.title}</div>
        </Grid>
        <Grid
          item
          xs={3}
          sm={3}
          md={3}
          textAlign="center"
          display="flex"
          flexDirection="row"
          justifyContent="space-evenly"
        >
          <div className="fw-bold">Color&nbsp;: </div>
          <div>{val?.tag?.color?.title}</div>
        </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2.4} sm={2.4} md={2.4}>
        <div className={classes.itemsTotal}>
          <span className="">Total</span>

          {/* <span>{itemPrice(val?.cart_item_id, val?.total_price)} </span> */}
          <span>
            {/* {currency === "AED" ? totalIncTax : total} */}
            {val?.total_price_includingplan_without_tax} 

             <span></span>
            {currency}{" "}
          </span>
        </div>
      </Grid>
      <Grid
        item
        xs={0.1}
        sm={0.1}
        md={0.1}
        textAlign="center"
        display="flex"
        flexDirection="column"
        justifyContent="space-evenly"
      >
        <button
          className="btn crossIconStyling"
          data-toggle="tooltip"
          data-placement="top"
          title="Remove From Cart"
          onClick={() =>
            deleteCartitem(val?.cart_item_id, val?.shopping_cart_id)
          }
        >
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="red"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="white"
            width="1.5em"
            style={{ cursor: "pointer" }}
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg> */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
            width="1.5em"
            style={{ cursor: "pointer" }}
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </button>
      </Grid>
    </Grid>
  );
};

export default CartCard;
