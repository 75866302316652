import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import { useLocation } from "react-router-dom";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  // "pk_test_51Nd5cYFL3Pd3ox1sg2qqszIyy5gwGxq0apqAzJHXSJpu2wgri5dwCfLoMMlnagAliSPgWIhuN6nVHBlCLwQ0V19w007xTenbxk",
  // "pk_live_51Nd5cYFL3Pd3ox1szGgrtwXfyJLfu3dDaONZjy1AZWRSylo00jpo5DWMZP5Vfr0c8wuaj5NPK3tbfoiRaYaWoaya00XKS8vF97"
  `${
    process.env.REACT_APP_SERVER === "STAGE"
      ? process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_STAGE
      : process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_LIVE
  }`
);
const DummyPayment = ({ clentsecretkey }) => {
  console.log("clentsecretkey", clentsecretkey);
  const [cId, setCid] = useState("");
  const options = {
    clientSecret: clentsecretkey,
    appearance: {
      theme: "night",
      labels: "floating",
      rules: {
        ".DropdownItem": {
          display: "none !important",
        },
      },
    },
  };

  useEffect(() => {
    if (clentsecretkey) {
      setCid(clentsecretkey);
      options.clientSecret = clentsecretkey;
    }
  }, [clentsecretkey]);
  console.log("opt", options);
  const location = useLocation();
  console.log("loc", location);
  return (
    <>
      {cId ? (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm />
        </Elements>
      ) : (
        ""
      )}
    </>
  );
};

export default DummyPayment;
