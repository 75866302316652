import { Box, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import "./UserNavbar.css";
import tounzLogo from "../assets/tounzLogo.png";
import {  useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import TuneIcon from "@mui/icons-material/Tune";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import { clearLocalStorage, getLocalUserData, getshoppingId } from "../utils/Util";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { cartCount } from "../utils/Helper";
import { getCartCount } from "../utils/Util";

const UserNavbar = ({ count, setCount }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const locData = location.pathname.slice(1);
  const [toggleClassState, setToggleClassState] = useState("");
  const [toggleNavState, setToggleNavState] = useState("");
  // const [count, setCount] = useState("0");
  const shopp = getshoppingId();
  const token=getLocalUserData()?.token
  // console.log("shoppie", shopp);
  const param = {
    token: token,
  };
  const wasCalled = useRef(false);

  useEffect(() => {
    if(wasCalled.current) return;
    wasCalled.current = true;
    getCartCount(param, count, setCount);
  }, []);

  const handleClick = () => {
    if (toggleClassState === "mobile-nav") {
      setToggleClassState("");
      setToggleNavState("");
    } else {
      setToggleClassState("mobile-nav");
      setToggleNavState("is-active");
    }
  };
  const logout = () => {
    clearLocalStorage();
    window.location.reload();
  };

  return (
    <Box>
      <Box className="page-wrapper">
        <Box className="nav-wrapper">
          <nav className="navbar">
            <img
              onClick={() => navigate("/UserPets")}
              style={{ cursor: "pointer" }}
              src={tounzLogo}
            />
            <Box
              className={`menu-toggle ${toggleNavState}`}
              onClick={handleClick}
              id="mobile-menu"
            >
              <span className="bar"></span>
              <span className="bar"></span>
              <span className="bar"></span>
            </Box>
            <ul
              style={{ float: "right" }}
              className={`nav ${toggleClassState} no-search`}
            >
              <li
                className="nav-item"
                onClick={() => {
                  setToggleClassState("");
                  setToggleNavState("");
                }}
              >
                <Link
                  to="/UserPets"
                  style={{ color: locData === "UserPets" ? "orange" : "" }}
                >
                  {" "}
                  <span
                    style={{
                      fontFamily: "Calli Cat",
                      color: locData === "UserPets" ? "orange" : "",
                    }}
                  >
                    Home
                  </span>
                </Link>
              </li>
              <li
                className="nav-item"
                onClick={() => {
                  setToggleClassState("");
                  setToggleNavState("");
                }}
              >
                <Link
                  to="/Plans"
                  style={{
                    fontFamily: "Calli Cat",
                    color: locData === "Plans" ? "orange" : "",
                  }}
                >
                  Plans
                </Link>
              </li>
             
              <li
                className="nav-item"
                onClick={() => {
                  setToggleClassState("");
                  setToggleNavState("");
                }}
              >
                <Link
                  to="/Tags"
                  style={{
                    fontFamily: "Calli Cat",
                    color: locData === "Tags" ? "orange" : "",
                  }}
                >
                  Tags
                </Link>
              </li>
              <li
                className="nav-item"
                onClick={() => {
                  setToggleClassState("");
                  setToggleNavState("");
                }}
              >
                <Link
                  to="/OrderList"
                  style={{
                    fontFamily: "Calli Cat",
                    color: locData === "OrderList" ? "orange" : "",
                  }}
                >
                  Orders
                </Link>
              </li>
              <li
                className="nav-item"
                onClick={() => {
                  setToggleClassState("");
                  setToggleNavState("");
                }}
              >
                <Link
                  to="/UserAccount"
                  style={{ color: locData === "UserAccount" ? "orange" : "" }}
                >
                  {" "}
                  <TuneIcon />{" "}
                  <span
                    style={{
                      fontFamily: "Calli Cat",
                      color: locData === "UserAccount" ? "orange" : "",
                    }}
                  >
                    {" "}
                    Settings
                  </span>
                </Link>
              </li>

              <li className="nav-item" onClick={logout}>
                {" "}
                <Link
                  to="/Login"
                  style={{
                    fontFamily: "Calli Cat",
                  }}
                >
                  <LogoutRoundedIcon />
                  Logout
                </Link>{" "}
              </li>
              <li className="nav-item">
                {" "}
                <Link
                  to="/UserCart"
                  style={{
                    fontFamily: "Calli Cat",
                    color: locData === "UserCart" ? "orange" : "",
                  }}
                >
                  <ShoppingCartIcon />
                  <span className="cartCountBadge">{count}</span>
                </Link>{" "}
              </li>
            </ul>
          </nav>
        </Box>
      </Box>
    </Box>
  );
};
export default UserNavbar;
