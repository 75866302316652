import axios from "axios";
import { errorMsg } from "../ToasterFn";
import { cartCount, myAllPetsQr } from "./Helper";

export const createLocalStorage = (data) => {
  localStorage.setItem("user_token", JSON.stringify(data));
};

export const getLocalUserData = () => {
  const userData =
    typeof window !== "undefined" && localStorage.getItem("user_token")
      ? JSON.parse(localStorage.getItem("user_token"))
      : "";
  if (userData !== "" && userData !== "null") {
    return userData;
  } else {
    return false;
  }
};

export const clearLocalStorage = () => {
  localStorage.clear();
};

export const createGuestCart = (data) => {
  localStorage.setItem("guest_cart_id", JSON.stringify(data));
};

export const getGuestUserCart = () => {
  const userData =
    typeof window !== "undefined" && localStorage.getItem("guest_cart_id")
      ? JSON.parse(localStorage.getItem("guest_cart_id"))
      : "";
  if (userData !== "" && userData !== "null") {
    return userData;
  } else {
    return false;
  }
};
export const clearGuestToken = () => {
  localStorage.removeItem("guest_cart_id");
};

export const getCartCount = async (param, count, setCount) => {
  // const param = {
  //   shopping_cart_id: "92a6a9a4-ffbb-11ed-bc7c-3574abf5045b",
  // };
  var data = [];

  try {
    const res = await axios.post(cartCount(), param);
    if (res.status == 200 && res.data) {
      data = res.data;
      console.log("cart count ", data);
      if (data?.status) {
        setCount(data?.data?.item_count);
      } else {
        setCount(count);
      }
    } else {
      data = [];
    }
  } catch (e) {
    console.log(e);
    data = [];
  }
};

export const createShoppingCartId = (data) => {
  localStorage.setItem("shopping_cart_id", JSON.stringify(data));
};

export const getshoppingId = () => {
  const userData =
    typeof window !== "undefined" && localStorage.getItem("shopping_cart_id")
      ? JSON.parse(localStorage.getItem("shopping_cart_id"))
      : "";
  if (userData !== "" && userData !== "null") {
    return userData;
  } else {
    return false;
  }
};

export const PetsQr = async (setDogTagID, setCatTagID) => {
  const token = getLocalUserData().token;
  const param = {
    token: token,
  };

  var data = [];

  try {
    const res = await axios.post(myAllPetsQr(), param);
    if (res.status && res.data) {
      data = res.data;
      console.log("alltags ", data);
      if (data?.status) {
        setDogTagID(data?.Dog);
        setCatTagID(data?.Cat);
      } else {
        errorMsg(data?.message);
      }
    } else {
      data = [];
      console.log(res?.response?.data?.message);

      errorMsg(res?.data?.message);
    }
  } catch (e) {
    console.log(e);
    errorMsg(e?.response?.data?.message);

    data = [];
  }
};

export const Btnurl = (str) => {
  let newstr = "";
  const urlString = str;
  const parts = urlString.split("/");
  const slug = parts[parts.length - 1];
  newstr = slug;
  console.log("url",newstr)
  return newstr;
};
