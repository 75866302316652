import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import {
  ParticularOrderDetail,
  UserOrderDetails,
} from "../../Components/utils/Helper";
import { getLocalUserData } from "../../Components/utils/Util";
import "../OrderDetails/OrderDetails.css";

const OrderDetails = () => {
  const [orderStatus, setOrderStatus] = useState(true);
  const [allDetails, setAllDetails] = useState([]);

  const slug = useParams();
  console.log("param", slug.oid);
  const uid = getLocalUserData()?.token;
  const GetAllOrders = async () => {
    const params = {
      order_id: slug?.oid,
      token: uid,
    };
    let data = [];
    try {
      const res = await axios.post(ParticularOrderDetail(), params);
      if (res.status == 200 && res.data) {
        data = res.data;
        console.log("orders--", data);
        if (data?.status) {
          setAllDetails(data?.order);
          // setPaymentDone(true);
          // setOrderId(data?.order_id);
        }
      } else {
        data = [];
      }
    } catch (e) {
      console.log(e);
      data = [];
    }
  };
  const wasCalled = useRef(false);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if(wasCalled.current) return;
    wasCalled.current = true;
    GetAllOrders();
  }, [slug]);
  return (
    <>
      {/* <button onClick={() => setOrderStatus(!orderStatus)} className="btn">
        order status
      </button> */}
      <div className="container-fluid mt-5">
        <div className="d-flex justify-content-center mt-3 mb-3">
          <p class="header-title">Order Details</p>
        </div>

        <div className="row d-flex justify-content-center">
          {/* {orderStatus ? (
            <div className="text-center d-flex justify-content-center ">
              <h6
                className="rounded  detail-headings px-3 py-2 thankyouText"
                style={{ border: "1px solid green" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="white"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="green"
                  width="1.2em"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <span className="mx-2 text-success">
                  Thank you. Your order has been received.
                </span>
              </h6>
            </div>
          ) : (
            <div className="text-center d-flex justify-content-center ">
              <h6
                className=" rounded detail-headings px-3 py-2 thankyouText"
                style={{ border: "1px solid #dc3545" }}
              >
                <svg
                  width="1.2em"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#dc3545"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <span className="mx-2 text-danger">
                  OOPS ! Your order has been Failed.
                </span>{" "}
              </h6>
            </div>
          )} */}

          <div className="col-lg-7 gx-5 ">
            {/* <div className="row card-style mt-4">
              <div className="col-lg-6">
                <img
                  src="	http://tounzapi.bangalore2.com//media/cat-logo.651040265e2dbd34a876.png"
                  className="img-fluid w-75"
                />
              </div>
              <div className="col-lg-6 py-5">
                <table cellPadding={4}>
                  <tr>
                    <td>
                      <h6 className="detail-headings">Username </h6>
                    </td>
                    <td>
                      <h6 className="list-page-details ms-2 ">
                        &nbsp;-&nbsp;&nbsp;&nbsp;Abeer
                      </h6>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6 className="detail-headings">Tag QR code </h6>
                    </td>
                    <td>
                      <h6 className="list-page-details ms-2">
                        &nbsp;-&nbsp;&nbsp;&nbsp;9768556
                      </h6>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6 className="detail-headings">Tag type </h6>
                    </td>
                    <td>
                      <h6 className="list-page-details  ms-2">
                        &nbsp;-&nbsp;&nbsp;&nbsp;Cat
                      </h6>
                    </td>
                  </tr>
                </table>
              </div>
            </div>

            <div className="row card-style mt-4">
              <div className="col-lg-6 d-flex justify-content-center">
                <img
                  src="	http://tounzapi.bangalore2.com//media/dog-logo.008e2df790ed727fb055.png"
                  className="img-fluid w-100 p-4"
                />
              </div>
              <div className="col-lg-6 py-5">
                <table cellPadding={4}>
                  <tr>
                    <td>
                      <h6 className="detail-headings">Username </h6>
                    </td>
                    <td>
                      <h6 className="list-page-details ms-2">
                        &nbsp;-&nbsp;&nbsp;&nbsp;Abeer
                      </h6>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6 className="detail-headings">Tag QR code </h6>
                    </td>
                    <td>
                      <h6 className="list-page-details ms-2">
                        &nbsp;-&nbsp;&nbsp;&nbsp;5364758
                      </h6>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6 className="detail-headings">Tag type </h6>
                    </td>
                    <td>
                      <h6 className="list-page-details  ms-2">
                        &nbsp;-&nbsp;&nbsp;&nbsp;Dog
                      </h6>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6 className="detail-headings">Subscription Opted</h6>
                    </td>
                    <td>
                      <h6 className="list-page-details  ms-2">
                        &nbsp;-&nbsp;&nbsp;&nbsp;Yes
                      </h6>
                    </td>
                  </tr>{" "}
                  <tr>
                    <td>
                      <h6 className="detail-headings">Plan Validity</h6>
                    </td>
                    <td>
                      <h6 className="list-page-details  ms-2">
                        &nbsp;-&nbsp;&nbsp;&nbsp;1 Month
                      </h6>
                    </td>
                  </tr>
                </table>
              </div>
            </div> */}
            {allDetails && allDetails?.order_lines?.length > 0 ? (
              <>
                {allDetails?.order_lines &&
                  allDetails?.order_lines.map((item) => {
                    return (
                      <>
                        <div className="row card-style mt-4">
                          <div className="col-lg-6 d-flex justify-content-center">
                            <img
                              src={item?.tag?.pic}
                              className="img-fluid w-100 p-4"
                            />
                          </div>
                          <div className="col-lg-6 py-5 ">
                            <table cellPadding={4}>
                              {/* <tr>
                                <td>
                                  <h6 className="detail-headings">
                                    Tag QR code{" "}
                                  </h6>
                                </td>
                                <td>
                                  <h6 className="list-page-details ms-2">
                                    &nbsp;-&nbsp;&nbsp;&nbsp;{item?.qr_code}
                                  </h6>
                                </td>
                              </tr> */}
                              <tr>
                                <td>
                                  <h6 className="detail-headings">Tag type </h6>
                                </td>
                                <td>
                                  <h6 className="list-page-details  ms-2">
                                    &nbsp;-&nbsp;&nbsp;&nbsp;
                                    {item?.tag?.pet_type}
                                  </h6>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h6 className="detail-headings">
                                    Tag Color{" "}
                                  </h6>
                                </td>
                                <td>
                                  <h6 className="list-page-details  ms-2">
                                    &nbsp;-&nbsp;&nbsp;&nbsp;
                                    {item?.tag?.color?.title}
                                  </h6>
                                </td>
                              </tr>{" "}
                              <tr>
                                <td>
                                  <h6 className="detail-headings">Tag Size </h6>
                                </td>
                                <td>
                                  <h6 className="list-page-details  ms-2">
                                    &nbsp;-&nbsp;&nbsp;&nbsp;
                                    {item?.tag?.size?.title}
                                  </h6>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h6 className="detail-headings">
                                    Subscription Opted
                                  </h6>
                                </td>
                                <td>
                                  <h6 className="list-page-details  ms-2">
                                    &nbsp;-&nbsp;&nbsp;&nbsp;
                                    {item?.is_subscription ? "Yes" : "No"}
                                  </h6>
                                </td>
                              </tr>{" "}
                              {item?.is_subscription ? (
                                <tr>
                                  <td>
                                    <h6 className="detail-headings">
                                      Plan Validity
                                    </h6>
                                  </td>
                                  <td>
                                    <h6 className="list-page-details  ms-2">
                                      &nbsp;-&nbsp;&nbsp;&nbsp;
                                      {item?.subscription?.name}
                                    </h6>
                                  </td>
                                </tr>
                              ) : (
                                ""
                              )}
                            </table>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </>
            ) : (
              ""
            )}
          </div>

          <div className="col-lg-4 gx-5 mt-4">
            <div className="row card-style py-5 px-4">
              <div className="col-lg-12 detail-sub-headings border-bottom mt-3">
                Delivery Address
              </div>
              <div className="mt-3">
                <table cellPadding={4}>
                  <tr>
                    <td>
                      <h6 className="detail-headings">Username </h6>
                    </td>
                    <td>
                      <h6 className="list-page-details ms-2 ">
                        &nbsp;-&nbsp;&nbsp;&nbsp;{allDetails?.user?.name}
                      </h6>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6 className="detail-headings">Address </h6>
                    </td>
                    <td>
                      <h6 className="list-page-details ms-2 ">
                        &nbsp;-&nbsp;&nbsp;&nbsp;{allDetails?.user?.address}
                      </h6>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <h6 className="detail-headings">Phone Number </h6>
                    </td>
                    <td>
                      <h6 className="list-page-details  ms-2">
                        &nbsp;-&nbsp;&nbsp;&nbsp;{allDetails?.user?.mobile}
                      </h6>
                    </td>
                  </tr>
                </table>
              </div>
              <div className="col-lg-12 detail-sub-headings border-bottom mt-3">
                Payment Method
              </div>
              <div className="mt-3">
                <table cellPadding={4}>
                  <tr>
                    <td>
                      <h6 className="detail-headings">Status</h6>
                    </td>
                    <td>
                      <h6 className="list-page-details  ms-2">
                        &nbsp;-&nbsp;&nbsp;&nbsp;<span style={{fontWeight:"800"}}>{allDetails?.status}</span> 
                      </h6>
                    </td>
                  </tr>
                  {/* <tr>
                    <td>
                      <h6 className="detail-headings">Payment Type</h6>
                    </td>
                    <td>
                      <h6 className="list-page-details  ms-2">
                        &nbsp;-&nbsp;&nbsp;&nbsp; Stripe ( card )
                      </h6>
                    </td>
                  </tr> */}

                  <tr>
                    <td>
                      <h6 className="detail-headings">Payment Id </h6>
                    </td>
                    <td>
                      <h6 className="list-page-details  ms-2">
                        &nbsp;-&nbsp;&nbsp;&nbsp;{allDetails?.gateway_order_id}
                      </h6>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6 className="detail-headings">Payment Date </h6>
                    </td>
                    <td>
                      <h6 className="list-page-details  ms-2">
                        &nbsp;-&nbsp;&nbsp;&nbsp;{" "}
                        {allDetails?.order_at?.slice(8, 10)}-
                        {allDetails?.order_at?.slice(5, 7)}-
                        {allDetails?.order_at?.slice(0, 4)}
                      </h6>
                    </td>
                  </tr>

                  {/* <tr>
                    <td>
                      <h6 className="detail-headings">Payment type </h6>
                    </td>
                    <td>
                      <h6 className="list-page-details ms-2 ">
                        &nbsp;-&nbsp;&nbsp;&nbsp;Visa
                      </h6>
                    </td>
                  </tr> */}
                </table>
              </div>

              <div className="col-lg-12 detail-sub-headings border-bottom mt-3">
                Order Price Details
              </div>
              <div className="mt-3">
                <table cellPadding={4}>
                  {/* <tr>
                    <td>
                      <h6 className="detail-headings">SubTotal </h6>
                    </td>
                    <td>
                      <h6 className="list-page-details  ms-2">
                        &nbsp;-&nbsp;&nbsp;&nbsp;756 AED
                      </h6>
                    </td>
                  </tr> */}
                  {/* <tr>
                    <td>
                      <h6 className="detail-headings">Tax </h6>
                    </td>
                    <td>
                      <h6 className="list-page-details  ms-2">
                        &nbsp;-&nbsp;&nbsp; 16 AED
                      </h6>
                    </td>
                  </tr> */}
                  <tr>
                    <td>
                      <h6 className="detail-headings">Total Amount: </h6>
                    </td>
                    <td>
                      <h6 className="list-page-details  ms-2">
                        &nbsp;-&nbsp;&nbsp;&nbsp;{allDetails?.total}{" "}
                        {allDetails?.currency}
                      </h6>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderDetails;
